import React, { Component } from "react";
import { Button, Collapse, Spin } from "antd";
import { Image as Images } from "../../Images";
import { CaretRightOutlined } from "@ant-design/icons";
import { handleError } from "../../../Controller/Global";
import {
    updateProposal,
} from "../../../Controller/api/proposalServices";
import { withRouter } from "react-router-dom";
import { routes } from "../../../Controller/Routes";
import { setBreadcrumb } from "../../../Store/actions/breadcrumbAction";
import { connect } from "react-redux";
import { reverse } from "named-urls/dist/index.es";
import { history } from "../../../Controller/history";
import ViewProposalLineItems from "./components/ViewProposalLineItems";
import { Tooltip } from "antd";
import { checkProposalRequired } from "../../../Controller/utils";
import ProposalTemplateGeneralInfo from "./components/ProposalTemplateGeneralInfo";
import CommonWarningModal from "../../modals/CommonWarningModal";
import ViewProposalTemplateLineItems from "./components/ViewProposalTemplateLineItems";
import {updateContact} from "../../../Controller/api/contactsServices";

const { Panel } = Collapse;

class ProposalTemplateSummary extends Component {
    state = {
        data: [],
        loading: false,
        pagination: {
            current: 1,
            pageSize: 15,
        },
        visible: false,
        visibleDrawer: false,
        warningModalVisible: false
    };

    handleOkAction = () => {
        let proposal_template_data = this.props.proposal;
        proposal_template_data['service_variant'] = proposal_template_data['service_variant_template'];
        proposal_template_data['isTemplate'] = true;
        proposal_template_data['template_name'] = proposal_template_data['name'];
        delete proposal_template_data['name'];
        delete proposal_template_data['service_variant_template'];
        localStorage.setItem('proposal_template', JSON.stringify(proposal_template_data));
        history.push(
            reverse(routes.dashboard.sales.proposal.create)
        )
    }

    showSendProposal = (visible) => {
        this.setState({
            visible: visible,
        });
    };


    showWarningModal = (visible) => {
        this.setState({
            warningModalVisible: visible,
        });
    };

    render() {
        const { proposal, onTabChange, proposal_filled } = this.props;

        const { statusTypes } = this.state;
        if (!proposal) {
            return (
                <div className={"text-center my-2"}>
                    <Spin />
                </div>
            );
        }

        const statusIndex = statusTypes?.findIndex(
            (i) => i?.id === proposal?.status?.id
        );
        // console.log(statusIndex);
        return (
            <>
                <div className="col-12">
                    <div
                        className="row summary-info-row-main"
                        style={{ marginBottom: "80px" }}
                    >
                        <div className="col-12">
                            <div className="row mx-0 summary-info-status-green-line-main">
                                <Tooltip
                                    placement="top"
                                    title="Proposal Template"
                                    overlayStyle={{ fontSize: 11 }}
                                >
                                    <div
                                        className={
                                            "summary-line-main summary-line-template-main " +
                                            "text-uppercase position-relative p-0 d-flex " +
                                            "align-items-center justify-content-center"
                                        }
                                    >
                                        PROPOSAL TEMPLATE
                                    </div>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                    <div className="row mx-0 summary-info-inner-row">
                        <div className="col-12">
                            <Collapse
                                // accordion
                                defaultActiveKey={["1"]}
                                expandIcon={({ isActive }) => (
                                    <CaretRightOutlined rotate={isActive ? 90 : 0} />
                                )}
                            >
                                <Panel
                                    header={
                                        <div className="opportunity_info-collapse d-flex align-items-center justify-content-between">
                                            <span>
                                              General Information <sup>*</sup>
                                            </span>
                                            <div className="d-flex align-items-center">
                                                <Button
                                                    onClick={() =>
                                                        this.showWarningModal(true)
                                                    }
                                                    className="edit-btn-summary"
                                                >
                                                    <img
                                                        src={Images.note_proposal}
                                                        alt=""
                                                        className="img-fluid"
                                                    />
                                                    Use Template
                                                </Button>
                                            </div>
                                        </div>
                                    }
                                    key="1"
                                >
                                    <ProposalTemplateGeneralInfo
                                        proposal={proposal}
                                        handleChange={this.handleChange}
                                        onTabChange={this.props.onTabChange}
                                        fetchProposal={this.props.fetchProposal}
                                        proposal_filled={proposal_filled}
                                        statusTypes={statusTypes}
                                        callbackSendProposal={() => this.showSendProposal(true)}
                                    />
                                </Panel>

                                <Panel
                                    header={
                                        <div className="opportunity_info-collapse d-flex align-items-center justify-content-between">
                                            <span>
                                                Service Variants<sup>*</sup>
                                            </span>
                                            <div className="d-flex align-items-center">
                                                {
                                                    checkProposalRequired(proposal,"SERVICE_VARIENT") && (
                                                        <p className="mb-0 info-signifire mr-3">
                                                            Please complete required information to avoid issues
                                                        </p>)
                                                }
                                            </div>
                                        </div>

                                    }
                                    key="5"
                                >
                                    <ViewProposalTemplateLineItems
                                        onTabChange={onTabChange}
                                        proposal={proposal}
                                        viewAll={true}
                                    />
                                </Panel>

                            </Collapse>
                        </div>
                    </div>
                </div>
                <CommonWarningModal
                    heading={`Are you sure you want to use this template?`}
                    visible={this.state.warningModalVisible}
                    onClose={() => this.showWarningModal(false)}
                    subHeadingUOM={" "}
                    useTemplate={this.handleOkAction}
                    onOkText={"Yes, i want to use it"}
                    onCancelText={"No, cancel this action"}
                    handleAccountChange={this.handleAccountChange}
                />
            </>
        );
    }
}

const actionCreators = {
    setBreadcrumb,
};
export default connect(null, actionCreators)(withRouter(ProposalTemplateSummary));
