
import { combineReducers, Reducer } from "redux";
import { LOGOUT_SUCCESS } from "../actions/authAction";
import { AuthAction } from "../actions/authActionTypes";
import { RootState } from "../reducers/mainReducer" ;

const resetStateReducer: Reducer<RootState | undefined, AuthAction> = (state, action) => {
  if (action.type === LOGOUT_SUCCESS) {
    return undefined;
  }
  return state;
};

export default resetStateReducer;
