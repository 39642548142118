import React , {useEffect, useState } from "react";
import {DatePicker} from "antd";
import moment from "moment";

export function GenericDateFilterField({ label, onChange, valueGTE, valueLTE }) {
    const [formatValueGTE, setFormatValueGTE] = useState();
    const [formatValueLTE, setFormatValueLTE] = useState();

    const handleChange = (new_values, ) => {
        console.log(new_values, 'normal date')
        onChange(new_values);
    }

    useEffect(() => {
        const end = typeof valueLTE === "string" ? moment(valueLTE, 'YYYY-MM-DD') : valueLTE;
        const start = typeof valueGTE === "string" ? moment(valueGTE, 'YYYY-MM-DD') : valueGTE;

        setFormatValueLTE(end);
        setFormatValueGTE(start)


    }, [ valueLTE, valueGTE]);

    return (
        <>
            <label className={'generic_select__label col-12 pl-0'}>{label}</label>

            <DatePicker onChange={date => handleChange([date, formatValueLTE])} className={'col-5'} value={formatValueGTE} placeholder={"Start Date"} />
            <span className={'col-1'}>-</span>
            <DatePicker onChange={date => handleChange([formatValueGTE, date])} className={'col-5'} value={formatValueLTE} placeholder={"End Date"}/>
        </>
    )
}
export default GenericDateFilterField
