import React, { useState } from 'react';
import {Menu, Switch, Table} from "antd";
import {Link} from "react-router-dom";
import {checkOpportunityFieldsRequired, formatDate} from "../../../Controller/utils";
import {history} from "../../../Controller/history";
import {reverse} from "named-urls";
import {routes} from "../../../Controller/Routes";
import { connect, useSelector, useDispatch } from 'react-redux';
import moment from 'moment/moment';
import {LoadingOutlined} from "@ant-design/icons";
import {InvoiceStatusToString} from "../../../utils/mappings";

export const InvoicingTableMain = (props) => {


    const menu = (
        <Menu>
            <Menu.Item key="0">
                <Link className="d-flex align-items-center font-weight-bold" to={""}>
                    <Switch/>
                    <span>Salesperson</span>
                </Link>
            </Menu.Item>
            <Menu.Item key="1">
                <Link className="d-flex align-items-center font-weight-bold" to={""}>
                    <Switch/>
                    <span>Sales Manager</span>
                </Link>
            </Menu.Item>
            <Menu.Item key="2">
                <Link className="d-flex align-items-center font-weight-bold" to={""}>
                    <Switch/>
                    <span>Sales Assistant</span>
                </Link>
            </Menu.Item>
        </Menu>
    );

    const columns = [
        {
            title: "Invoice ID",
            key: "id",
            dataIndex: "id",
            sorter: true,
            render: (id) => <div>{id}</div>
        },
        {
            title: "Associated Project",
            dataIndex: "project",
            sorter: true,
            key: "project",
            render:(project)=><div className="font-weight-500">{project?.name}</div>
        },
       {
            title: "Billing Account",
            key: "account",
            dataIndex: "invoice_customer_contact",
            sorter: true,
            render: (data) =>
                <div>{data.length ? data.map(i => {
                return (
                    <>{i.account ? i.account.name : "-"}</>
                )
                   }) : "-"
                }
              </div>
        },
        {
            title: "Status",
            dataIndex: "status",
            sorter: true,
            key: "status",
            render: (status) => <div>{InvoiceStatusToString[status]}</div>,
        },
        {
            title: <div className="position-relative">Last Activity Date</div>,
            dataIndex: "modified",
            render: (modified) => <div className="text-capitalize">{modified ? moment(modified).format("MMM DD,YYYY") : "-"}</div>,
            sorter: true,
            key: "modified",
        },
    ];


    return (
            <>
                <div className="col-12 table-responsive main-table-div opportunity-db-table">
                    <Table
                        scroll={{ y: 500}}
                        className="main-table-all"
                        columns={columns}
                        loading={props.loading ? {indicator:<LoadingOutlined style={{ fontSize: 24, color: '#9bd2bb' }} />} : false }
                        dataSource={props.data}
                        size="middle"
                        locale={props.locale}
                        pagination={props.pagination}
                        rowKey={(record) => record.id}
                        onChange={props.handleTableChange}
                        // rowClassName={(record) => {
                        //     if (checkOpportunityFieldsRequired(record)) {
                        //         return "is-required";
                        //     }
                        // }}
                        onRow={(record) => {
                            return {
                                onClick: () => {
                                    history.push(
                                        reverse(routes.dashboard.accounting.invoicing.view, {
                                            id: record.id,
                                        })
                                    );
                                },
                            };
                        }}
                    />
                </div>
            </>
        );
};

export default InvoicingTableMain;