import get from "lodash/get";
const objectKeySequence = [
  "street_address",
  "apartment",
  "county",
  "city",
  "state",
  "zip_code",
  "country",
];
export const mainAddressMapper = ({ type, facility }) => {
  let mainAddressObj = null;
  if (type === "VENDOR") mainAddressObj = facility?.vendor?.main_address;
  else if (type === "WAREHOUSE") mainAddressObj = facility?.internal_location;
  const mappedKeyToAddress = objectKeySequence.map((key) =>
    get(mainAddressObj, key, "")
  );
  return mappedKeyToAddress.join(" ");
};
