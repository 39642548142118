import React, { Component } from "react";
import { Button, Form, Input, Popover } from "antd";
import { Image as Images } from "../Images";
import { Link } from "react-router-dom";
import { routes } from "../../Controller/Routes";
import { reverse } from "named-urls/dist/index.es";
import FilterDatePicker from "../../FilterDatePicker";
import GenericFilterDrawer from "../drawers/generic-filter/GenericFilterDrawer";
import {
  FILTER_TYPE_ACCOUNT_TYPE, FILTER_TYPE_BILLING_ACCOUNT,
  FILTER_TYPE_CITY, FILTER_TYPE_DATE_RANGE,
  FILTER_TYPE_INDUSTRY, FILTER_TYPE_OPPORTUNITY, FILTER_TYPE_PRIMARY_TEAM_MEMBER, FILTER_TYPE_PROJECT,
  FILTER_TYPE_STATE
} from "../drawers/generic-filter/GenericFilterDrawerTypes";
import FilterBadge from "../filter-badge/FilterBadge";
import {checkFilterValue} from "../../Controller/utils";
class ContactMinHeader extends Component {
  state = {
    visible: false,
    filterApplied: false,
    filterObj: null,
    isClearFilter: false,
  };

  filterDataPop = () => {
    return <FilterDatePicker fetchData={this.props.fetchData} />;
  };

  handleVisibleChange = (visible) => {
    this.setState({ visible });
  };
  handleFilterChange = (value, label) => {
      this.handleSetFilterObj(label)
    if (this.props.onChangeFilters){
      this.props.onChangeFilters(value)
    }
  }

    handleSetFilterObj = (filter) => {
        this.setState({ filterObj: filter });
        const checkValue = checkFilterValue(filter)
        if (checkValue && checkValue.length > 0) {
            this.setState({ filterApplied: true, isClearFilter: false });
        } else {
            this.setState({ filterApplied: false });
        }
    }

  render() {
    return (
      <React.Fragment>
        <div className="row mx-0 align-items-center mini-header-filter-list-grid-row">
          <div className="col-12">
            <div className="row mx-0 new-opportunity-header-row">
              <div className="d-flex align-items-center">
                <div className="search-opportunities-div">
                  <div className="search-bar-div">
                    <Form className="position-relative">
                      <Input
                        placeholder="Search"
                        onChange={this.props.onSearch}
                      />
                      <Button className="search-btn position-absolute p-0 border-0 bg-transparent m-auto">
                        <img
                          src={Images.search_icon_gray}
                          className="img-fluid"
                          alt="search icon"
                        />
                      </Button>
                    </Form>
                  </div>
                </div>
              </div>
              <div className="new-opportunity-btn-div ml-3">
                  <Button
                    onClick={() => this.handleVisibleChange(true)}
                    className="filter-btn d-flex align-items-center justify-content-center text-capitalize"
                  >
                    <img alt={" "} src={Images.filter_icon} /> Filter
                  </Button>
              </div>
              <div className="new-opportunity-btn-div ml-3">
                <Link
                  to={reverse(routes.dashboard.contacts.create)}
                  className="new-opportunity-btn d-flex align-items-center justify-content-center text-capitalize"
                >
                  + Create Contact
                </Link>
              </div>
              <GenericFilterDrawer
                  visible={this.state.visible}
                  onClose={() => this.handleVisibleChange(false)}
                  filters={[
                    { type: FILTER_TYPE_ACCOUNT_TYPE, label:'Account Type', name:'account__account_type__in' },
                    { type: FILTER_TYPE_OPPORTUNITY, label:'Associated Opportunity', name:'customer_contact_accounts__opportunity__in' },
                    { type: FILTER_TYPE_PROJECT, label: 'Associated Project', name:'project_customer__project_customer_contact__project__in',},
                    { type: FILTER_TYPE_BILLING_ACCOUNT, label: 'Billing Account', name:'account__in' },
                    { type: FILTER_TYPE_CITY, label: 'City', name:'account__main_address__city__in' },
                    { type: FILTER_TYPE_STATE, label: 'State', name:'account__main_address__state__in' },
                    { type: FILTER_TYPE_DATE_RANGE, label: 'Last Activity Date', name:'modified' },
                  ]}
                  changeFilters={(filters, label) => this.handleFilterChange(filters, label)}
                  isClearFilter={this.state.isClearFilter}
                  storageName={"contacts"}
              />
            </div>
          </div>
        </div>

          <div className="row mx-0 opportunities-table-main-dashboard">
              {this.state.filterApplied &&
                  <FilterBadge
                      filterObj={this.state.filterObj}
                      handleFilterChange={this.handleFilterChange}
                      filters={[
                          { type: FILTER_TYPE_ACCOUNT_TYPE, label:'Account Type', name:'account__account_type__in' },
                          { type: FILTER_TYPE_OPPORTUNITY, label:'Associated Opportunity', name:'customer_contact_accounts__opportunity__in' },
                          { type: FILTER_TYPE_PROJECT, label: 'Associated Project', name:'project_customer__project_customer_contact__project__in',},
                          { type: FILTER_TYPE_BILLING_ACCOUNT, label: 'Billing Account', name:'account__in' },
                          { type: FILTER_TYPE_CITY, label: 'City', name:'account__main_address__city__in' },
                          { type: FILTER_TYPE_STATE, label: 'State', name:'account__main_address__state__in' },
                          { type: FILTER_TYPE_DATE_RANGE, label: 'Last Activity Date', name:'modified' },
                      ]}
                      // breadcrumb={this.state.breadcrumb}
                      setIsCleanFilter={(value) => this.setState({isClearFilter: value})}
                  />
              }
          </div>
      </React.Fragment>
    );
  }
}

export default ContactMinHeader;
