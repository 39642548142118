import React, { useEffect, useState } from "react";
import { useSlate, useSlateStatic } from "slate-react";
import Button from "../Common/Button";
import Icon from "../Common/Icon";
import defaultToolbarGroups from "./toolbarGroups";
import {
  toggleBlock,
  toggleMark,
  isMarkActive,
  addMarkData,
  isBlockActive,
  activeMark,
} from "../Utils/SlateUitlityFunctions";
import LinkButton from "../Elements/Link/LinkButton";
import "./styles.css";
import { InsertImageButton } from "../Elements/Image/utils";

const Toolbar = () => {
  const editor = useSlate();
  const [toolbarGroups, setToolbarGroups] = useState(defaultToolbarGroups);

  const BlockButton = ({ format }) => {
    return (
      <Button
        className={isBlockActive(editor, format) ? "btnActive" : ""}
        active={isBlockActive(editor, format)}
        format={format}
        onMouseDown={(e) => {
          e.preventDefault();
          toggleBlock(editor, format);
        }}
      >
        <Icon icon={format} />
      </Button>
    );
  };
  const MarkButton = ({ format }) => {
    return (
      <Button
        className={isMarkActive(editor, format) ? "btnActive" : ""}
        active={isMarkActive(editor, format)}
        format={format}
        onMouseDown={(e) => {
          e.preventDefault();
          toggleMark(editor, format);
        }}
      >
        <Icon icon={format} />
      </Button>
    );
  };
  const Dropdown = ({ format, options }) => {
    return (
      <select
        value={activeMark(editor, format)}
        onChange={(e) => changeMarkData(e, format)}
      >
        {options.map((item, index) => (
          <option key={index} value={item.value}>
            {item.text}
          </option>
        ))}
      </select>
    );
  };
  const changeMarkData = (event, format) => {
    event.preventDefault();
    const value = event.target.value;
    addMarkData(editor, { format, value });
  };
  
  return (
    <div className="toolbar">
      {toolbarGroups.map((group, index) => (
        <span key={index} className="toolbar-grp">
          {group.map((element) => {
            switch (element.type) {
              case "block":
                return <BlockButton key={element.id} {...element} />;
              case "mark":
                return <MarkButton key={element.id} {...element} />;
              case "image":
                return <InsertImageButton key={element.id} {...element} />;
              // case 'link':
              //     return <LinkButton key={element.id} active={isBlockActive(editor,'link')} editor={editor}/>
              default:
                return <button>Invalid Button</button>;
            }
          })}
        </span>
      ))}
    </div>
  );
};

export default Toolbar;
