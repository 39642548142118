import React, { useEffect, useState } from "react";
import OwnerAccountMinHeader from "../../../min-header/OwnerAccountMinHeader";
import OwnerAccountTableMain from "./OwnerAccountTableMain";
import { connect } from "react-redux";
import { setBreadcrumb } from "../../../../Store/actions/breadcrumbAction";
import { routes } from "../../../../Controller/Routes";
import { getOwnerAccount } from "../../../../Controller/api/ownerAccountServices";
import { message } from "antd";
import { Image as Images } from '../../../Images'
import {debounceEvent, getPaginationFromStorage} from "../../../../Controller/utils";

const OwnerAccountsMain = (props) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 15,
        showSizeChanger: false,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`
    })
    const [search, setSearch] = useState(null);
    const [date, setDate] = useState({
        start: "",
        end: "",
        start_modified: "",
        end_modified: ""
    })
    const [filters, setFilters] = useState()

   
    useEffect(() => {
        handleFiltersApplied()
    }, [search, date])

    const fetchOwnerAccountData = async (params = {}, localFilters=null) => {
        setLoading(true);
        if (!params.ordering) {
            params.ordering = "-modified";
        }
        const _filters = localFilters || filters;
        const getFilters = {search, ...params, page: params.page || 1, ...date, ..._filters};

        try {
            const res = await  getOwnerAccount(getFilters);
            setData(res.data.results);
            setLoading(false);
            setPagination({
                ...pagination,
                current: params.page || 1,
                total: res.data.count,
            })

        } catch (err) {
            setLoading(false);
            if (err.response) {
                Object.keys(err.response.data).map((e) => {
                    message.error(err.response.data[e]);
                });
            }

        }

    };
    const onSearch = (e) => {
        setSearch(e.target.value)
    };
    const handleFiltersApplied = (filters = null) => {
        let arr = [{
            title: "Site Manager Accounts", url: routes.dashboard.owner_account.self,
        },];
        props.setBreadcrumb(arr);

        const params = getPaginationFromStorage("page", "name")

        setTimeout(() => {
            fetchOwnerAccountData(params, filters);
        }, 100)
    }

    const handleTableChange = (pagination, filters, sorter) => {
        let symbol = "";
        if (sorter.order === "descend") symbol = "-";
        let params = {
            page: pagination.current,
        };
        if (sorter.columnKey) {
            params.ordering = `${symbol}${sorter.columnKey}`;
        } else {
            params.ordering = "-modified";
        }

        sessionStorage.setItem("page", pagination.current);
        fetchOwnerAccountData(params);

    };
    const getLocaleData = () => {
        return (<div className="col-12 no-data-card-row-new-table">
            <div className="row no-data-upload-screens no-data-second m-0 border-0">
                <div className="col-12 text-center">
                    <img src={Images.person_gray_icon} alt="" className="img-fluid" />
                    <h6 className="no-data-main-tg mb-0">No Site Manager Accounts</h6>
                </div>
            </div>
        </div>)
    }

    const handleDatawithFilter = (data) => {
        setDate({...data})
    }
    const handleFilterChange = (value) => {
        setFilters(value);
        setTimeout(() => {
            handleFiltersApplied(value);
        }, 200)
    }


    return (<React.Fragment>
        <OwnerAccountMinHeader
            onSearch={debounceEvent(onSearch, 1000)}
            fetchData={(data) => handleDatawithFilter(data)}
            onChangeFilters={(filters) => handleFilterChange(filters)}
        />
        <div className="row mx-0 opportunities-table-main-dashboard">
            <OwnerAccountTableMain
                data={data}
                locale={!loading ? { emptyText: getLocaleData() } : { emptyText: " " }}
                loading={loading}
                pagination={pagination}
                handleTableChange={handleTableChange}
                changeFilters={handleFilterChange}
            />
        </div>
    </React.Fragment>);
}


export default connect(null, { setBreadcrumb })(OwnerAccountsMain);
