import { LOGIN_SUCCESS, CHANGE_DEFAULT_WAREHOUSE} from "../actions/authAction";

export interface UserLoginState {
  user: null
}

const initialState: UserLoginState = {
  user: null
};

export const userLoginReducer = (state = initialState , action: any): UserLoginState => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {...state, ...action.payload};
    case CHANGE_DEFAULT_WAREHOUSE:
      return {...state, ...action.payload}
    default:
      return state;
  }
};

export default userLoginReducer;
