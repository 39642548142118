import React, { Component } from "react";
import { Table } from "antd";
import ManagementHeader from "../../management/ManagementHeader";
import { history } from "../../../Controller/history";
import { reverse } from "named-urls";
import { routes } from "../../../Controller/Routes";
import {getProposals, getProposalTemplate} from "../../../Controller/api/proposalServices";
import { formatDate } from "../../../Controller/utils";
import { handleError } from "../../../Controller/Global";
import { setBreadcrumb } from "../../../Store/actions/breadcrumbAction";
import { connect } from "react-redux";
import { checkProposalFieldsRequired } from "../../../Controller/utils";
import { debounce } from "lodash";
import {Image as Images} from "../../Images";
import {
    FILTER_TYPE_BILLING_ACCOUNT, FILTER_TYPE_DATE_RANGE, FILTER_TYPE_NUMBER_RANGE, FILTER_TYPE_OPPORTUNITY,
    FILTER_TYPE_PRIMARY_TEAM_MEMBER, FILTER_TYPE_PROJECT, FILTER_TYPE_PROPOSAL_STATUS, FILTER_TYPE_SITE_MANAGER
} from "../../drawers/generic-filter/GenericFilterDrawerTypes";
import {LoadingOutlined} from "@ant-design/icons";


class ProposalTemplatesMain extends Component {
    state = {
        active: "1",
        proposals: [],
        pagination: {
            search: "",
            current: 1,
            pageSize: 15,
            showSizeChanger: false,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
        },
        date: {
            start: "",
            end: "",
            start_modified: "",
            end_modified: ""
        },
        loading: true,
        filters: {},
    };
    columns = [
        {
            title: "Template Id",
            dataIndex: "id",
            // sorter: {
            //     compare: Sorter.DEFAULT
            // },
            sorter: true,
            key: "id",
        },
        {
            title: "Proposal template name",
            // dataIndex: "name",
            // sorter: {
            //     compare: Sorter.DEFAULT
            // },
            sorter: true,
            // key: "name",
            render: data => {
                // console.log(data, "dsfs")
                return (<div>
                    <p style={{ marginTop: '12px' }}>{data.name || "-"}</p>
                </div>)
            }
        },
        {
            title: "Created By",
            dataIndex: "created_by",
            sorter: true,
            key: "created_by",
        },
        {
            title: "Create Date",
            dataIndex: "created",
            // sorter: {
            //     compare: Sorter.DEFAULT
            // },
            sorter: true,
            key: "created",
            render: (data) => <div className="text-capitalize">{formatDate(data)}</div>,
        },
    ];

    componentDidMount() {
        this.fetchProposals();
    }

    fetchProposals = (params = {}) => {
        const {search,date, filters} = this.state;
        this.setState({ loading: true })
        getProposalTemplate()
            .then((res) => {
                this.setState({
                    proposals: res.data.results,
                    pagination: {
                        ...this.state.pagination,
                        current: params.page || 1,
                        total: res.data.count,
                    },
                    loading: false,
                });
            })
            .catch((err) => {
                handleError(err);
                this.setState({ loading: false });
            });
    };

    debounceEvent = (...args) => {
        this.debouncedEvent = debounce(...args);
        return (e) => {
            e.persist();
            return this.debouncedEvent(e);
        };
    };

    onSearch = (e) => {
        this.setState({search: e.target.value}, () => {
            this.fetchProposals();
        })
    };

    handleTableChange = (pagination, filters, sorter) => {
        // this.fetchProposals({ page: pagination.current });
        let symbol = "";
        if (sorter.order === "descend") symbol = "-";
        let params = {
            page: pagination.current,
        };

        sessionStorage.setItem("page", pagination.current );

        if (sorter.columnKey) {
            params.ordering = `${symbol}${sorter.columnKey}`;
        } else {
            params.ordering = "name";
        }
        this.setState({ loading: true });
        this.fetchProposals(params);
    };
    getLocateData = () =>{
        return(
            <div className="col-12 no-data-card-row-new-table">
                <div className="row no-data-upload-screens no-data-second m-0 border-0">
                    <div className="col-12 text-center">
                        <img src={Images.propsal_icon_add} alt="" className="img-fluid"/>
                        <h6 className="no-data-main-tg mb-0">No Proposals</h6>
                    </div>
                </div>
            </div>
        )
    }

    handleFilterData = (data) => {
        if(data) {
            this.setState({date:{...this.state.date,...data}},() => {
                this.fetchProposals();
            })
        }
        else {
            this.setState({date: null}, () => {
                this.fetchProposals();
            })
        }
    }
    handleFilterChange = (value) => {
        this.setState({filters: value});

        const pageValueFromStorage = sessionStorage.getItem("page");
        let params = {
            page: pageValueFromStorage ? Number(pageValueFromStorage) : 1,
        }


        setTimeout(() => {
            this.fetchProposals(params);
        }, 100)
    }

    onTabChange = (key) => {
        this.setState({ active: key });
    };

    render() {
        let { proposals, pagination, loading } = this.state;
        return (
            <>
                <ManagementHeader
                    buttonName={"Proposal Templates"}
                    onSearch={this.debounceEvent(this.onSearch,1000)}
                    fetchData={(data) => this.handleFilterData(data)}
                    onChangeFilters={(filters) => this.handleFilterChange(filters)}
                    filters={[
                        { type: FILTER_TYPE_PROPOSAL_STATUS, label:'Status', name:'status__in' },
                        { type: FILTER_TYPE_PRIMARY_TEAM_MEMBER, label: 'Primary Team Member', name:'point_proposal__in' },
                        { type: FILTER_TYPE_OPPORTUNITY, label: 'Associated Opportunity', name:'opportunity__in' },
                        { type: FILTER_TYPE_PROJECT, label: 'Associated Project', name:'project' },
                        { type: FILTER_TYPE_BILLING_ACCOUNT, label: 'Billing Account', name:'customer_contact_accounts__account__in' },
                        { type: FILTER_TYPE_SITE_MANAGER, label: 'Site Manager Account', name:'owner_contact_accounts__account__in' },
                        { type: FILTER_TYPE_NUMBER_RANGE, label: 'Estimated Total', name:'estimated_revenue' },
                        { type: FILTER_TYPE_DATE_RANGE, label: 'Proposal Date Created', name:'creation_date', with_time: false},
                        { type: FILTER_TYPE_DATE_RANGE, label: 'Proposal Date Due', name:'due_date', with_time: false},
                        { type: FILTER_TYPE_DATE_RANGE, label: 'Start Date', name:'project_start_date', with_time: false},
                        { type: FILTER_TYPE_DATE_RANGE, label: 'End Date', name:'project_end_date', with_time: false},
                    ]}
                    loading={this.state.loading}
                    storageName={"proposal"}
                />
                <div className="row mx-0 width-160-id opportunities-table-main-dashboard">
                    <div className="col-12 table-responsive main-table-div proposals-main-table opportunity-db-table">
                        <Table
                            scroll={{y: 500 , x:1300}}
                            loading={loading ? {indicator:<LoadingOutlined style={{ fontSize: 24, color: '#9bd2bb' }} />} : false }
                            className="main-table-all sorter-design-fix"
                            columns={this.columns}
                            dataSource={proposals}
                            onChange={this.handleTableChange}
                            pagination={pagination}
                            size="middle"
                            locale={!loading ? {emptyText:this.getLocateData()}: {emptyText:" "}}
                            onRow={(template) => {
                                return {
                                    onClick: (event) => {
                                        history.push({
                                            pathname: reverse(routes.dashboard.sales.proposal.template, {
                                                id: template.id
                                            })
                                        })
                                    },
                                };
                            }}
                        />
                    </div>
                </div>
            </>
        );
    }
}

export default connect(null, { setBreadcrumb })(ProposalTemplatesMain);
