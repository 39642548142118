import React, {useContext, useEffect, useState} from "react";
import {Line} from 'react-chartjs-2';
import styles from './OperationLineChartStyles.module.css';
import {Select} from "antd";
import {LoadingOutlined} from "@ant-design/icons";
import {OperationsReportContext} from "../../OperationReportMain";


const OperationLineChart = ({
    selectedGroupByData,
    selectedDataType,
    setSelectedDataType,
    handleGroupByDataChange,
    isLoading
}) => {

    const data = useContext(OperationsReportContext);
    const [label, setLabel] = useState([]);
    const [chartData, setChartData] = useState([]);


    const optionsForLineChart = {
        responsive: true,
        interaction: {
            mode: "index",
            intersect: false,
        },
        plugins: {
            legend: {
                display: false,
                position: "top",
                align: "start",
                labels: {
                    color: 'rgba(132, 154, 169, 1)',
                    padding: 16,
                },
            },
            title: {
                display: false,
                text: "YTD",
                align: 'start',
            },
            tooltip: {
                titleColor: "rgba(79, 79, 79, 1)",
                backgroundColor: "#FFFFFF",
                borderColor: 'rgba(132, 154, 169, 0.3)',
                borderWidth: 0.6,
                padding: 16,
                bodySpacing: 10,
                titleMarginBottom: 16,
                displayColors: true,
                boxWidth: 5,
                boxPadding: 5,
                callbacks: {
                    labelTextColor: function(context) {
                        return 'rgba(132, 154, 169, 1)';
                    },
                    afterTitle: function(context) {
                        return  "";
                    },
                    afterBody: function(context) {
                        return "";
                    },

                }
            }
        },

    };

    const sortOptions = [
        {
            value: 'profit',
            label: 'Profit',
        },
        {
            value: 'revenue',
            label: 'Revenue',
        },
        {
            value: 'expense',
            label: 'Expense',
        },
    ];

    const sortOptionsTime = [
        {
            value: 'month',
            label: 'Month',
        },
        {
            value: 'week',
            label: 'Week',
        },
        {
            value: 'quarter',
            label: 'Quarter',
        },
        {
            value: 'year',
            label: 'Year',
        },
    ]

    const getMonthName = (monthNumber) => {
        const date = new Date();
        date.setMonth(monthNumber);

        return date.toLocaleString('en-US', { month: 'short' });
    }

    const getDateFromWeekNumber = (weekNumber, year) => {
        const date = new Date(year, 0, 1);
        const dayMilliseconds = 24 * 60 * 60 * 1000;
        const startDay = date.getDay() || 7;
        const startDate = date.getTime() + (4 - startDay) * dayMilliseconds;
        const targetTime = startDate + (weekNumber - 1) * 7 * dayMilliseconds;

        date.setTime(targetTime);
        return new Date(date).toLocaleDateString('en-us', { year:"numeric", month:"short", day:"numeric"});
    }


    const handleGetDateLabelForChart = (data) => {

        const dateLabel = data.map((i) => {
            let date
            if (selectedGroupByData.value === 'month') {
                const month = getMonthName(i.month);
                date = `${month}, ${i.year}`;
            } else if (selectedGroupByData.value === 'year') {
                date = `${i.year}`;
            } else if (selectedGroupByData.value === 'week') {
                const formatDate = getDateFromWeekNumber(i.week, i.year)
                date = `week ${i.week}, ${formatDate}`;
            } else if (selectedGroupByData.value === 'quarter') {
                const fiscal_quarter = i.fiscal_quarter;
                if (fiscal_quarter === 'Q1') {
                    date = ` 1 January – 31 March, ${i.year}`;
                } else if (fiscal_quarter === 'Q2') {
                    date = ` 1 April – 30 June, ${i.year}`;
                } else if (fiscal_quarter === 'Q3') {
                    date = ` 1 July – 30 September, ${i.year}`;
                } else if (fiscal_quarter === 'Q4') {
                    date = ` 1 October – 31 December, ${i.year}`;
                }
            }
            return date
        })

        setLabel(dateLabel)
    }



    const handleDataTypeChange = (value) => {
        setSelectedDataType(value)

        let chartValue;
        if (value.value === 'expense') {
            chartValue = data.map((i) => i.projected_expense)
            setChartData(chartValue);
        } else if ( value.value === 'revenue') {
            chartValue = data.map((i) => i.projected_revenue)
            setChartData(chartValue);
        } else {
            chartValue = data.map((i) => i.projected_profit)
            setChartData(chartValue);
        }
    }

    useEffect(() => {
        if (data && data.length > 0 ) {
            handleDataTypeChange(selectedDataType)
            handleGetDateLabelForChart(data)
        }
        else {
            setChartData(null)
        }
    },[data])

    return (
        <div className={styles.main}>
            <div className={`${styles.graphHeader} ${styles.flexBox} data-table`}>
                <h5>Performance: {selectedDataType.label}</h5>
                <div className={`${styles.flexBox}`}>
                    <Select
                        labelInValue
                        defaultValue={{
                            value: 'profit',
                            label: 'Profit',
                        }}
                        style={{
                            width: 120,
                            margin: "0 10px"
                        }}
                        onChange={handleDataTypeChange}
                        options={sortOptions}
                    />
                    <Select
                        labelInValue
                        defaultValue={{
                            value: 'month',
                            label: 'Month',
                        }}
                        style={{
                            width: 120,
                        }}
                        onChange={handleGroupByDataChange}
                        options={sortOptionsTime}
                    />
                </div>
            </div>
            {isLoading ? (
                <div className="text-center create-div m-5">
                    <div className="loading-spinner">
                        <LoadingOutlined style={{ fontSize: 24, color: '#9bd2bb' }} />
                    </div>
                </div>
            ) : (
                <div className={styles.graph}>
                    <Line
                        options={optionsForLineChart}
                        data={{
                            labels: label,
                            datasets: [
                                {
                                    label: `${selectedDataType.label}`,
                                    data: chartData ? chartData.map((i) => Math.floor(i)) : [],
                                    borderColor: 'rgba(0, 58, 140, 1)',
                                    backgroundColor: (context) => {
                                        const ctx = context.chart.ctx;
                                        const gradient = ctx.createLinearGradient(0, 0, 0, 700);
                                        gradient.addColorStop(0, "rgba(0, 58, 140, 0.3)");
                                        gradient.addColorStop(0.5, "rgba(0, 58, 140, 0.2)");
                                        gradient.addColorStop(1, "rgba(0, 58, 140, 0)");
                                        return gradient;
                                    },
                                    borderWidth: 1.5,
                                    fill: true,
                                    // tension: 0.2,
                                },
                            ]
                        }}
                    />
                </div>
            )}
        </div>
    )
}

export default OperationLineChart;