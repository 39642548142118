import React, { useEffect, useState } from "react";
import styles from './SaleProgressBarCardStyles.module.css'
import {Progress} from "antd";



const SalesProgressCard = ({ progressBarData }) => {
    return (
        <div className={styles.card}>
            <div className={`${styles.flexRow} ${styles.header}`}>
                <div className={styles.title}>Total Proposals</div>
                <h2>112</h2>
            </div>
            <div>
                <div>
                    { progressBarData.map((i) => {
                        return (
                            <div className={`${styles.progressContainer}`}>
                                <div className={`${styles.flexRow} col-9`}>
                                    <p>{i.title}</p>
                                    <p>{i.value}</p>
                                </div>
                                <div className={`col-3`}>
                                    <Progress
                                        type="line"
                                        strokeColor={
                                            'rgba(9, 109, 217, 1)'
                                        }
                                        strokeLinecap="square"
                                        percent={i.value}
                                        showInfo={false}
                                    />
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>

        </div>
    )
}

export default SalesProgressCard;