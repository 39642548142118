export const SITE_OWNER = "SITE_OWNER";
export const CUSTOMER = "CUSTOMER";
export const CUSTOMER_OWNER = "CUSTOMER_OWNER";
export const VENDOR = "VENDOR"

export const userTypes = {
  SITE_OWNER: "Site Manager",
  CUSTOMER: "Billing",
  CUSTOMER_OWNER: "Billing, Site Manager",
  VENDOR: "Vendor"
};
