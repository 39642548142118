import {Button, Drawer} from "antd";
import React, {useEffect, useState} from "react";
import {
    FILTER_TO_API_MAPPING,
    FILTER_TYPE_DATE_RANGE, FILTER_TYPE_NUMBER_RANGE,
    GENERIC_FILTER_TYPES,
    FILTER_TYPE_DATE_RANGE_WITH_SELECTOR
} from "./GenericFilterDrawerTypes";
import GenericFilterField from "./GenericFilterField";
import GenericDateFilterField from "./GenericDateFilterField";
import GenericNumberRangeFilterField from "./GenericNumberRangeFilterField";
import GenericDateFilterFieldWithSelector from "./GenericDateFilterFieldWithSelector";


export function GenericFilterDrawer({ visible, onClose, children, filters, changeFilters, isClearFilter,  storageName }) {


    const [filtersValue, setFiltersValue] = useState({})
    const [filterValueWithLabel, setFilterValeWithLabel] = useState({})
    const [isCustomDateRange, setIsCustomDateRange] = useState(false);


    const changeFilterValue = (filter, newValue, newLabel) => {

        const newFilters = {...filtersValue}
        const newFilterWithLabel = {...filterValueWithLabel}
        if ([FILTER_TYPE_DATE_RANGE, FILTER_TYPE_NUMBER_RANGE, FILTER_TYPE_DATE_RANGE_WITH_SELECTOR].includes(filter.type)){
            newFilters[`${filter.name}__gte`] = newValue[0]
            newFilters[`${filter.name}__lte`] = newValue[1]
            newFilterWithLabel[`${filter.name}__gte`] = newValue[0]
            newFilterWithLabel[`${filter.name}__lte`] = newValue[1]

        }
        else {
            newFilters[filter.name] = newValue
            newFilterWithLabel[filter.name] = newLabel
        }

        setFiltersValue(newFilters)
        setFilterValeWithLabel(newFilterWithLabel)

    }
    const clearValues = () => {
        const defaultFilterValue = {}
        for (const filter of filters) {
            if([FILTER_TYPE_DATE_RANGE, FILTER_TYPE_NUMBER_RANGE, FILTER_TYPE_DATE_RANGE_WITH_SELECTOR].includes(filter.type)) {
                changeFilterValue(filter.name, [null, null])
            }
            else {
                changeFilterValue(filter.name, undefined)
            }
        }
        setFiltersValue(defaultFilterValue)
        setFilterValeWithLabel(defaultFilterValue)
        setIsCustomDateRange(false)

        storageName && sessionStorage.removeItem(`${storageName}-filters`);
        storageName && sessionStorage.removeItem(`${storageName}-filters-label`);
        sessionStorage.removeItem("page");
        sessionStorage.removeItem(`${storageName}-page`);


        handleApplyFilter(defaultFilterValue, defaultFilterValue)
    }

    const handleApplyFilter = (overrideFilters = null, overrideFiltersWithLabel = null) => {
        let reducedFilters = {...filtersValue};
        let reducedFiltersWithLabel = {...filterValueWithLabel}

        if (overrideFilters !== null) {
           reducedFilters = {...overrideFilters}
           reducedFiltersWithLabel = {...overrideFiltersWithLabel}
        }
        for (const filter of filters) {
            if (filter.type === FILTER_TYPE_DATE_RANGE || filter.type === FILTER_TYPE_DATE_RANGE_WITH_SELECTOR ){
                let dateFormatGTE = 'YYYY-MM-DDT00:00';
                let dateFormatLTE = 'YYYY-MM-DDT23:59';
                if (filter.with_time !== undefined && filter.with_time === false) {
                    dateFormatGTE = 'YYYY-MM-DD';
                    dateFormatLTE = 'YYYY-MM-DD';
                }

                if (typeof reducedFilters[`${filter.name}__gte`] !== "string" && typeof reducedFilters[`${filter.name}__lte`] !== "string") {

                    reducedFilters[`${filter.name}__gte`] = reducedFilters[`${filter.name}__gte`]?.format(dateFormatGTE);
                    reducedFilters[`${filter.name}__lte`] = reducedFilters[`${filter.name}__lte`]?.format(dateFormatLTE);

                    reducedFiltersWithLabel[`${filter.name}__gte`] = reducedFiltersWithLabel[`${filter.name}__gte`]?.format(dateFormatGTE);
                    reducedFiltersWithLabel[`${filter.name}__lte`] = reducedFiltersWithLabel[`${filter.name}__lte`]?.format(dateFormatLTE);
                }

            }
        }

        if (  visible ) {
            sessionStorage.removeItem("page");
            sessionStorage.removeItem(`${storageName}-page`);
        }

        storageName && sessionStorage.setItem(`${storageName}-filters`, JSON.stringify(reducedFilters));
        storageName && sessionStorage.setItem(`${storageName}-filters-label`, JSON.stringify(reducedFiltersWithLabel));


        changeFilters(reducedFilters, reducedFiltersWithLabel);
        onClose();
    }


    useEffect(() => {
        if (isClearFilter === true) {
            clearValues()
        }

    }, [isClearFilter])

    useEffect(() => {
        if ( storageName ) {
            const filterValueFromStorage = sessionStorage.getItem(`${storageName}-filters`);
            const filterWithLabelValueFromStorage = sessionStorage.getItem(`${storageName}-filters-label`);

            if (filterValueFromStorage && filterWithLabelValueFromStorage) {
                setFiltersValue(JSON.parse(filterValueFromStorage))
                setFilterValeWithLabel(JSON.parse(filterWithLabelValueFromStorage))
                handleApplyFilter(JSON.parse(filterValueFromStorage), JSON.parse(filterWithLabelValueFromStorage));
            }
        }
    }, [])

    const renderFilter = (filter, key) => {
        if (GENERIC_FILTER_TYPES.has(filter.type))
            return (
                <div className={'col-12 my-2'} key={key}>
                    <GenericFilterField
                        onChange={(newValue, newLabel) => changeFilterValue(filter, newValue, newLabel)}
                        label={filter.label}
                        values_api_url={FILTER_TO_API_MAPPING[filter.type]}
                        value={filtersValue[filter.name]}
                    />
                </div>
        )

        else if (filter.type === FILTER_TYPE_DATE_RANGE) {
            return (
                <div className={'col-12 my-2 justify-content-between d-flex flex-wrap align-items-center'} key={key}>
                    <GenericDateFilterField
                        onChange={(newValue) => changeFilterValue(filter, newValue)}
                        label={filter.label}
                        valueGTE={filtersValue[`${filter.name}__gte`]}
                        valueLTE={filtersValue[`${filter.name}__lte`]}
                    />
                </div>
            )
        }

        else if (filter.type === FILTER_TYPE_DATE_RANGE_WITH_SELECTOR) {
            return (
                <div className={'col-12 my-2 justify-content-between d-flex flex-wrap align-items-center'} key={key}>
                    <GenericDateFilterFieldWithSelector
                        onChange={(newValue) => changeFilterValue(filter, newValue)}
                        label={filter.label}
                        valueGTE={filtersValue[`${filter.name}__gte`]}
                        valueLTE={filtersValue[`${filter.name}__lte`]}
                        isCustomDateRange={isCustomDateRange}
                        setIsCustomDateRange={setIsCustomDateRange}
                    />
                </div>
            )
        }

        else if (filter.type === FILTER_TYPE_NUMBER_RANGE) {
            return (
                <div className={'col-12 my-2 justify-content-between d-flex flex-wrap align-items-center'} key={key}>
                    <GenericNumberRangeFilterField
                        onChange={(newValue) => changeFilterValue(filter, newValue)}
                        label={filter.label}
                        valueGTE={filtersValue[`${filter.name}__gte`]}
                        valueLTE={filtersValue[`${filter.name}__lte`]}
                    />
                </div>
            )
        }

        else return <div>{filter.type}</div>
    }

    return (
        <Drawer
            title="Filter"
            visible={visible}
            closable={true}
            onClose={onClose}
            onCancel={clearValues}
            className="main-all-form-modal main-drawer-div drawer-update"
            width={"400px"}
            placement={"right"}
            footer={
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <Button
                        className="apply-filter-update"
                        onClick={clearValues}
                        style={{marginRight: 8}}
                    >
                        Clear
                    </Button>
                    <Button
                        className="apply-filter-update"
                        onClick={() => handleApplyFilter()}
                        type="primary"
                    >
                        Apply Filter
                    </Button>
                </div>
            }
        >
            <div className="row mx-0 inner-modal-main-row">
                <div className="col-12">
                    <div className="row info-gray-div align-items-center">
                        <h6 className="mb-0">
                            Please search and select to filter information.
                        </h6>
                    </div>
                </div>
                {filters.map((filter, i) => renderFilter(filter, i))}
            </div>
            {children}
        </Drawer>
    )
}

export default GenericFilterDrawer;