
export const FILTER_TYPE_NUMBER_RANGE = "FILTER_TYPE_NUMBER_RANGE"
export const FILTER_TYPE_DATE_RANGE = "FILTER_TYPE_DATE_RANGE"
export const FILTER_TYPE_DATE_RANGE_WITH_SELECTOR = "FILTER_TYPE_DATE_RANGE_WTH_SELECTOR"
export const FILTER_TYPE_CITY  = "FILTER_TYPE_CITY"
export const FILTER_TYPE_STATE = "FILTER_TYPE_STATE"
export const FILTER_TYPE_BILLING_ACCOUNT  = "FILTER_TYPE_BILLING_ACCOUNT"
export const FILTER_TYPE_SITE_MANAGER  = "FILTER_TYPE_SITE_MANAGER"
export const FILTER_TYPE_INDUSTRY  = "FILTER_TYPE_INDUSTRY"
export const FILTER_TYPE_PRIMARY_TEAM_MEMBER  = "FILTER_TYPE_PRIMARY_TEAM_MEMBER"
export const FILTER_TYPE_ACCOUNT_TYPE  = "FILTER_TYPE_ACCOUNT_TYPE"
export const FILTER_TYPE_PROJECT = "FILTER_TYPE_PROJECT"
export const FILTER_TYPE_PROJECT_STATUS = "FILTER_TYPE_PROJECT_STATUS"
export const FILTER_TYPE_PROJECT_SOURCE = "FILTER_TYPE_PROJECT_SOURCE"
export const FILTER_TYPE_OPPORTUNITY = "FILTER_TYPE_OPPORTUNITY"
export const FILTER_TYPE_OPPORTUNITY_STATUS = "FILTER_TYPE_OPPORTUNITY_STATUS"
export const FILTER_TYPE_OPPORTUNITY_SOURCE = "FILTER_TYPE_OPPORTUNITY_SOURCE"
export const FILTER_TYPE_BID_SECTOR = "FILTER_TYPE_BID_SECTOR"
export const FILTER_TYPE_PROPOSAL = "FILTER_TYPE_PROPOSAL"
export const FILTER_TYPE_PROPOSAL_STATUS = "FILTER_TYPE_PROPOSAL_STATUS"
export const FILTER_TYPE_INTERNAL_SITE = "FILTER_TYPE_INTERNAL_SITE"
export const FILTER_TYPE_LABOR_GROUP = "FILTER_TYPE_LABOR_GROUP"
export const FILTER_TYPE_UNION_TYPES = "FILTER_TYPE_UNION_TYPES"
export const FILTER_TYPE_LABOR_REGION = "FILTER_TYPE_LABOR_REGION"
export const FILTER_TYPE_EMPLOYEE = "FILTER_TYPE_EMPLOYEE"
export const FILTER_TYPE_SALES_PEOPLE = "FILTER_TYPE_SALES_PEOPLE"
export const FILTER_TYPE_WAREHOUSE = "FILTER_TYPE_WAREHOUSE"
export const FILTER_TYPE_FLEET_GROUP = "FILTER_TYPE_FLEET_GROUP"
export const FILTER_TYPE_FLEET_GROUP_TREE_ID = "FILTER_TYPE_FLEET_GROUP_TREE_ID"
export const FILTER_TYPE_FLEET_FAMILY = "FILTER_TYPE_FLEET_FAMILY"
export const FILTER_TYPE_FLEET_FAMILY_TIER = "FILTER_TYPE_FLEET_FAMILY_TIER"
export const FILTER_TYPE_FLEET_VEHICLE = "FILTER_TYPE_FLEET_VEHICLE"
export const FILTER_TYPE_FLEET_VEHICLE_MAKE = "FILTER_TYPE_FLEET_VEHICLE_MAKE"
export const FILTER_TYPE_FLEET_VEHICLE_MODEL = "FILTER_TYPE_FLEET_VEHICLE_MODEL"
export const FILTER_TYPE_FLEET_VEHICLE_YEAR = "FILTER_TYPE_FLEET_VEHICLE_YEAR"
export const FILTER_TYPE_FLEET_VEHICLE_STATUS = "FILTER_TYPE_FLEET_VEHICLE_STATUS"
export const FILTER_TYPE_SUPPLY_GROUP = "FILTER_TYPE_SUPPLY_GROUP"
export const FILTER_TYPE_SUPPLY_GROUP_TIER = "FILTER_TYPE_SUPPLY_GROUP_TIER"
export const FILTER_TYPE_SUPPLY = "FILTER_TYPE_SUPPLY"
export const FILTER_TYPE_SUPPLY_FAMILY = "FILTER_TYPE_SUPPLY_FAMILY"
export const FILTER_TYPE_INVENTORY_ITEM = "FILTER_TYPE_INVENTORY_ITEM"
export const FILTER_TYPE_INVENTORY_ITEM_FAMILY = "FILTER_TYPE_INVENTORY_ITEM_FAMILY"
export const FILTER_TYPE_INVENTORY_ITEM_TIER = "FILTER_TYPE_INVENTORY_ITEM_TIER"
export const FILTER_TYPE_INVENTORY_FAMILY = "FILTER_TYPE_INVENTORY_FAMILY"
export const FILTER_TYPE_UNIT_OF_MEASUREMENT = "FILTER_TYPE_UNIT_OF_MEASUREMENT"
export const FILTER_TYPE_DISPOSAL = "FILTER_TYPE_DISPOSAL"
export const FILTER_TYPE_DISPOSAL_TIER = "FILTER_TYPE_DISPOSAL_TIER"
export const FILTER_TYPE_DISPOSAL_FAMILY = "FILTER_TYPE_DISPOSAL_FAMILY"
export const FILTER_TYPE_DISPOSAL_CODE = "FILTER_TYPE_DISPOSAL_CODE"
export const FILTER_TYPE_INVOICE_STATUS = "FILTER_TYPE_INVOICE_STATUS"
export const FILTER_TYPE_SITE = "FILTER_TYPE_SITE"
export const FILTER_TYPE_WORK_ORDER_STATUSES = "FILTER_TYPE_WORK_ORDER_STATUSES"

export const FILTER_TO_API_MAPPING = {
    FILTER_TYPE_NUMBER_RANGE: '',
    FILTER_TYPE_DATE_RANGE: '',
    FILTER_TYPE_CITY: 'filter.cities',
    FILTER_TYPE_STATE: 'filter.states',
    FILTER_TYPE_BILLING_ACCOUNT: 'filter.billing_accounts',
    FILTER_TYPE_SITE_MANAGER: 'filter.site_manager_accounts',
    FILTER_TYPE_INDUSTRY: 'filter.industries',
    FILTER_TYPE_PRIMARY_TEAM_MEMBER: 'filter.primary_team_members',
    FILTER_TYPE_ACCOUNT_TYPE: 'filter.account_types',
    FILTER_TYPE_PROJECT: 'filter.projects',
    FILTER_TYPE_PROJECT_STATUS: 'filter.project_statuses',
    FILTER_TYPE_PROJECT_SOURCE: 'filter.sources',
    FILTER_TYPE_OPPORTUNITY: 'filter.opportunities',
    FILTER_TYPE_OPPORTUNITY_STATUS: 'filter.opportunity_statuses',
    FILTER_TYPE_OPPORTUNITY_SOURCE: 'filter.sources',
    FILTER_TYPE_BID_SECTOR: 'filter.opportunity_sectors',
    FILTER_TYPE_PROPOSAL: 'filter.proposals',
    FILTER_TYPE_PROPOSAL_STATUS: 'filter.proposal_statuses',
    FILTER_TYPE_UNION_TYPES: 'filter.union_types',
    FILTER_TYPE_INTERNAL_SITE: '',
    FILTER_TYPE_LABOR_GROUP: 'filter.labor_groups',
    FILTER_TYPE_LABOR_REGION: 'filter.regions',
    FILTER_TYPE_EMPLOYEE: 'filter.employees',
    FILTER_TYPE_SALES_PEOPLE: 'filter.sales_people',
    FILTER_TYPE_WAREHOUSE: 'filter.warehouses',
    FILTER_TYPE_FLEET_GROUP: 'filter.fleet_groups',
    FILTER_TYPE_FLEET_GROUP_TREE_ID: 'filter.fleet_groups_tree_id',
    FILTER_TYPE_FLEET_FAMILY: 'filter.fleet_families',
    FILTER_TYPE_FLEET_FAMILY_TIER: '',
    FILTER_TYPE_FLEET_VEHICLE: 'filter.vehicles',
    FILTER_TYPE_FLEET_VEHICLE_MAKE: 'filter.vehicle_makes',
    FILTER_TYPE_FLEET_VEHICLE_MODEL: 'filter.vehicle_models',
    FILTER_TYPE_FLEET_VEHICLE_YEAR: 'filter.vehicle_years',
    FILTER_TYPE_FLEET_VEHICLE_STATUS: 'filter.vehicle_statuses',
    FILTER_TYPE_SUPPLY_GROUP: 'filter.supply_groups',
    FILTER_TYPE_SUPPLY_GROUP_TIER: 'filter.supply_group_tiers',
    FILTER_TYPE_SUPPLY: 'filter.supplies',
    FILTER_TYPE_SUPPLY_FAMILY: 'filter.supply_families',
    FILTER_TYPE_INVENTORY_ITEM: 'filter.inventory_items',
    FILTER_TYPE_INVENTORY_ITEM_FAMILY: '',
    FILTER_TYPE_INVENTORY_ITEM_TIER: 'filter.inventory_item_tiers',
    FILTER_TYPE_INVENTORY_FAMILY: 'filter.inventory_families',
    FILTER_TYPE_UNIT_OF_MEASUREMENT: 'filter.unit_of_measurements',
    FILTER_TYPE_DISPOSAL: 'filter.disposal_items',
    FILTER_TYPE_DISPOSAL_TIER: 'filter.disposal_item_tiers',
    FILTER_TYPE_DISPOSAL_FAMILY: 'filter.disposal_families',
    FILTER_TYPE_DISPOSAL_CODE: 'filter.disposal_code',
    FILTER_TYPE_INVOICE_STATUS: 'filter.invoice_statuses',
    FILTER_TYPE_SITE: 'filter.sites',
    FILTER_TYPE_WORK_ORDER_STATUSES: 'filter.work_order_statuses',
}

export const GENERIC_FILTER_TYPES = new Set([
    FILTER_TYPE_CITY,
    FILTER_TYPE_STATE,
    FILTER_TYPE_SITE_MANAGER,
    FILTER_TYPE_PRIMARY_TEAM_MEMBER,
    FILTER_TYPE_BID_SECTOR,
    FILTER_TYPE_OPPORTUNITY_STATUS,
    FILTER_TYPE_OPPORTUNITY_SOURCE,
    FILTER_TYPE_BILLING_ACCOUNT,
    FILTER_TYPE_PROPOSAL_STATUS,
    FILTER_TYPE_OPPORTUNITY,
    FILTER_TYPE_PROJECT,
    FILTER_TYPE_PROJECT_STATUS,
    FILTER_TYPE_PROJECT_SOURCE,
    FILTER_TYPE_PROPOSAL,
    FILTER_TYPE_WORK_ORDER_STATUSES,
    FILTER_TYPE_SITE,
    FILTER_TYPE_WAREHOUSE,
    FILTER_TYPE_INDUSTRY,
    FILTER_TYPE_ACCOUNT_TYPE,
    FILTER_TYPE_UNION_TYPES,
    FILTER_TYPE_LABOR_REGION,
    FILTER_TYPE_EMPLOYEE,
    FILTER_TYPE_SALES_PEOPLE,
    FILTER_TYPE_LABOR_GROUP,
    FILTER_TYPE_SUPPLY,
    FILTER_TYPE_SUPPLY_GROUP,
    FILTER_TYPE_SUPPLY_FAMILY,
    FILTER_TYPE_SUPPLY_GROUP_TIER,
    FILTER_TYPE_INVENTORY_ITEM,
    FILTER_TYPE_INVENTORY_ITEM_FAMILY,
    FILTER_TYPE_INVENTORY_ITEM_TIER,
    FILTER_TYPE_INVENTORY_FAMILY,
    FILTER_TYPE_UNIT_OF_MEASUREMENT,
    FILTER_TYPE_DISPOSAL,
    FILTER_TYPE_DISPOSAL_TIER,
    FILTER_TYPE_DISPOSAL_CODE,
    FILTER_TYPE_DISPOSAL_FAMILY,
    FILTER_TYPE_INVOICE_STATUS,
    FILTER_TYPE_FLEET_GROUP,
    FILTER_TYPE_FLEET_GROUP_TREE_ID,
    FILTER_TYPE_FLEET_FAMILY,
    FILTER_TYPE_FLEET_VEHICLE,
    FILTER_TYPE_FLEET_VEHICLE_MAKE,
    FILTER_TYPE_FLEET_VEHICLE_MODEL,
    FILTER_TYPE_FLEET_VEHICLE_YEAR,
    FILTER_TYPE_FLEET_VEHICLE_STATUS,
])