import React, {Component, useState, useEffect} from "react";
import { Button, Form, Input, Table } from "antd";
import { Image as Images } from "../../Images";
import {getProjectById, getProjects} from "../../../Controller/api/projectServices";
import {handleError} from "../../../Controller/Global";
import {history} from "../../../Controller/history";
import {reverse} from "named-urls/src";
import {routes} from "../../../Controller/Routes";
import moment from "moment";
import {LoadingOutlined} from "@ant-design/icons";

export const ProjectsInfo = (props) => {
  const [projects, setProjects] = useState([])
  const [isLoading, setIsloading] = useState(false)
  const [pagination,setPagination] = useState({
    current: 1,
    pageSize: 15,
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
  })
  const projectColumns = [
    {
      title: "Project ID",
      dataIndex: "id",
      sorter: true,
    },
    {
      title: "Project name",
      dataIndex: "name",
      sorter: true,
    },
    {
      title: "Customer",
      dataIndex: "project_customer_contact",
      sorter: true,
      render: (customer) => {
        if (customer) {
          Object.keys(customer).map(function (type, i) {
            return (
              <span key={i}>
                  {customer[type].account.name || "-"}
                </span>
            );
          })
        }else{
          return ''
        }
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: true,
      render: data => data?.title || "-"
    },
    {
      title: <div className="position-relative">LAST ACTIVITY DATE</div>,
      dataIndex: "modified",
      sorter: true,
      render: (data) => <div>{moment(data).format("MMM DD,YYYY hh:mm A")}</div>,
    },
  ];

  const fetchProjects = (params = {}) => {
    setIsloading(true)
    getProjectById( { contact: props.contact.id, ...params }, {...props.projectsQuery})
        .then((res) => {
          setProjects(res.data.results)
          setIsloading(false)
        })
        .catch((err) => {
          handleError(err);
          setIsloading(false)
        });
  };

  const onSearch = (e) => {
    fetchProjects({ search: e.target.value, page: 1 });
  };

  useEffect(() => {
    fetchProjects()
  }, [])



  return (
      <React.Fragment>
          <div className="col-12">
            <div className="row new-opportunity-header-row account-tabs-min summary-header-details search-view-header-in-collapse align-items-center  carpet-cleaning-mini-header">
              <div className="search-bar-div">
                <Form className="position-relative">
                  <Input placeholder="Search"  onChange={onSearch} />
                  <Button className="search-btn position-absolute p-0 border-0 bg-transparent m-auto">
                    <img
                      src={Images.search_icon_gray}
                      className="img-fluid"
                      alt="search icon"
                    />
                  </Button>
                </Form>
              </div>
              {/* <div className="new-opportunity-btn-div">
                  <Button className="new-opportunity-btn text-capitalize">Create</Button>
              </div> */}
              {props.hideTitle && (
                  <Button
                      onClick={() => props.tabChange("7")}
                      className="view-all-btn text-uppercase ml-auto"
                  >
                    VIEW ALL{" "}
                  </Button>
              )}
            </div>
          </div>

          { !isLoading && projects.length > 0 ? (
              <div className="col-12 table-responsive main-table-div">
                <Table
                    className="main-table-all sorter-design-fix border-0"
                    columns={projectColumns}
                    dataSource={projects}
                    size="middle"
                    pagination={false}
                    locale={{
                      emptyText: (
                          <div className="col-12">
                            <div className="row no-data-upload-screens no-data-second m-0 border-0">
                              <div className="col-12 text-center">
                                <img
                                    src={Images.propsal_icon_add}
                                    alt=""
                                    className="img-fluid"
                                />
                                <h6 className="mb-0 text-gray-tag">No Projects</h6>
                              </div>
                            </div>
                          </div>
                      ),
                    }}
                    onRow={(record) => {
                      return {
                        onClick: (event) => {
                          history.push(
                              reverse(routes.dashboard.operations.projects.view, {
                                id: record.id,
                              })
                          );
                        },
                      };
                    }}
                />
              </div>
          ) : !isLoading && projects.length <= 0 ? (
              <div className="col-12">
                <div className="row no-data-upload-screens">
                  <div className="col-12 text-center">
                    <img
                        src={Images.folder_icon_main}
                        alt="cloud upload"
                        className="img-fluid"
                    />
                    <h6 style={{ color: "#BDBDBD" }}>No Projects</h6>
                  </div>
                </div>
              </div>
          ): (
              <div className="col-12">
                <div className="row no-data-upload-screens no-data-second m-0 border-0">
                  <div className="col-12 text-center">
                    <LoadingOutlined style={{ fontSize: 24, color: '#9bd2bb' }} />
                    <h6 className="mb-0 mt-1 text-gray-tag">Loading...</h6>
                  </div>
                </div>
              </div>
          )}

      </React.Fragment>
    );
}

export default ProjectsInfo;
