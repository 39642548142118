import { getGlobalSettingValue } from "../../Controller/api/opportunityServices";
import { setGlobalSetting } from "../../Controller/localStorageHandler";


export const GET_GLOBAL_SETTING = "GET_GLOBAL_SETTING";

export const globalSettingAction = () => {
    return (dispatch) => {
        return getGlobalSettingValue().then((response) => {
            const data = JSON.stringify(response.data);
            setGlobalSetting(data)
            dispatch({
                type: GET_GLOBAL_SETTING,
                payload: response.data,
            });
        });
    };

}