import React, {Component, useEffect, useState} from "react";
import { Button, Form, Input, message, Table } from "antd";
import { Image as Images } from "../../Images";
import { getContactDocument } from "../../../Controller/api/contactsServices";
import {getProposalById, getProposals} from "../../../Controller/api/proposalServices";
import {history} from "../../../Controller/history";
import {reverse} from "named-urls/src";
import {routes} from "../../../Controller/Routes";
import {LoadingOutlined} from "@ant-design/icons";
import moment from "moment/moment";

const ProposalsInfo = (props) => {
  const [proposals,setProposals] = useState([]);
  const [loading,setLoading] = useState(false);
  const [pagination,setPagination] = useState({
    current: 1,
    pageSize: 15,
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
  })

  const [search,setSearch] = useState({
    search: null,
    page: 1
  });

  const columns = [
    {
      title: "Proposal ID",
      dataIndex: "id",
      sorter: true,
    },
    {
      title: "Proposal name",
      dataIndex: "name",
      sorter: true,
    },
    {
      title: "Customer",
      dataIndex: "customer_contact",
      sorter: true,
      render: (customer) =>
          Object.keys(customer).map(function (type, i) {
            return (
                <span key={i}>
                    {customer[type].account && customer[type].account.name}
                  </span>
            );
          }),
      key: "account",
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: true,
      render: data => data?.title || "-"
    },
    {
      title: <div className="position-relative">LAST ACTIVITY DATE</div>,
      dataIndex: "last_activity_date",
      sorter: true,
      render: (data) => <div>{moment(data).format("MMM DD,YYYY hh:mm A")}</div>,
    },
  ];


  useEffect(() => {
    fetchProposal()
  }, [])


  const fetchProposal = (params = {}) => {
    setLoading(true)
    getProposalById({ contact: props.contact.id, ...params })
      .then((res) => {
        setProposals(res.data.results)
        setLoading(false)
      })
      .catch((err) => {
        if (err.response) {
          setLoading(false)
          Object.keys(err.response.data).map((e) => {
            message.error(err.response.data[e]);
          });
        }
      });
  };

  const onSearch = (e) => {
    fetchProposal({ search: e.target.value, page: 1 });
  };


    return (
      <React.Fragment>
        <div
          className={`row mx-0 ${
            !props.hideTitle ? "mt-30 no-data-card-row-new" : ""
          }`}
        >
          <div className="col-12">
            <div className="row new-opportunity-header-row account-tabs-min summary-header-details search-view-header-in-collapse align-items-center  carpet-cleaning-mini-header">
              <div className="search-bar-div">
                <Form className="position-relative">
                  <Input placeholder="Search" onChange={onSearch} />
                  <Button className="search-btn position-absolute p-0 border-0 bg-transparent m-auto">
                    <img
                      src={Images.search_icon_gray}
                      className="img-fluid"
                      alt="search icon"
                    />
                  </Button>
                </Form>
              </div>
              {/* <div className="new-opportunity-btn-div">
                <Button className="new-opportunity-btn text-capitalize">
                  ADD
                </Button>
              </div> */}
              {props.hideTitle && (
                <Button
                  onClick={() => props.tabChange("5")}
                  className="view-all-btn text-uppercase ml-auto"
                >
                  VIEW ALL{" "}
                </Button>
              )}
            </div>
          </div>
          {!loading && proposals.length > 0 ? (
              <div className="col-12 table-responsive main-table-div">
                <Table
                    className="main-table-all sorter-design-fix border-0"
                    columns={columns}
                    dataSource={proposals}
                    size="middle"
                    pagination={false}
                    locale={{
                      emptyText: (
                          <div className="col-12">
                            <div className="row no-data-upload-screens no-data-second m-0 border-0">
                              <div className="col-12 text-center">
                                <img
                                    src={Images.propsal_icon_add}
                                    alt=""
                                    className="img-fluid"
                                />
                                <h6 className="mb-0 text-gray-tag">No proposal</h6>
                              </div>
                            </div>
                          </div>
                      ),
                    }}
                    onRow={(record) => {
                      return {
                        onClick: (event) => {
                          history.push(
                              reverse(routes.dashboard.sales.proposal.view, {
                                id: record.id,
                              })
                          );
                        },
                      };
                    }}
                />
              </div>

          ) : !loading && proposals.length <= 0 ? (
              <div className="col-12">
                <div className="row no-data-upload-screens">
                  <div className="col-12 text-center">
                    <img
                        src={Images.proposal_empty_state_icon}
                        alt="cloud upload"
                        className="img-fluid"
                    />
                    <h6 className="mb-0 mt-1 text-gray-tag">No Proposals</h6>
                  </div>
                </div>
              </div>

          ) : (
              <div className="col-12">
                <div className="row no-data-upload-screens no-data-second m-0 border-0">
                  <div className="col-12 text-center">
                    <LoadingOutlined style={{ fontSize: 24, color: '#9bd2bb' }} />
                    <h6 className="mb-0 mt-1 text-gray-tag">Loading...</h6>
                  </div>
                </div>
              </div>
            )
          }
        </div>
      </React.Fragment>
    );
}

export default ProposalsInfo;
