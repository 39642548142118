import { Component, useEffect, useState } from "react";
import { Button, Form, Input, Modal, TimePicker } from "antd";
import "./style.css";
import { Image as Images } from "../../../Images";
import moment from "moment";

const UpdateTimeModal = (props) => {
  const [time, setTime] = useState(null);
  return (
    <Modal
      title={`Update Requested Shop Arrival Time`}
      className={"miscellaneous"}
      visible={props.visible}
      okText="Save"
      footer={false}
      onCancel={() => props.onClose()}
      wrapClassName="wrap-class"
      destroyOnClose={true}
    >
      <div>
        <div className={"textLabel"}>Requested Shop Arrival Time</div>
        <Form
          onFinish={() => {
            if (time) {
              props.onSave(moment(time).format("HH:mm:ss.SSSSSS"));
              setTime(null);
            }
          }}
        >
          <Form.Item
            name="shop_arrival_time"
            rules={[
              {
                required: true,
                message: "Requested shop arrival time is required",
              },
            ]}
          >
            <TimePicker
              popupClassName="dropdown-class"
              value={time}
              onChange={(e) => {
                setTime(e);
              }}
              className="w-100 rounded picker-class"
            />
          </Form.Item>
          <div className="mt-5 d-flex justify-content-end">
            <Button
              onClick={() => {
                setTime(null);
                props.onClose();
              }}
            >
              Cancel
            </Button>
            <Button type="primary" htmlType="submit" className="ml-3">
              Save
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default UpdateTimeModal;
