import { OWNER_ACCOUNT_DETAIL_SUCCESS } from "../actions/ownerAccountAction";

export interface OwnerAccountState {
  ownerAccountDetail: any;
}
const initialState: OwnerAccountState = {
  ownerAccountDetail: null,
};

export const ownerAccountReducer = (state = initialState, action: any): OwnerAccountState => {
  switch (action.type) {
    case OWNER_ACCOUNT_DETAIL_SUCCESS:
      return  action.payload ;
    default:
      return state;
  }
};

export default ownerAccountReducer;
