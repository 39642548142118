import { USERDATA } from "../actions/userdataAction";


export interface UserDataState {
  userData: null;
}

const initialState: UserDataState = {
  userData: null,
};

export const userdataReducer = (state = initialState, action: any): UserDataState => {
  switch (action.type) {
    case USERDATA:
      return  action.payload;
    default:
      return state;
  }
};

export default userdataReducer;
