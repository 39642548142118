export const getBaseUnitrate = {
  1: 1, // Litre
  2: 0.001, // mili litre
  3: 158.9873, //barrel
  // 4: 4.54609, // gallon (u.s)
  4: 3.785, // gallon (u.s)
  5: 0.946353, // quart (u.s.)
  6: 0.473176, // pint (u.s.)
  7: 0.236588, //cup (u.s.)
  8: 0.118294, // gill (u.s.)
  9: 0.03, //ounce(u.s)
  10: 0.014787, // table spoon (u.s)
  11: 0.004929, // tea spoon (u.s)
  12: 23.3352, // qubic mile
  13: 764.555, // qubic yard
  14: 28.3168, // qubic foot
  15: 0.016387, //qubic inch
  16: 1000, // qubic meter
  17: 0.001, // qubic cm
  18: 1233000, // acre-foot
  19: 36.3687, // bushel- imperial
  20: 3624.56, // cord
  21: 0.23, // cup (canadian)
  22: 0.014, // tbsp (canadian)
  23: 0.004736, // teaspoon (canadian)
  25: 1609.34, // mile
  26: 0.9144, // yard
  27: 0.3048, // foot
  28: 0.0254, // inch
  29: 1000, // km
  30: 1, // meter
  31: 0.01, // cm
  32: 0.001, // milimeter
  33: 5.0292, // rod
  34: 0.001, // gm
  35: 1, // kg
  36: 1000, // mega gram
  37: 0.000001, // mili gram
  38: 0.02835, // ounce
  39: 0.453592, // pound
  40: 1, // pieces
  41: 1000, // metric ton
};
