import React, { useEffect, useState } from "react";
import Chart from 'chart.js/auto'
import { Bar } from 'react-chartjs-2';
import styles from './VerticalBarChartStyles.module.css';






const VerticalBarChart = ({data, graphTitle}) => {

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
                align: 'start',
            },
            title: {
                display: true,
                text: "YTD",
                align: 'start',
            }
        },
    };


    return (
        <div className={styles.main}>
           <div className={styles.graphHeader}>
               <h4>{graphTitle}</h4>
            {/*    dropdown  */}
           </div>
            <div>
            {/*    custom legend here */}
            </div>
            <div className={styles.graph}>
                <Bar options={options} data={data} />
            </div>
        </div>
    )
}

export default VerticalBarChart;