import React, {useState, useEffect} from "react";
import {Button, Form, Input, Popover, Spin} from "antd";
import {Image as Images} from "../Images";
import {Link} from "react-router-dom";
import FilterDatePicker from "../../FilterDatePicker";
import ServiceVarientsFilterDrawer from "../drawers/ServiceVarientsFilterDrawer";
import GenericFilterDrawer from "../drawers/generic-filter/GenericFilterDrawer";
import {LoadingOutlined} from "@ant-design/icons";
import FilterBadge from "../filter-badge/FilterBadge";
import {checkFilterValue} from "../../Controller/utils";

export const ManagementHeader = (props) => {
    const [visible, setVisible] = useState(false);
    const [visibleFilter, setVisibleFilter] = useState(false);
    const [filterApplied, setFilterApplied] = useState(false);
    const [filterObj, setFilterObj] = useState(null);
    const [isClearFilter,setIsCleanFilter] = useState(false)

    const filterDataPop = () => {
        return <FilterDatePicker fetchData={props.fetchData}/>;
    };

    const handleVisibleChange = (visible) => {
        setVisible(visible)
    };
    const filterClose = (visible) => {
        setVisibleFilter(visible)
    };

    const checkShowBtn = () => {
        switch (props.buttonName) {
            case "Create Service":
                return false;
            case "Create Disposal Inventory":
                return false;
            case "Proposal Templates":
                return false;
            default:
                return true;
        }
    };
    const handleFilterChange = (value, valueWithLabel) => {
        handleSetFilterObj(valueWithLabel)
        if (props.onChangeFilters){
            props.onChangeFilters(value)
        }
    }

    const handleSetFilterObj = (filter) => {
        setFilterObj(filter)
        const checkValue = checkFilterValue(filter)
        if (checkValue && checkValue.length > 0) {
            setFilterApplied(true)
            setIsCleanFilter(false)
        } else {
            setFilterApplied(false)
        }
    }


        const {buttonLink, buttonName, createButtonAction, hideDateFilter, FILTER_NEW} = props;
        return (
            <React.Fragment>
                <div className="row mx-0 align-items-center mini-header-filter-list-grid-row">
                <div className="col-12">
                    <div className="row mx-0 new-opportunity-header-row  align-items-center">
                        <div className="d-flex align-items-center">
                            <div className="search-opportunities-div">
                                <div className="search-bar-div">
                                    <Form className="position-relative">
                                        <Input
                                            placeholder="Search"
                                            onChange={props.onSearch}
                                        />
                                        <Button
                                            className="search-btn position-absolute p-0 border-0 bg-transparent m-auto">
                                            <img
                                                src={Images.search_icon_gray}
                                                className="img-fluid"
                                                alt="search icon"
                                            />
                                        </Button>
                                    </Form>
                                </div>
                            </div>
                        </div>
                        <>
                            {
                                // Use the Generic Filters
                                props.filters ?
                                    <div className="new-opportunity-btn-div ml-3">
                                        <Button
                                            onClick={() => filterClose(true)}
                                            className="filter-btn d-flex align-items-center justify-content-center text-capitalize"
                                        >
                                            <img alt={" "} src={Images.filter_icon}/> Filter
                                        </Button>
                                    </div>
                                    :
                                    // Use Old filters
                                    !FILTER_NEW ? !hideDateFilter &&
                                        <div className="new-opportunity-btn-div ml-3">
                                            <Popover
                                                overlayClassName="popover-main-all filter-popover"
                                                content={filterDataPop}
                                                title={false}
                                                trigger="click"
                                                visible={visible}
                                                placement="bottom"
                                                onVisibleChange={handleVisibleChange}
                                            >
                                                <Button
                                                    // onClick={createButtonAction ? createButtonAction : ''}
                                                    className="filter-btn d-flex align-items-center justify-content-center text-capitalize"
                                                >
                                                    <img alt={" "} src={Images.filter_icon}/> Filter
                                                </Button>
                                            </Popover>
                                        </div>
                                        :
                                        <div className="new-opportunity-btn-div ml-3">
                                            <Button
                                                onClick={() => filterClose(true)}
                                                className="filter-btn d-flex align-items-center justify-content-center text-capitalize"
                                            >
                                                <img alt={" "} src={Images.filter_icon}/> Filter
                                            </Button>
                                        </div>
                            }
                        </>
                        {checkShowBtn() && (<div className="new-opportunity-btn-div ml-3">
                                <Link
                                    to={buttonLink}
                                    className="new-opportunity-btn d-flex
                                 align-items-center justify-content-center text-capitalize"
                                >
                                    {/*<img src={Images.white_plus_icon} alt={" "} className="img-fluid mr-1"/>*/}
                                    {buttonName}
                                </Link>
                            </div>)}
                    </div>
                </div>
            </div>
                {
                    props.filters ?
                        <GenericFilterDrawer
                            visible={visibleFilter}
                            onClose={() => filterClose(false)}
                            filters={props.filters}
                            changeFilters={(filters, labels) => handleFilterChange(filters, labels)}
                            isClearFilter={isClearFilter}
                            storageName={props.storageName}
                        />
                        :
                        <ServiceVarientsFilterDrawer
                            visible={visibleFilter}
                            onClose={()=>filterClose(false)}
                            setFilterObj={props.setFilterObj}
                            filterApplied={props.filterApplied}
                        />
                }
                <div className="row mx-0 opportunities-table-main-dashboard">
                    {filterApplied &&
                        <FilterBadge
                            filterObj={filterObj}
                            handleFilterChange={handleFilterChange}
                            filters={props.filters}
                            // breadcrumb={this.state.breadcrumb}
                            setIsCleanFilter={setIsCleanFilter}
                        />
                    }
                </div>
            </React.Fragment>
        );

}

export default ManagementHeader;
