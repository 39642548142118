import React, { Component } from "react";
import { Button, Form, Input, message, Table } from "antd";
import { Image as Images } from "../../Images";
import { getOpportunities } from "../../../Controller/api/opportunityServices";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { getContactOppotunity } from '../../../Controller/api/contactsServices';
import {history} from "../../../Controller/history";
import {reverse} from "named-urls";
import {routes} from "../../../Controller/Routes";
import {LoadingOutlined} from "@ant-design/icons";

class OpportunitiesInfo extends Component {
  state = {
    opportunities: [],
    pagination: {
      current: 1,
      pageSize: 15,
    },
    loading: false,
  };

  opportunitiesColumns = [
    {
      title: "OPPORTUNITY ID",
      dataIndex: "id",
      sorter: true,
    },
    {
      title: "OPPORTUNITY NAME",
      dataIndex: "name",
      sorter: true,
    },
    {
      title: "STATUS",
      dataIndex: "status",
      sorter: true,
      render: data => data?.title || "-"
    },
    {
      title: "OPPORTUNITY SOURCE",
      dataIndex: "source",
      sorter: true,
      render: (data) => <div>{data.name}</div>,
    },
    {
      title: "LAST ACTIVITY DATE",
      dataIndex: "modified",
      render: (data) => <div>{moment(data).format("MMM DD,YYYY hh:mm A")}</div>,
      sorter: true,
    },
  ];

  componentDidMount() {
    this.fetchOpportunity();
  }

  fetchOpportunity = (params = {}) => {
    this.setState({loading: true})
    getOpportunities({ account: this.props.contact.id , ...params })
    .then(res=>{
      this.setState({loading: false})
      this.setState({opportunities:res.data.results})
    })
          // const opportunity = this.props.contact?.contact_opportunity || []
    // this.setState({opportunities:opportunity})
  };
  onSearch = (e) => {
    // this.fetchOpportunity({ search: e.target.value, page: 1 });
  };

  render() {
    let { pagination, opportunities, loading } = this.state;
    if (!this.props.pagination) {
      pagination = false;
    }
    return (
      <React.Fragment>
        <div
          className={`row mx-0 ${
            !this.props.hideTitle ? "mt-30 no-data-card-row-new" : ""
          }`}
        >
          {/* {!this.props.hideSearch && */}
          <div className="col-12">
            <div className="row new-opportunity-header-row account-tabs-min summary-header-details search-view-header-in-collapse align-items-center  carpet-cleaning-mini-header">
              <div className="search-bar-div">
                <Form className="position-relative">
                  <Input placeholder="Search" onChange={this.onSearch} />
                  <Button className="search-btn position-absolute p-0 border-0 bg-transparent m-auto">
                    <img
                      src={Images.search_icon_gray}
                      className="img-fluid"
                      alt="search icon"
                    />
                  </Button>
                </Form>
              </div>
              {this.props?.hideSearch && (
                <Button
                  onClick={() => this.props.tabChange("4")}
                  className="view-all-btn text-uppercase ml-auto"
                >
                  VIEW ALL{" "}
                </Button>
              )}
            </div>
          </div>
          { !loading && opportunities?.length > 0 ? (
            <div className="col-12 mt-2 table-responsive width-160-id main-table-div">
              <Table
                pagination={pagination}
                className="main-table-all border-0 carpet-cleaning-table"
                columns={this.opportunitiesColumns}
                rowKey={(record) => record.id}
                loading={loading}
                dataSource={opportunities}
                size="middle"
                onRow={(record, rowIndex) => {
                  return {
                    onClick: (event) => {
                      history.push(
                          reverse(routes.dashboard.opportunities.view, {
                            id: record.id,
                          })
                      );
                    },
                  };
                }}
                // locale={{emptyText:
                //         (<div style={{textAlign: "center"}}>
                //             <img  src={Images.leads_icon_black} className="img-fluid" alt={"icon"} style={{width:40, opacity:0.5}}/>
                //             <p style={{textAlign:"center", color:"#38BC94"}}> Add Opportunity </p>
                //         </div>
                //         )}}
              />
            </div>
          ) : !loading && opportunities?.length <= 0 ? (
            <div className="col-12">
              <div className="row no-data-upload-screens no-data-second m-0 border-0">
                <div className="col-12 text-center">
                  <img
                    src={Images.no_opportunities_icon}
                    alt="logo"
                    className="img-fluid"
                  />
                  <h6 className="mb-0 text-gray-tag">No Opportunities</h6>
                </div>
              </div>
            </div>
          ) : (
          <div className="col-12">
            <div className="row no-data-upload-screens no-data-second m-0 border-0">
              <div className="col-12 text-center">
                <LoadingOutlined style={{ fontSize: 24, color: '#9bd2bb' }} />
                <h6 className="mb-0 mt-1 text-gray-tag">Loading...</h6>
              </div>
            </div>
          </div>
          )}
        </div>


      </React.Fragment>
    );
  }
}

export default withRouter(OpportunitiesInfo);
