import { VENDOR_ACCOUNT_DETAIL_SUCCESS } from "../actions/vendorAccountAction";

export interface VendorAccountState {
  vendorAccountDetail: any;
}

const initialState: VendorAccountState = {
  vendorAccountDetail: null,
};

export const vendorAccountReducer = (state = initialState, action: any): VendorAccountState => {
  switch (action.type) {
    case VENDOR_ACCOUNT_DETAIL_SUCCESS:
      return  action.payload ;
    default:
      return state;
  }
};

export default vendorAccountReducer;
