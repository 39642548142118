import React, { Component } from "react";
import {Button, Checkbox, Col, Form, Input, Popover, Row, Spin} from "antd";
import { Image as Images } from "../Images";
import { Link } from "react-router-dom";
import { routes } from "../../Controller/Routes";
import GenericFilterDrawer from "../drawers/generic-filter/GenericFilterDrawer";
import {
  FILTER_TYPE_BID_SECTOR,
  FILTER_TYPE_BILLING_ACCOUNT,
  FILTER_TYPE_DATE_RANGE, FILTER_TYPE_NUMBER_RANGE,
  FILTER_TYPE_OPPORTUNITY_SOURCE,
  FILTER_TYPE_OPPORTUNITY_STATUS,
  FILTER_TYPE_PRIMARY_TEAM_MEMBER, FILTER_TYPE_SITE_MANAGER,
  FILTER_TYPE_STATE
} from "../drawers/generic-filter/GenericFilterDrawerTypes";
import {LoadingOutlined} from "@ant-design/icons";
import FilterBadge from "../filter-badge/FilterBadge";
import {checkFilterValue} from "../../Controller/utils";

function onChangeCheckbox(checkedValues) {
  // console.log("checked = ", checkedValues);
}


class OpportunitiesMiniHeader extends Component {
  state = {
    visible: false,
    filters: [
      { type: FILTER_TYPE_OPPORTUNITY_STATUS, label:'Status', name:'status__in' },
      { type: FILTER_TYPE_PRIMARY_TEAM_MEMBER, label: 'Primary Team Member', name:'point_opportunity__in' },
      { type: FILTER_TYPE_OPPORTUNITY_SOURCE, label: 'Opportunity Source', name:'source__in' },
      { type: FILTER_TYPE_BILLING_ACCOUNT, label: 'Billing Account', name:'customer_contact_accounts__account__in' },
      { type: FILTER_TYPE_SITE_MANAGER, label: 'Site Manager Account', name:'owner_contact_accounts__account__in' },
      { type: FILTER_TYPE_NUMBER_RANGE, label: 'Total Bid Value', name:'bid_value' },
      { type: FILTER_TYPE_BID_SECTOR, label: 'Bid Sector', name:'bid_sector__in' },
      { type: FILTER_TYPE_DATE_RANGE, label: 'Bid Date', name:'bid_date', with_time: false},
      { type: FILTER_TYPE_DATE_RANGE, label: 'Last Activity Date', name:'modified' },
    ],
    filterApplied: false,
    filterObj: null,
    isClearFilter: false,
  };
  filterDataPop = [
    <React.Fragment>
      <div className="filter-main-card row mx-0">
        <div className="col-12">
          <div className="row mx-0 type-working-checkbox">
            <div className="col-12">
              <h6 className="d-flex align-items-center justify-content-between">
                Filter Opportunities
              </h6>
            </div>
            <div className="col-12">
              <Checkbox.Group onChange={onChangeCheckbox}>
                <Row>
                  <Col span={24}>
                    <Checkbox value="A">Only my opportunities</Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox value="B">Prospect</Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox value="C">Contacted</Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox value="D">Nurturing</Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox value="E">Negotiating</Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox value="F">Converted</Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox value="G">Closed / Dead</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </div>
          </div>
        </div>
        {/*<div className="col-12 col-sm-6">*/}
        {/*    <div className="row mx-0 date-created-row">*/}
        {/*        <Collapse*/}
        {/*            defaultActiveKey={['1']}*/}
        {/*            onChange={callback}*/}
        {/*            expandIcon={false}*/}
        {/*        >*/}
        {/*            <Panel header="Creation Date" key="1">*/}
        {/*                <RangePicker/>*/}
        {/*            </Panel>*/}
        {/*        </Collapse>*/}
        {/*    </div>*/}
        {/*</div>*/}
      </div>
      <div className="footer-row-main-fix border-0 mt-0 row mx-0">
        {/*<Button>Clear</Button>*/}
        <Button type={"primary"}>Apply Filter</Button>
      </div>
    </React.Fragment>,
  ];

  hide = () => {
    this.setState({
      visible: false,
    });
  };

  handleVisibleChange = (visible) => {
    this.setState({ visible });
  };
  handleFilterChange = (value, label) => {
    this.handleSetFilterObj(label)
    if (this.props.onChangeFilters){
      this.props.onChangeFilters(value)
    }
  }

  handleSetFilterObj = (filter) => {
    this.setState({ filterObj: filter });
    const checkValue = checkFilterValue(filter)
    if (checkValue && checkValue.length > 0) {
      this.setState({ filterApplied: true, isClearFilter: false });
    } else {
      this.setState({ filterApplied: false });
    }
  }

  render() {
    return (
      <>
        <div className="row mx-0 align-items-center mini-header-filter-list-grid-row">
          <div className="col-12">
            <div className="row mx-0 new-opportunity-header-row d-flex align-items-center">
              <div className="d-flex align-items-center">
                {/*<div className="small-heading">15 Opportunities</div>*/}
                <div className="search-opportunities-div">
                  <div className="search-bar-div">
                    <Form className="position-relative">
                      <Input
                        placeholder="Search"
                        onChange={this.props.onSearch}
                      />
                      <Button className="search-btn position-absolute p-0 border-0 bg-transparent m-auto">
                        <img
                          src={Images.search_icon_gray}
                          className="img-fluid"
                          alt="search icon"
                        />
                      </Button>
                    </Form>
                  </div>
                </div>
              </div>
              <div className="new-opportunity-btn-div mx-3">
                <Button
                    className="filter-btn d-flex align-items-center justify-content-center text-capitalize"
                    onClick={() => this.handleVisibleChange(true)}
                >
                  <img alt={"Filter Icon"} src={Images.filter_icon} /> Filter
                </Button>
              </div>
              <div className="new-opportunity-btn-div">
                <Link
                  to={routes.dashboard.opportunities.create}
                  className="new-opportunity-btn d-flex align-items-center justify-content-center text-capitalize"
                >
                  + Create Opportunity
                </Link>
              </div>
            </div>
          </div>
          <GenericFilterDrawer
              visible={this.state.visible}
              onClose={() => this.handleVisibleChange(false)}
              filters={this.state.filters}
              changeFilters={(filters, label) => this.handleFilterChange(filters, label)}
              isClearFilter={this.state.isClearFilter}
              storageName={"opportunity"}
          />
        </div>
        <div className="row mx-0 opportunities-table-main-dashboard">
          {this.state.filterApplied &&
              <FilterBadge
                  filterObj={this.state.filterObj}
                  handleFilterChange={this.handleFilterChange}
                  filters={this.state.filters}
                  // breadcrumb={this.state.breadcrumb}
                  setIsCleanFilter={(value) => this.setState({isClearFilter: value})}
              />
          }
        </div>
      </>
    );
  }
}

export default OpportunitiesMiniHeader;
