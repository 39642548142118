import React, { useEffect, useState } from 'react';
import Chart from 'chart.js/auto';
import { Bar } from "react-chartjs-2";
import OperationDetailCard
    from "../../../analytics/report/operation-report/components/OperationDetailCard/OperationDetailCard";
import styles from "../../../analytics/report/operation-report/OperationReportStyles.module.css";
import {numberWithCommas} from "../../../../Controller/utils";

//ChartJS.register(BarElement, CategoryScale, LinearScale);

const ProjectsAnalyticsView = ({ chartData, actualProfit, isLoading }) => {


    const data = {
        labels: ['Estimated Profit', 'Projected Profit','Actual Profit'],
        datasets: [
            {
                data: [chartData?.estimated_profit, chartData?.projected_profit, chartData?.actual_profit_sum],
                backgroundColor: ['rgba(172, 225, 255, 1)', 'rgba(0, 39, 102, 1)' , 'rgba(106, 175, 255, 1)']
            },
            // TODO temporary comment out till BE fix
            // ...actualProfit
        ],
    };

    const options = {
        indexAxis: 'y',
        maintainAspectRatio: false,
        responsive: true,
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true
            }
        },
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                titleColor: "rgba(79, 79, 79, 1)",
                backgroundColor: "#FFFFFF",
                borderColor: 'rgba(132, 154, 169, 0.3)',
                borderWidth: 0.6,
                bodyColor: "rgba(79, 79, 79, 1)",
                padding: 16,
                bodySpacing: 10,
                titleMarginBottom: 16,
                displayColors: true,
                boxPadding: 5,
                // TODO TODO temporary comment out till BE fix
                // callbacks: {
                //     label: function(context) {
                //         if (context.label === "Actual Profit") {
                //             return `${context.label} : $${context.raw}`
                //         } else {
                //             return context.raw
                //         }
                //     },
                //     title: function(context) {
                //         if (context[0].label === "Actual Profit") {
                //             return `${context[0].dataset.label}`
                //         } else {
                //             return context[0].label
                //         }
                //     }
                // }
            }
        },
    };

    return (
        <React.Fragment>
            {
                (
                    <div className="row summary-collapse-inner-row-main bg-light">
                        <div className={styles.cardSection} style={{ width: '100%'}}>
                            <OperationDetailCard
                                title={"Sales Estimate"}
                                data={[
                                    {
                                        title: "Estimate Revenue",
                                        value: chartData?.estimated_revenue ? `$${numberWithCommas(chartData.estimated_revenue)}` : "-"
                                    },
                                    {
                                        title: "Estimate Expense",
                                        value: chartData?.estimated_expense ? `$${numberWithCommas(chartData.estimated_expense)}` : "-"
                                    },
                                    {
                                        title: "Estimate Profit",
                                        value: chartData?.estimated_profit ? `$${numberWithCommas(chartData.estimated_profit)}` : "-"
                                    },
                                    {
                                        title: "Estimate Profit Margin",
                                        value:  chartData?.estimated_profit_margin ? `${numberWithCommas(chartData?.estimated_profit_margin)}%` : "-"
                                    }
                                ]}
                                isLoading={isLoading}
                            />
                            <OperationDetailCard
                                title={"Operations Projection"}
                                data={[
                                    {
                                        title: "Projected Revenue",
                                        value: chartData?.projected_revenue ? `$${numberWithCommas(chartData?.projected_revenue)}` : "-"
                                    },
                                    {
                                        title: "Projected  Expense",
                                        value: chartData?.projected_expense ? `$${numberWithCommas(chartData?.projected_expense)}` : "-"
                                    },
                                    {
                                        title: "Projected  Profit",
                                        value: chartData?.projected_profit ? `$${numberWithCommas(chartData?.projected_profit)}` : "-"
                                    },
                                    {
                                        title: "Projected  Profit Margin",
                                        value: chartData?.projected_profit_margin ? `${numberWithCommas(chartData?.projected_profit_margin)}%` : "-"
                                    }
                                ]}
                                isLoading={isLoading}
                            />
                            <OperationDetailCard
                                title={"Accounting"}
                                data={[
                                    {
                                        title: "Actual Revenue",
                                        value: chartData?.actual_revenue ? `$${numberWithCommas(chartData?.actual_revenue)}` : "-"
                                    },
                                    {
                                        title: "Actual Expense",
                                        value: chartData?.actual_expense ? `$${numberWithCommas(chartData?.actual_expense)}` : "-"
                                    },
                                    {
                                        title: "Actual Profit",
                                        value:  chartData?.actual_profit_sum ? `$${numberWithCommas(chartData?.actual_profit_sum)}` : "-"
                                    },
                                    {
                                        title: "Actual Profit Margin",
                                        value: chartData?.profit_margin ? `${numberWithCommas(chartData?.profit_margin)}%` : "-"
                                    },
                                    {
                                        title: "Account Receivables",
                                        value: chartData?.accounts_receivable ? `$${numberWithCommas(chartData?.accounts_receivable)}` : "-"
                                    },
                                    {
                                        title: "Paid",
                                        value:  chartData?.paid ? `$${numberWithCommas(chartData?.paid)}` : "-"
                                    },
                                    {
                                        title: "Over Due",
                                        value: chartData?.over_due ? `$${numberWithCommas(chartData?.over_due)}` : "-"
                                    }
                                ]}
                                isLoading={isLoading}
                                showTextColor={true}
                            />
                            {/*<div className="col-3 p-0">*/}
                            {/*    <div className="card shadow-sm">*/}
                            {/*        <div className="card-body">*/}
                            {/*            <h5 className="card-title text-muted">Estimate Revenue</h5>*/}
                            {/*            <h2 className="card-text mt-5">${chartData.estimated_revenue || '0.00'}</h2>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {/*<div className="col-3 p-0">*/}
                            {/*    <div className="card shadow-sm">*/}
                            {/*        <div className="card-body">*/}
                            {/*            <h5 className="card-title text-muted">Estimate Expense</h5>*/}
                            {/*            <h2 className="card-text mt-5">${chartData.estimated_expense || '0.00'}</h2>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {/*<div className="col-3 p-0">*/}
                            {/*    <div className="card shadow-sm">*/}
                            {/*        <div className="card-body">*/}
                            {/*            <h5 className="card-title text-muted">Actual Revenue</h5>*/}
                            {/*            <h2 className="card-text mt-5">${chartData.actual_revenue || '0.00'}</h2>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {/*<div className="col-3 p-0">*/}
                            {/*    <div className="card shadow-sm">*/}
                            {/*        <div className="card-body">*/}
                            {/*            <h5 className="card-title text-muted">Actual Expense</h5>*/}
                            {/*            <h2 className="card-text mt-5">${chartData.actual_expense || '0.00'}</h2>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {/*<div className="col-3 p-0">*/}
                            {/*    <div className="card shadow-sm">*/}
                            {/*        <div className="card-body">*/}
                            {/*            <h5 className="card-title text-muted">Actual Expense</h5>*/}
                            {/*            <h2 className="card-text mt-5">${chartData.actual_expense || '0.00'}</h2>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                        <div className="col-12 p-0 mt-4">
                            <div className="card shadow-sm">
                                <div className="card-header bg-white" style={{ fontSize: '18px' }}>
                                    Profit
                                </div>
                                <div className="card-body">
                                    <Bar options={options} data={data} />
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </React.Fragment>
    );
}

export default ProjectsAnalyticsView;