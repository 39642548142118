import React, { SVGProps } from 'react';


export interface IconBaseProps extends React.SVGAttributes<SVGElement> {
    children?: React.ReactNode;
    size?: string | number;
    color?: string;
}
const ToolIcon = ({ size, color } : IconBaseProps ) => {
    return (
        <svg width={size ?? "25"} height={size ?? "24"} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M19.7857 12C19.7857 16.0238 16.5238 19.2857 12.5 19.2857C11.4188 19.2857 10.3926 19.0502 9.46987 18.6277L11.3091 16.8292L11.6769 16.4614V16.5161C13.2421 16.8918 14.9012 16.43 16.2082 15.444C18.5091 13.7066 17.1238 9.90309 17.1238 9.90309L14.5491 12.4779C14.236 12.7831 13.743 12.7831 13.4378 12.4779L12.3343 11.3744C12.0291 11.0692 12.0291 10.5761 12.3343 10.2709L14.9091 7.69613C13.1717 6.85874 11.0117 7.16396 9.57167 8.61178C8.34297 9.84048 7.93602 11.5857 8.35862 13.1431L6.33167 15.17L6.06606 15.4216C5.5224 14.4014 5.21429 13.2367 5.21429 12C5.21429 7.97621 8.47621 4.71429 12.5 4.71429C16.5238 4.71429 19.7857 7.97621 19.7857 12ZM12.5 21C17.4706 21 21.5 16.9706 21.5 12C21.5 7.02944 17.4706 3 12.5 3C7.52944 3 3.5 7.02944 3.5 12C3.5 16.9706 7.52944 21 12.5 21Z"
                  fill={color ?? "#BDBDBD"}
            />
        </svg>
    )
}

export default ToolIcon;