import React, {Component} from "react";
import {Button, Form, Input, InputNumber} from "antd";
import {Image as Images} from "../../../Images";
import LineItemsTableCustomMain from "../summary-details/LineItemsTableCustomMain";
import {getSubUnitName} from "../../../../Controller/api/disposalServices";
import {handleError} from "../../../../Controller/Global";
import {formatPrice, laborCalculations, supplyCalculation, vehicleCalculations,} from "../../../../Controller/utils";
import {costSettingOptions, paymentOptions,} from "../../../../Controller/proposalServiceVariantDropdown";
import {getServiceVariantProposal} from "../../../../Controller/api/proposalServices";
import { getRegion } from "../../../../Controller/api/vehicleServices";
import RichTextarea from "../../../RichTextarea";

class ViewProposalTemplateLineItems extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allOptions: [],
            newPricing: [],
            regions: []
        };
    }

    componentDidMount() {
        this.getUnitName();
        this.fetchRegion();
        this.getSelectedServiceVariants();
    }

    getSelectedServiceVariants = () => {
        const {proposal} = this.props;
        this.setState({newPricing: proposal.service_variant_template})
    }

    getUnitName = () => {
        this.setState({fetching: true});
        getSubUnitName()
            .then((res) => {
                this.setState({allOptions: res.data});
            })
            .catch((err) => {
                handleError(err);
            })
            .finally(() => {
                this.setState({fetching: false});
            });
    };


    fetchRegion = () => {
        getRegion()
            .then((res) => {
                this.setState({ regions: res.data.results});
            })
            .catch((err) => {
                handleError(err);
            });
    };

    render() {
        const {viewAll, proposal} = this.props;
        const {newPricing} = this.state;

        return (
            <div className={`col-12 ${!this.props.viewAll ? "mt-30" : ""}`}>
                <div
                    className={`row new-opportunity-header-row summary-header-details search-view-header-in-collapse align-items-center justify-content-between carpet-cleaning-mini-header ${
                        !this.props.viewAll ? "border-1 d-none" : ""
                    }`}
                >
                    {viewAll && (
                        <Button
                            onClick={() => this.props.onTabChange("4")}
                            className="view-all-btn text-uppercase"
                        >
                            VIEW ALL{" "}
                        </Button>
                    )}
                </div>
                {newPricing.length > 0 ?
                    <div className="col-12 mt-3 table-responsive main-table-div position-relative wage-table view-proposal-table">
                        <div className="row mx-0 custom-table-main-row custom-table-main-row-proposal-line-item custom-table-main-row-wage-info-main proposal-update-table">
                            <div className="col-12 custom-table-change service-variants-table">
                                <div className="row custom-table-header custom-table-header-2">
                                    <div className="custom-table-cell-th custom-table-cell-th-1">
                                        <div className="custom-th-heading">Type</div>
                                    </div>
                                    <div className="custom-table-cell-th custom-table-cell-th-2">
                                        <div className="custom-th-heading">Name / Info</div>
                                    </div>
                                    <div className="custom-table-cell-th custom-table-cell-th-3">
                                        <div className="custom-th-heading">FACILITY</div>
                                    </div>
                                    <div className="custom-table-cell-th custom-table-cell-th-4">
                                        <div className="custom-th-heading">Qty</div>
                                    </div>
                                    <div className="custom-table-cell-th custom-table-cell-th-5">
                                        <div className="custom-th-heading">Uom</div>
                                    </div>
                                    <div className="custom-table-cell-th custom-table-cell-th-6">
                                        <div className="custom-th-heading">
                                            Price
                                            <br/>
                                            Per unit
                                        </div>
                                    </div>
                                    <div className="custom-table-cell-th custom-table-cell-th-7">
                                        <div className="custom-th-heading">Taxable</div>
                                    </div>
                                    <div className="custom-table-cell-th custom-table-cell-th-8">
                                        <div className="custom-th-heading">
                                            Include
                                            <br/>
                                            In Subtotal
                                        </div>
                                    </div>
                                    <div className="custom-table-cell-th custom-table-cell-th-9">
                                        <div className="custom-th-heading">Total Price</div>
                                    </div>
                                    <div className="custom-table-cell-th custom-table-cell-th-9">
                                        <div className="custom-th-heading">Document</div>
                                    </div>
                                </div>
                                <div className="row">
                                    {newPricing?.map((n) => {
                                        // let foundRegion = regions.find(r => r.id == n.variant?.region);
                                        // console.log(foundRegion)
                                        return (
                                            <LineItemsTableCustomMain
                                                allOptions={this.state.allOptions}
                                                margin={n?.margin}
                                                newPricing={n}
                                                child={
                                                    n?.variant_data?.table_pricing || n?.children || []
                                                }
                                                manually_added={n?.resource_type === "INVENTORY_KIT" ? true : false}
                                                view
                                                removeThreeDots
                                                foundRegion={n.variant_data?.region}
                                            />
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="col-12 mt-3">
                        <div
                            className="row mx-0 border-0 bg-white no-data-card-row align-items-center">
                            <div className="col-12 text-center">
                                <img src={Images.line_items_empty_state_icon} alt={''} className="img-fluid"/>
                                <h6 className="mb-0 mt-1">No Service Variants</h6>
                            </div>
                        </div>
                    </div>
                }
                {/*<div className={`row ${!viewAll ? "custom-row-space" : ""}`}>
                    <div className="col-12 p-0">
                        <div className="row line-items-row-main">
                            <div className="col-12 col-sm-4">
                                <div className="row mb-3">
                                    <div className="col-12">
                                        <span className="sub-total-text">Cost Setting</span>
                                    </div>
                                    <div className="col-12">
                                        <span className="sub-total-text gray-1">
                                          {
                                              costSettingOptions.find(
                                                  (i) => proposal?.cost_setting === i.value
                                              )?.name
                                              || "Standard" }
                                        </span>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-12">
                                        <span className="sub-total-text">Tax Basis</span>
                                    </div>
                                    <div className="col-12">
                                        <span
                                            className="sub-total-text gray-1">{proposal?.tax_basis?.name || "-"}</span>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-12">
                                        <span className="sub-total-text">Payment Terms</span>
                                    </div>
                                    <div className="col-12">
                                <span className="sub-total-text gray-1">
                                {
                                    paymentOptions.find(
                                        (n) => n.value === proposal?.payment_terms
                                    )?.name
                                    || "30 Days" }
                                </span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <span className="sub-total-text">Deposit</span>
                                    </div>
                                    <div className="col-12">
                                        <span className="sub-total-text gray-1">
                                          {proposal?.deposit === "YES" ? "Yes" : proposal?.deposit === "NO" ? "No" : "-"}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-7 offset-sm-1">

                                <div className="row estimated-total-row">
                                    <div className="col-8">
                    <span className="sub-total-text">
                      Estimated Total Price Pre-tax:
                    </span>
                                    </div>
                                    <div className="col-4 text-md-right">
                    <span className="sub-total-text gray-1">
                    {(proposal?.cost_setting == "LUMP_SUM")
                    ||
                    (proposal?.cost_setting == "LUMP_SUM_WITH_UOM_AND_QTY")
                        ?
                        <>${formatPrice(proposal?.estimated_total_price_pre_tax)}</>
                        :
                        <>${formatPrice(proposal?.estimated_total_price_pre)}</>
                    }
                        }</span>
                                    </div>
                                </div>
                                <div className="row estimated-total-row">
                                    <div className="col-7">
                                        <span className="sub-total-text">Estimated Taxes:</span>
                                    </div>
                                    <div className="col-5 text-md-right">
                                        <ul className="list-inline mb-0">
                                            <li className="list-inline-item">
                                                <small>{proposal?.tax_basis?.percentage != 0 ? proposal?.tax_basis?.percentage : "-"}%</small>
                                            </li>
                                            <li className="list-inline-item">|</li>
                                            <li className="list-inline-item">
                        <span className="sub-total-text gray-1">
                        ${formatPrice(proposal?.estimated_taxes) || 0.00}
                        </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div className={`${
                                    proposal?.cost_setting == "TOTAL_PRICE_PER_UNIT" &&
                                    "normal-text"
                                } row estimated-total-row-3 estimated-total-row`}>
                                    <div className="col-7">
                    <span className="sub-total-text-main">
                      Estimated Total:
                    </span>
                                    </div>
                                    <div className="col-5 text-md-right">
                                        {(proposal?.cost_setting == "LUMP_SUM" || proposal?.cost_setting == "LUMP_SUM_WITH_UOM_AND_QTY") &&
                                            <span className="sub-total-text cut-text mr-2">
                            ${proposal?.standard_estimated_total}
                          </span>
                                        }
                                        <span className="sub-total-text-main">
                                            ${formatPrice(proposal?.estimated_total)?.toLocaleString()}
                    </span>
                                    </div>
                                </div>
                                <div className="row estimated-total-row-2 pt-1 pb-0 estimated-total-row">
                                </div>
                                {proposal?.cost_setting === "TOTAL_PRICE_PER_UNIT" && (
                                    <>
                                        <div className="row estimated-total-row-2 pt-1 pb-0 estimated-total-row align-items-center">
                                            <div className="col-5">
                              <span className="sub-total-text">
                                Total Unit:
                              </span>
                                            </div>
                                            <div className="col-7 text-md-right value-div-inner">
                                                <ul className="list-inline mb-0">
                                                    <li className="list-inline-item">
                                                        <Input disabled placeholder={proposal?.proposal_uom ? `${proposal?.proposal_uom?.name} (${proposal?.proposal_uom?.symbol})` : "Select"} />
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <InputNumber
                                                            disabled
                                                            value={proposal?.total_units || 0}
                                                        />
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="row estimated-total-row-2 pt-3 pb-0 estimated-total-row">
                                            <div className="col-5">
                              <span className="sub-total-text-main">
                                Price Per Unit:
                              </span>
                                            </div>
                                            <div className="col-7 text-md-right">
                              <span className="sub-total-text-main">
                                $ {formatPrice(proposal?.price_per_unit)}
                                  {proposal?.proposal_uom &&
                                      ` / ${proposal?.proposal_uom?.symbol} `
                                  }
                              </span>
                                            </div>
                                        </div>
                                    </>
                                )}
                                <div className="row estimated-total-row-4 estimated-total-row">
                                    <div className="col-12">
                                        <small className="small-text-main position-relative small-text-main-2">
                                            Taxes are calculated by customer’s billing address
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>*/}
                <div
                    className={`row bullet-point-row ${
                        !viewAll ? "custom-row-space" : ""
                    }`}
                >
                    <div className="col-12 col-sm-4">
                        <div className="row">
                            <div className="col-12 mb-1">
                                <span className="sub-total-text">Qualifiers</span>
                            </div>
                            {
                                proposal &&
                                <RichTextarea initialValue={proposal.qualifiers}/>
                            }

                        </div>
                    </div>
                    <div className="col-12 col-sm-7 offset-sm-1">
                        <div className="row">
                            <div className="col-12 mb-1">
                                <span className="sub-total-text">Comments</span>
                            </div>
                            {
                                proposal &&
                                <RichTextarea initialValue={proposal.comments}/>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ViewProposalTemplateLineItems;
