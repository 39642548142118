import React, { useState, useEffect } from "react";
import {Button} from "antd";
import {Image as Images} from "../Images";
import {getProposalPricingList} from "../../Controller/api/lineItemsServices";
import {handleError} from "../../Controller/Global";
import {setBreadcrumb} from "../../Store/actions/breadcrumbAction";
import commonConfirmationNew from "../modals/new-modal-update/CommonConfirmationNew";
import {
    FILTER_TYPE_DATE_RANGE,
    FILTER_TYPE_DATE_RANGE_WITH_SELECTOR,
    FILTER_TYPE_NUMBER_RANGE
} from "../drawers/generic-filter/GenericFilterDrawerTypes";

export const FilterBadge = ({ filterObj, handleFilterChange, filters, setIsCleanFilter }) => {

    const [filterLabel, setFilterLabel]  = useState({})


    useEffect(() => {
        buildFilterLabel()
    }, [])


    const buildFilterLabel = () => {
        const filterLabel = {}
        for (const filter of filters) {
            filterLabel[filter.name] = filter.label
        }
        setFilterLabel(filterLabel)
    }


        const computeFilterObject = (filterObj) => {
            const ret = {};
            for (const [key, value] of Object.entries(filterObj)) {

                if (value === undefined || key === "ordering" || value === null) {
                    continue;
                }
                if (key.endsWith('__gte') || key.endsWith('__lte')) {
                    const newKey = key.substring(0, key.length-5)
                    const startNumber = filterObj[`${newKey}__gte`] ? filterObj[`${newKey}__gte`] : '';
                    const endNumber = filterObj[`${newKey}__lte`] ? filterObj[`${newKey}__lte`] : '';

                    if (!(newKey in ret)) {
                        const filterItem = filters?.find(e => e.name === newKey)
                        if (filterItem.type === FILTER_TYPE_DATE_RANGE) {
                            const options = { month: 'short', day: 'numeric' , year: 'numeric'};

                           const startDate = startNumber ? new Date(startNumber).toLocaleDateString('en-us', options) : '';
                           const endDate = endNumber ? new Date(endNumber).toLocaleDateString('en-us', options) : '';

                            ret[newKey] = [startDate, endDate]

                        } else {
                            ret[newKey] = [startNumber, endNumber]
                        }
                    }
                }
                else {
                    ret[key] = value
                }
            }
            return ret;
        }
        const computedFilterObject = computeFilterObject(filterObj)


    const Badge = () => {
        return (
            <ul  className="list-inline mb-0">
                {
                    computedFilterObject && Object.entries(computedFilterObject).map(([filterKey, filterValue]) => {

                        if (filterValue && filterValue.length > 0 ) {
                            const filterItem = filters?.find(e => e.name === filterKey)
                            return (
                                <li className="list-inline-item">
                                    <strong>{filterLabel[filterKey]} : </strong>
                                    {
                                        filterItem.type === FILTER_TYPE_DATE_RANGE ||
                                        filterItem.type === FILTER_TYPE_NUMBER_RANGE ||
                                        filterItem.type === FILTER_TYPE_DATE_RANGE_WITH_SELECTOR ?
                                        <span>{filterValue.join(' - ')}</span> :
                                        <span>{filterValue.length > 0 ? filterValue.join(' , ') : filterValue}</span>
                                    }
                                </li>
                            )
                        }
                    })
                }
            </ul>
        )
    }


        return (
            <>
                <div className="col-12">
                    <div className="row banner-apply-filter-row">
                        <div className="col-12">
                            <ul className="list-inline mb-0">
                                <li className="list-inline-item">
                                    <Button className="applied-filter">✓ Filter Applied</Button>
                                </li>
                                <li className="list-inline-item">
                                    <Button className="clear-btn" onClick={() => {
                                        handleFilterChange({}, null)
                                        setIsCleanFilter(true)
                                    }}>
                                        <img src={Images.close_small} alt={''} className="img-fluid"/>
                                        Clear
                                    </Button>
                                </li>
                            </ul>
                        </div>
                        <div className="col-12">
                            <Badge />
                        </div>
                    </div>
                </div>
            </>
        );

}

export default FilterBadge;