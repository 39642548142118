export const BidSectorOptions = [
    {
      value: 'ROADS_HIGHWAYS',
      name: 'Roads-Highways'
    },
    {
      value: 'AIRPORT',
      name: 'Airport'
    },
    {
      value: 'BRIDGES',
      name: 'Bridges'
    },
    {
      value: 'OTHER',
      name: 'Other'
    },
    {
      value: 'PARKS',
      name: 'Parks'
    },
    {
      value: 'RAIL',
      name: 'Rail'
    },
    {
      value: 'SEWER',
      name: 'Sewer'
    },
    {
      value: 'WATER',
      name: 'Water'
    },
    {
      value: 'WWTP',
      name: 'WWTP'
    },
    {
      value: 'Pipeline / Station Work',
      name: 'Pipeline / Station Work'
    }
  ]