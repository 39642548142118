import React, { useEffect, useState } from "react";
import {Input, Modal, Select} from 'antd';
import './AddMiscellaneousModalStyles.css'
import labor from "../../../management/labor/Labor";


export type AddMiscellaneousModalProps = {
    isOpen: boolean,
    onClose: () => void,
    handleAdd: (arg: string) => void,
    label: string,
    selectedMiscellaneous: {
        label: string,
        value: string,
    }
}

export const AddMiscellaneousModal = ({ isOpen, onClose, handleAdd, label } : AddMiscellaneousModalProps ) => {

    const [inputValue, setInputValue] = useState("");


    const handleChange = (value: string) => {
        setInputValue(value)
    };

    const onSubmit = () => {
        handleAdd(inputValue)
        setInputValue("")
        onClose()
    }


    return (
        <Modal
            title={`Add ${label}`}
            className={'miscellaneous'}
            visible={isOpen}
            onOk={onSubmit}
            okText="Add"
            onCancel={onClose}
        >
                <div className="row info-gray-div align-items-center">
                    <h6 className="mb-0">Please input a name for this item.</h6>
                </div>
                <div>
                    <div className={'textLabel'}>{label} Name</div>
                    <Input
                        placeholder={label === "Discount" ? "Discount Name" : "Miscellaneous Item Name"}
                        value={inputValue}
                        onChange={(e) => handleChange(e.target.value)}
                    />
                </div>
        </Modal>
    )
};

export default AddMiscellaneousModal;

