import React, { useCallback, useMemo, useState, useEffect } from "react";
import { Editable, withReact, Slate } from "slate-react";
import { createEditor } from "slate";
import { withHistory } from "slate-history";
import Toolbar from "./Toolbar/Toolbar";
// import Link from './Elements/Link/Link'
import { LoadingOutlined } from "@ant-design/icons";
import { htmlToSlate, slateToHtml } from "slate-serializers";
import { slateToHtmlConfig } from "../RichTextarea";
import { getAttributeValue } from "domutils";
import Image from "./Elements/Image/Image";
import { insertImage } from "./Elements/Image/utils";

export const htmlToSlateConfig = {
  elementStyleMap: {
    align: "textAlign",
  },
  elementTags: {
    quote: (el) => ({
      type: "blockquote",
    }),
    img: (el) => ({
      type: "image",
      url: el && getAttributeValue(el, "src"),
      children: [{ text: "" }],
    }),
    div: (el) => ({
      type:
        el &&
        getAttributeValue(el, "style") ===
          "display: flex; justify-content: center; list-style-position: inside;"
          ? "alignCenter"
          : el &&
            getAttributeValue(el, "style") ===
              "display: flex; justify-content: flex-end; list-style-position: inside;"
          ? "alignRight"
          : "alignLeft",
    }),
    a: (el) => ({
      type: "link",
      newTab: el && getAttributeValue(el, "target") === "_blank",
      url: el && getAttributeValue(el, "href"),
    }),

    h1: () => ({ type: "headingOne" }),
    h2: () => ({ type: "headingTwo" }),
    h3: () => ({ type: "headingThree" }),
    li: () => ({ type: "list-item" }),
    ol: () => ({ type: "orderedList" }),
    p: () => ({ type: "paragraph" }),
    ul: () => ({ type: "unorderedList" }),
  },
  textTags: {
    code: () => ({ code: true }),
    em: () => ({ italic: true }),
    i: () => ({ italic: true }),
    s: () => ({ strikethrough: true }),
    strong: () => ({ bold: true }),
    u: () => ({ underline: true }),
  },
  htmlPreProcessString: (html) =>
    html.replace(/<pre[^>]*>/g, "<code>").replace(/<\/pre>/g, "</code>"),
  filterWhitespaceNodes: true,
  convertBrToLineBreak: true,
};

const Element = (props) => {
  const { attributes, children, element } = props;
  switch (element.type) {
    case "headingOne":
      return (
        <h1 {...attributes} {...element.attr}>
          {children}
        </h1>
      );
    case "headingTwo":
      return (
        <h2 {...attributes} {...element.attr}>
          {children}
        </h2>
      );
    case "headingThree":
      return (
        <h3 {...attributes} {...element.attr}>
          {children}
        </h3>
      );
    case "alignLeft":
      return (
        <div
          style={{ listStylePosition: "inside" }}
          {...attributes}
          {...element.attr}
        >
          {children}
        </div>
      );
    case "alignCenter":
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            listStylePosition: "inside",
          }}
          {...attributes}
          {...element.attr}
        >
          {children}
        </div>
      );
    case "alignRight":
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            listStylePosition: "inside",
          }}
          {...attributes}
          {...element.attr}
        >
          {children}
        </div>
      );
    case "list-item":
      return (
        <li {...attributes} {...element.attr}>
          {children}
        </li>
      );
    case "orderedList":
      return (
        <ol type="1" {...attributes}>
          {children}
        </ol>
      );
    case "unorderedList":
      return <ul {...attributes}>{children}</ul>;
    case "image":
      return <Image {...props} />;
    // case 'link':
    //     return <Link {...props}/>
    default:
      return (
        <p {...element.attr} {...attributes}>
          {children}
        </p>
      );
  }
};

const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }
  if (leaf.code) {
    children = <code>{children}</code>;
  }
  if (leaf.italic) {
    children = <em>{children}</em>;
  }
  if (leaf.strikethrough) {
    children = (
      <span style={{ textDecoration: "line-through" }}>{children}</span>
    );
  }
  if (leaf.underline) {
    children = <u>{children}</u>;
  }
  return <span {...attributes}>{children}</span>;
};

const TextEditor = (props) => {
  const withLinks = (editor) => {
    const { isInline } = editor;
    editor.isInline = (element) =>
      element.type === "link" ? true : isInline(element);
    return editor;
  };

  const withImages = (editor) => {
    const { insertData, isVoid } = editor;

    editor.isVoid = (element) => {
      return element.type === "image" ? true : isVoid(element);
    };

    editor.insertData = (data) => {
      const text = data.getData("text/plain");
      const { files } = data;

      if (files && files.length > 0) {
        for (const file of files) {
          const reader = new FileReader();
          const [mime] = file.type.split("/");

          if (mime === "image") {
            reader.addEventListener("load", () => {
              const url = reader.result;
              insertImage(editor, url);
            });

            reader.readAsDataURL(file);
          }
        }
      } else {
        insertData(data);
      }
    };

    return editor;
  };

  const withEmbeds = (editor) => {
    const { isVoid } = editor;

    editor.isVoid = (element) =>
      ["video", "image"].includes(element.type) ? true : isVoid(element);
    return editor;
  };
  const withEquation = (editor) => {
    const { isInline } = editor;
    editor.isInline = (element) =>
      element.type === "equation" && element.inline ? true : isInline(element);
    return editor;
  };

  const editor = useMemo(
    () =>
      withImages(
        withEquation(withHistory(withEmbeds(withReact(createEditor()))))
      ),
    []
  );

  const isJsonString = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };

  const handleConvertDataForTextEditor = (value) => {
    // type of value will come as
    // 1:  one long string of array contains HTML tags
    // 2: multi string of array
    // 3: one long slate format string of array
    // 4: long string contains HTML tags
    if (Array.isArray(value)) {
      if (/<\/?[a-z][\s\S]*>/i.test(value[0])) {
        // handle case 1
        return htmlToSlate(value[0], htmlToSlateConfig);
      } else if (isJsonString(value)) {
        // handle case 3
        return JSON.parse(value);
      } else {
        // handle case 2
        const listItem = value?.map((item) => {
          return {
            type: "list-item",
            children: [{ text: item }],
          };
        });
        const convertToObjectArray = [
          {
            type: "unorderedList",
            children: listItem,
          },
        ];
        return convertToObjectArray;
      }
    } else if (/<\/?[a-z][\s\S]*>/i.test(value)) {
      // handle case 4
      return htmlToSlate(value, htmlToSlateConfig);
    } else {
      return [
        {
          type: "paragraph",
          children: [{ text: "" }],
        },
      ];
    }
  };

  const [value, setValue] = useState(
    props.value
      ? handleConvertDataForTextEditor(props.value)
      : [
          {
            type: "paragraph",
            children: [{ text: "" }],
          },
        ]
  );

  const [isLoading, setIsLoading] = useState(true);

  const renderElement = useCallback((props) => <Element {...props} />, []);

  const renderLeaf = useCallback((props) => {
    return <Leaf {...props} />;
  }, []);

  const onChangeValue = (newValue) => {
    const isAstChange = editor.operations.some(
      (op) => "set_selection" !== op.type
    );
    if (isAstChange) {
      const newValueForSave = JSON.stringify(newValue);
      props.onUpdateValue(slateToHtml(newValue, slateToHtmlConfig));
      setValue(newValue);
    }
  };

  useEffect(() => {
    setValue(
      props.value
        ? handleConvertDataForTextEditor(props.value)
        : [
            {
              type: "paragraph",
              children: [{ text: "" }],
            },
          ]
    );

    setIsLoading(false);
  }, [props.value && isLoading]);

  const RichTextEditor = () => {
    return (
      <Slate editor={editor} value={value} onChange={onChangeValue}>
        <Toolbar />
        <div className="editor-wrapper editable-list">
          <Editable
            placeholder="Write something"
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            id={props.id}
          />
        </div>
      </Slate>
    );
  };

  return (
    <div>
      {isLoading ? (
        <div className="col-12">
          <div className="row no-data-upload-screens no-data-second m-0 border-0">
            <div className="col-12 text-center">
              <LoadingOutlined style={{ fontSize: 24, color: "#9bd2bb" }} />
              <h6 className="mb-0 mt-1 text-gray-tag">Loading...</h6>
            </div>
          </div>
        </div>
      ) : (
        <RichTextEditor />
      )}
    </div>
  );
};

export default TextEditor;
