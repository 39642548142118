import React, {useEffect, useState} from "react";
import {Get} from "../../../Controller/headerIntercepter";
import {getAPIUrl} from "../../../Controller/Global";
import {Select} from "antd";
import './GenericFilterField.css';

export function GenericFilterField({ values_api_url, label, onChange, value }) {
    const [options, setOptions] = useState([]);
    const [optionsLoading, setOptionsLoading] = useState(true);
    const [optionsValueLabelMapping, setOptionsValueLabelMapping] = useState([]);

    useEffect(() => {
        const loadData = async () => {
            const res = await Get(getAPIUrl(values_api_url)).finally(
                () => setOptionsLoading(false)
            )
            setOptions(res?.data)
            const valueToLabelMapping = {}
            for ( const option of res?.data) {
                valueToLabelMapping[option.value] = option.label
            }
            setOptionsValueLabelMapping(valueToLabelMapping)
        }
        loadData()
    },[])


    const handleChange = (new_values) => {
        const selectedOptionsArray = [];

        new_values.map((i) => {
            selectedOptionsArray.push(optionsValueLabelMapping[i])
        })

        onChange(new_values, selectedOptionsArray);
    }

    return (
        <>
            <label className={'generic_select__label'}>{label}</label>
            <Select
                mode="multiple"
                className={'generic_select'}
                allowClear
                style={{ width: '100%' }}
                placeholder="Please select"
                value={value}
                defaultValue={[]}
                onChange={handleChange}
                options={options}
                loading={optionsLoading}
                showSearch={true}
                filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input.toLowerCase())}
            />
        </>
    )
}
export default GenericFilterField
