import React, {Component, useEffect, useState} from "react";
import { Button, Form, Input, Table } from "antd";
import { Image as Images } from "../../Images";
import {getWorkOrderById} from "../../../Controller/api/workOrderServices";
import {handleError} from "../../../Controller/Global";
import {history} from "../../../Controller/history";
import {reverse} from "named-urls/src";
import {routes} from "../../../Controller/Routes";
import {LoadingOutlined} from "@ant-design/icons";

export const WorkOrdersInfo = (props) => {

  const [workOrder,setworkOrder] = useState([]);
  const [loading,setLoading] = useState(false);
  const [pagination,setPagination] = useState({
    current: 1,
    pageSize: 15,
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
  })
  const [search,setSearch] = useState({
    search: null,
    page: 1
  });


  const workOrdersColumns = [
    {
      title: "Work order id",
      dataIndex: "id",
      sorter: true,
    },
    {
      title: "project",
      dataIndex: "project",
      sorter: true,
      render: (project) =>
          <span key={0}>
            {project.name}
                  </span>
    },
    {
      title: "Site",
      dataIndex: "work_owner_contact",
      sorter: true,
      key: "site",
      render: (data) => {
        if (data){
          return (
            <div>
              {data.map(i => {
                if (i.site.length) {
                  return (
                    i.site.map(d => {
                        return (
                          <>{`${d.site?.street_address}${d.site?.city}${d.site?.country}${d.site?.state}${d.site?.zip_code}`}</>
                        )
                      }
                    )
                  )
                } else {
                  return "-"
                }
              }
            )}
            </div>
          )
        }else{
          return ''
        }
      }
      ,
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: true,
    },
    // {
    //   title: "Truck(S)",
    //   dataIndex: "truck",
    //   sorter: true,
    // },
    {
      title: <div className="position-relative">Service date</div>,
      dataIndex: "service_date",
      sorter: true,
    },
  ];

  const fetchWorkOrder = (params = {}) => {
    setLoading(true)
    getWorkOrderById({ id: props.contact.id, ...params}, {...props.workorderQuery})
        .then((res) => {
          setworkOrder(res.data.results)
          setLoading(false)
        })
        .catch((err) => {
          setLoading(false)
          handleError(err);
        });
  };


  useEffect(() => {
    fetchWorkOrder()
  }, [])

    return (
      <React.Fragment>
          <div className="col-12">
            <div className="row new-opportunity-header-row account-tabs-min summary-header-details search-view-header-in-collapse align-items-center  carpet-cleaning-mini-header">
              <div className="search-bar-div">
                <Form className="position-relative">
                  <Input placeholder="Search" />
                  <Button className="search-btn position-absolute p-0 border-0 bg-transparent m-auto">
                    <img
                      src={Images.search_icon_gray}
                      className="img-fluid"
                      alt="search icon"
                    />
                  </Button>
                </Form>
              </div>
              {/* <div className="new-opportunity-btn-div">
                <Button className="new-opportunity-btn text-capitalize">
                  Create
                </Button>
              </div> */}
            </div>
          </div>
        { !loading && workOrder.length > 0 ? (
            <div className="col-12 table-responsive main-table-div">
              <Table
                  className="main-table-all sorter-design-fix border-0"
                  columns={workOrdersColumns }
                  dataSource={workOrder}
                  size="middle"
                  pagination={false}
                  locale={{
                    emptyText: (
                        <div className="col-12">
                          <div className="row no-data-upload-screens no-data-second m-0 border-0">
                            <div className="col-12 text-center">
                              <img
                                  src={Images.propsal_icon_add}
                                  alt=""
                                  className="img-fluid"
                              />
                              <h6 className="mb-0 text-gray-tag">No Work Orders</h6>
                            </div>
                          </div>
                        </div>
                    ),
                  }}
                  onRow={(record) => {
                    return {
                      onClick: (event) => {
                        history.push(
                            reverse(routes.dashboard.operations.work_order.view, {
                              id: record.id,
                            })
                        );
                      },
                    };
                  }}
              />
            </div>
        ) : !loading && workOrder.length <= 0 ? (
            <div className="col-12">
              <div className="row no-data-upload-screens">
                <div className="col-12 text-center">
                  <img
                      src={Images.work_setting}
                      alt="cloud upload"
                      className="img-fluid"
                  />
                  {/* <h6 style={{color: "#38BC94"}}>Add Work Order</h6> */}
                  <h6 style={{ color: "#BDBDBD" }}>No Work Orders</h6>
                </div>
              </div>
            </div>
        ) : (
          <div className="col-12">
            <div className="row no-data-upload-screens no-data-second m-0 border-0">
              <div className="col-12 text-center">
                <LoadingOutlined style={{ fontSize: 24, color: '#9bd2bb' }} />
                <h6 className="mb-0 mt-1 text-gray-tag">Loading...</h6>
              </div>
            </div>
          </div>
          ) }
      </React.Fragment>
    );
}

export default WorkOrdersInfo;
