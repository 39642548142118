import { combineReducers, Reducer } from "redux";
import { LOGOUT_SUCCESS } from "../actions/authAction";
import { userLoginReducer, UserLoginState } from "./authReducer";
import { Action, AnyAction } from "@reduxjs/toolkit";
import { opportunityReducer, OpportunityState } from "./opportunityReducer";
import { contactReducer, ContactState } from "./contactReducer";
import {
  customerAccountReducer,
  CustomerAccountState,
  globalSearchReducer,
  GlobalSearchState,
} from "./customerAccountReducer";
import { ownerAccountReducer, OwnerAccountState } from "./ownerAccountReducer";
import { sidebarReducer, SidebarState } from "./sidebarReducer";
import { breadcrumbReducer, BreadcrumbState } from "./breadcrumbReducer";
import { unitItemReducer, UnitItemState } from "./unitItemReducer";
import { userdataReducer, UserDataState } from "./userdataReducer";
import { proposalReducer, ProposalState } from './proposalReducer';
import { projectReducer, ProjectState } from './projectReducer';
import { vendorAccountReducer, VendorAccountState } from "./vendorAccountReducer";
import { globalSettingReducer, GlobalSettingState } from "./globalSettingReducer";
import resetStateReducer from "./resetStateReducer";

export interface RootState {
  user: UserLoginState;
  opportunity: OpportunityState;
  contact: ContactState;
  customer: CustomerAccountState;
  owner: OwnerAccountState;
  sidebar: SidebarState;
  breadcrumb: BreadcrumbState;
  global_search: GlobalSearchState;
  userdata: UserDataState;
  unit_item_data: UnitItemState;
  proposal_data: ProposalState;
  project_data: ProjectState;
  vendor: VendorAccountState;
  global_setting: GlobalSettingState;
}

const allReducers = combineReducers<RootState>({
  user: userLoginReducer,
  opportunity: opportunityReducer,
  contact: contactReducer,
  customer: customerAccountReducer,
  owner: ownerAccountReducer,
  sidebar: sidebarReducer,
  breadcrumb: breadcrumbReducer,
  global_search: globalSearchReducer,
  userdata: userdataReducer,
  unit_item_data: unitItemReducer,
  proposal_data: proposalReducer,
  project_data: projectReducer,
  vendor: vendorAccountReducer,
  global_setting: globalSettingReducer,
});

const rootReducer: Reducer<RootState | undefined> = (state, action) => {
  const nextState = resetStateReducer(state, action);
  return allReducers(nextState, action);
};

export default rootReducer;
