import React, { Component } from "react";
import { Button, Dropdown, Menu, Select, Spin } from "antd";
import { Image as Images } from "../../../Images";
import { Link } from "react-router-dom";
import WorkOrderViewDetailsDrawer from "../drawer/WorkOrderViewDetailsDrawer";
import moment from "moment";
import { handleError } from "../../../../Controller/Global";
import { postDispatchData } from "../../../../Controller/api/dispatchServices";
import { Option } from "antd/lib/mentions";
import { statusLabel } from "../../../../Controller/utils";
import CommonWarningNew from "../../../modals/new-modal-update/CommonWarningNew";
import { reverse } from "named-urls";
import { routes } from "../../../../Controller/Routes";
import { history } from "../../../../Controller/history";
import { LoadingOutlined } from "@ant-design/icons";
import { getWorkOrderById } from "../../../../Controller/api/workOrderServices";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Tooltip } from "antd";

const menu = (
  <Menu>
    <Menu.Item key="0">
      <a href="https://www.antgroup.com">1st menu item</a>
    </Menu.Item>
  </Menu>
);

class DispatchProjectDetailsAll extends Component {
  state = {
    visible: false,
    woInformationRequiredPopup: false,
    selectedWordOrderId: null,
    workOrderMissingInfoList: [],
  };
  showWorkOrderDetails = (visible) => {
    this.setState({
      visible: visible,
    });
  };

  onAddWorkOrderInformation = () => {
    const { selectedWordOrderId } = this.state;
    if (selectedWordOrderId) {
      history.push({
        pathname: reverse(routes.dashboard.operations.work_order.edit, {
          id: selectedWordOrderId,
        }),
        editTab: "4",
      });
    }
  };

  handleClick = (id) => {
    const params = {
      status: "IN_QUEUE",
    };
    postDispatchData(params, id)
      .then((res) => {
        this.props.statusUpdate(true);
      })
      .catch((err) => {
        handleError(err);
      });

    //   5/23 temp comment it out @SOM
    // const { workOrder  } = this.props
    // const selectedWorkOrder  = workOrder.find((i) => i.id === id)
    // const  { contact } = selectedWorkOrder.workorder.work_customer_contact[0]
    //
    // if (contact && contact.length <= 0) {
    //   this.setState({
    //       workOrderMissingInfoList: ['Billing Account Contact Name', 'Contact Phone Number', 'Contact Email'],
    //       woInformationRequiredPopup: true,
    //       selectedWordOrderId: selectedWorkOrder.workorder.id
    //   });
    // } else {
    //   const default_contact = contact.find((i) => i.default_customer_recipient === true);
    //
    //
    //   let warningList = []
    //   if (!default_contact.contact.default_email || !default_contact.contact.default_phone) {
    //     !default_contact.contact.default_phone && warningList.push('Contact Phone Number');
    //     !default_contact.contact.default_email && warningList.push('Contact Email')
    //
    //     this.setState({
    //       workOrderMissingInfoList: warningList,
    //       woInformationRequiredPopup: true,
    //       selectedWordOrderId: selectedWorkOrder.workorder.id
    //     });
    //   } else {
    //     const params = {
    //       status: "IN_QUEUE",
    //     };
    //     postDispatchData(params, id)
    //       .then((res) => {
    //         this.props.statusUpdate(true);
    //       })
    //       .catch((err) => {
    //         handleError(err);
    //       });
    //   }
    // }
  };

  handleStatusChange = (value, id) => {
    const params = {
      status: value,
    };
    postDispatchData(params, id)
      .then((res) => {
        this.props.statusUpdate(true);
      })
      .catch((err) => {
        handleError(err);
      });
  };
  render() {
    const { workOrder } = this.props;

    return (
      <>
        <div className="row dispatch-work-details-row-main">
          {this.props.loading ? (
            <div className="col-12 text-center create-div">
              <div className="loading-spinner">
                <LoadingOutlined style={{ fontSize: 24, color: "#9bd2bb" }} />
              </div>
            </div>
          ) : !this.props.loading && workOrder.length > 0 ? (
            <>
              <div className="col-12">
                {workOrder.map((i) => {
                  let site =
                    i?.workorder?.work_owner_contact?.length &&
                    i?.workorder?.work_owner_contact[0]?.site?.length &&
                    i?.workorder?.work_owner_contact[0]?.site[0]?.site;
                  return (
                    <div className="row mx-0 dispatch-word-card">
                      <div className="col-12 position-relative">
                        <h6>
                          <div className="work-id">{i?.workorder?.id}</div>
                          <div className="project-name">
                            {i?.workorder?.project?.name || "-"}
                          </div>
                        </h6>
                        <Tooltip
                          placement="top"
                          title={"Go to Work Order"}
                          overlayStyle={{ fontSize: 11 }}
                          arrowPointAtCenter={true}
                        >
                          <Button
                            className="ant-dropdown-link ant-dropdown-link-custom"
                            style={{ marginRight: "15px" }}
                            onClick={() =>
                              history.push({
                                pathname: reverse(
                                  routes.dashboard.operations.work_order.view,
                                  { id: i?.workorder?.id }
                                ),
                              })
                            }
                          >
                            <OpenInNewIcon style={{ color: "#828282" }} />
                          </Button>
                        </Tooltip>
                        {/* <Dropdown overlay={menu} trigger={['click']}>
                                    <Button className="ant-dropdown-link ant-dropdown-link-custom"
                                            onClick={e => e.preventDefault()}>
                                        <img src={Images.menu_dispatch_elipsis} alt={' '} className="img-fluid"/>
                                    </Button>
                                </Dropdown> */}
                      </div>
                      <div className="col-12">
                        <ul className="list-inline">
                          {/* Site Manager Account Name*/}
                          {i?.workorder?.work_owner_contact && (
                            <li style={{ color: "#828282" }}>
                              <img
                                src={Images.person_gray_icon}
                                alt={""}
                                width={"14"}
                                className="img-fluid"
                              />
                              <span>
                                {" "}
                                {
                                  i?.workorder?.work_owner_contact[0]?.account
                                    ?.name
                                }
                              </span>
                            </li>
                          )}
                          {/* Billing Account Name*/}
                          {i?.workorder?.work_customer_contact && (
                            <li style={{ color: "#828282" }}>
                              <img
                                src={Images.person_gray_icon_carve}
                                alt={""}
                                width={"14"}
                                className="img-fluid"
                              />
                              <span>
                                {" "}
                                {
                                  i?.workorder?.work_customer_contact[0]
                                    ?.account?.name
                                }
                              </span>
                            </li>
                          )}
                          {/* Date */}
                          <li>
                            <img
                              src={Images.calendar_gary}
                              alt={""}
                              className="img-fluid"
                            />
                            {i?.workorder?.service_date
                              ? moment(i?.workorder?.service_date).format(
                                  "MMM D, YYYY"
                                )
                              : "-"}{" "}
                            |{" "}
                            {i?.workorder?.start_time
                              ? moment(
                                  i?.workorder?.start_time,
                                  "HH:mm"
                                ).format("HH:mm A")
                              : "-"}{" "}
                            -{" "}
                            {i?.workorder?.end_time
                              ? moment(i?.workorder?.end_time, "HH:mm").format(
                                  "HH:mm A"
                                )
                              : ""}
                          </li>
                          <li>
                            <img
                              src={Images.time_activity_add}
                              alt=""
                              className="img-fluid"
                              style={{ width: "15px", height: "15px" }}
                            />
                            Requested Site Arrival Time:{" "}
                            {i?.workorder?.start_time
                              ? moment(
                                  i?.workorder?.start_time,
                                  "HH:mm"
                                ).format("HH:mm A")
                              : "-"}
                          </li>
                          <li>
                            <img
                              src={Images.time_activity_add}
                              alt={""}
                              style={{ width: "15px", height: "15px" }}
                              className="img-fluid"
                            />
                            Requested Shop Arrival Time:{" "}
                            {i?.workorder?.work_owner_contact?.length &&
                            i?.workorder?.work_owner_contact[0]?.site
                              ?.length &&
                            i?.workorder?.work_owner_contact[0]?.work_order
                              ?.shop_arrival_time
                              ? moment(
                                  i?.workorder?.work_owner_contact[0]
                                    ?.work_order?.shop_arrival_time,
                                  "HH:mm"
                                ).format("HH:mm A")
                              : "-"}{" "}
                          </li>
                          <li>
                            <img
                              src={Images.location_gray_dispatch_14}
                              alt=""
                              className="img-fluid"
                            />
                            {site?.apartment || ""} {site?.street_address || ""}{" "}
                            {site?.city || ""} {site?.state}
                          </li>
                        </ul>
                        <div
                          style={{ marginBottom: "25px" }}
                          className="rescheduled-div p-0 shadow-none bg-transprent"
                        >
                          {/* {i.status} */}
                          <Select
                            className="status-small-select"
                            // placeholder={i.status}
                            value={statusLabel(i?.status)}
                            style={{
                              width: "100%",
                              textAlign: "center",
                              fontSize: "13px",
                            }}
                            onChange={(e) => this.handleStatusChange(e, i.id)}
                            suffixIcon={
                              <img
                                src={Images.caret_small_icon_select}
                                alt=""
                                className="img-fluid"
                              />
                            }
                          >
                            <Option value={"IN_QUEUE"}>
                              Scheduled/In Queue
                            </Option>
                            <Option value={"RESCHEDULED"}>
                              Need to be Rescheduled
                            </Option>
                            <Option value={"CANCELED"}>
                              Permanently Canceled
                            </Option>
                          </Select>
                        </div>
                      </div>
                      <Button
                        onClick={() => this.handleClick(i.id)}
                        style={{ color: "#38BC94", backgroundColor: "#FFFFFF" }}
                        className="common-project-btn assign-btn"
                      >
                        Add to calendar
                      </Button>
                    </div>
                  );
                })}
                {workOrder.length !== this.props.totalCount && (
                  <div className="d-flex align-items-center justify-content-center mt-5">
                    <Button
                      className="load-more-btn w-auto bg-transprent"
                      onClick={(e) => {
                        this.props.handlePagination();
                        e.stopPropagation();
                      }}
                    >
                      Load More
                    </Button>
                  </div>
                )}
              </div>
            </>
          ) : (
            <div className="col-12 text-center create-div">
              <div className="loading-spinner">No data available</div>
            </div>
          )}

          {/*<div className="col-12">
                        <div className="row">
                            <div className="col-12">
                                <div className="row mx-0 heading-dispatch-left">
                                    <div className="col-12 col-sm-6">
                                        <h6 className="mb-0">Tue, May 4, 2021</h6>
                                    </div>
                                    <div className="col-12 col-sm-6 text-right">
                                        <h6 className="mb-0 color-gray-3"><span>3</span> Service Request</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div style={{
                                    cursor:'pointer'
                                }} onClick={()=>this.showWorkOrderDetails(true)} className="row mx-0 dispatch-word-card">
                                    <div className="col-12 position-relative">
                                        <h6>
                                            <div className="work-id">302654</div>
                                            <div className="project-name">Lower Manhattan Big Cleaning Phase 1</div>
                                        </h6>
                                        <small>Site Owner Account</small>
                                        <Dropdown overlay={menu} trigger={['click']}>
                                            <Button className="ant-dropdown-link ant-dropdown-link-custom"
                                                    onClick={e => e.preventDefault()}>
                                                <img src={Images.menu_dispatch_elipsis} alt={' '}
                                                     className="img-fluid"/>
                                            </Button>
                                        </Dropdown>
                                    </div>
                                    <div className="col-12 col-sm-8">
                                        <p>
                                            <img src={Images.location_gray_dispatch_14} alt=""
                                                 className="img-fluid"/>
                                            123 W 112TH ST New York</p>
                                    </div>
                                    <div className="col-12 col-sm-4 position-relative">
                                        <Button className="repeats-btn">Repeats
                                            <img src={Images.recurrence} alt={' '} className="img-fluid"/>
                                        </Button>
                                    </div>
                                </div>
                                <div style={{
                                    cursor:'pointer'
                                }} onClick={()=>this.showWorkOrderDetails(true)} className="row mx-0 dispatch-word-card">
                                    <div className="col-12 position-relative">
                                        <h6>
                                            <div className="work-id">302654</div>
                                            <div className="project-name">Lower Manhattan Big Cleaning Phase 1</div>
                                        </h6>
                                        <small>Site Owner Account</small>
                                        <Dropdown overlay={menu} trigger={['click']}>
                                            <Button className="ant-dropdown-link ant-dropdown-link-custom"
                                                    onClick={e => e.preventDefault()}>
                                                <img src={Images.menu_dispatch_elipsis} alt={' '}
                                                     className="img-fluid"/>
                                            </Button>
                                        </Dropdown>
                                    </div>
                                    <div className="col-12 col-sm-8">
                                        <p>
                                            <img src={Images.location_gray_dispatch_14} alt=""
                                                 className="img-fluid"/>
                                            123 W 112TH ST New York</p>
                                    </div>
                                    <div className="col-12 col-sm-4 position-relative">
                                        <Button className="repeats-btn">Repeats
                                            <img src={Images.recurrence} alt={' '} className="img-fluid"/>
                                        </Button>
                                    </div>
                                </div>
                                <div style={{
                                    cursor:'pointer'
                                }} onClick={()=>this.showWorkOrderDetails(true)} className="row mx-0 dispatch-word-card">
                                    <div className="col-12 position-relative">
                                        <h6>
                                            <div className="work-id">302654</div>
                                            <div className="project-name">Lower Manhattan Big Cleaning Phase 1</div>
                                        </h6>
                                        <small>Site Owner Account</small>
                                        <Dropdown overlay={menu} trigger={['click']}>
                                            <Button className="ant-dropdown-link ant-dropdown-link-custom"
                                                    onClick={e => e.preventDefault()}>
                                                <img src={Images.menu_dispatch_elipsis} alt={' '}
                                                     className="img-fluid"/>
                                            </Button>
                                        </Dropdown>
                                    </div>
                                    <div className="col-12 col-sm-8">
                                        <p>
                                            <img src={Images.location_gray_dispatch_14} alt=""
                                                 className="img-fluid"/>
                                            123 W 112TH ST New York</p>
                                    </div>
                                    <div className="col-12 col-sm-4 position-relative">
                                        <Button className="repeats-btn">Repeats
                                            <img src={Images.recurrence} alt={' '} className="img-fluid"/>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="row">
                            <div className="col-12">
                                <div className="row mx-0 heading-dispatch-left">
                                    <div className="col-12 col-sm-6">
                                        <h6 className="mb-0">Tue, May 4, 2021</h6>
                                    </div>
                                    <div className="col-12 col-sm-6 text-right">
                                        <h6 className="mb-0 color-gray-3"><span>3</span> Service Request</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <Link to={''} className="row mx-0 dispatch-word-card">
                                    <div className="col-12 position-relative">
                                        <h6>
                                            <div className="work-id">302654</div>
                                            <div className="project-name">Lower Manhattan Big Cleaning Phase 1</div>
                                        </h6>
                                        <small>Site Owner Account</small>
                                        <Dropdown overlay={menu} trigger={['click']}>
                                            <Button className="ant-dropdown-link ant-dropdown-link-custom"
                                                    onClick={e => e.preventDefault()}>
                                                <img src={Images.menu_dispatch_elipsis} alt={' '}
                                                     className="img-fluid"/>
                                            </Button>
                                        </Dropdown>
                                    </div>
                                    <div className="col-12 col-sm-8">
                                        <p>
                                            <img src={Images.location_gray_dispatch_14} alt=""
                                                 className="img-fluid"/>
                                            123 W 112TH ST New York</p>
                                    </div>
                                    <div className="col-12 col-sm-4 position-relative">
                                        <Button className="repeats-btn">Repeats
                                            <img src={Images.recurrence} alt={' '} className="img-fluid"/>
                                        </Button>
                                    </div>
                                </Link>
                                <Link to={''} className="row mx-0 dispatch-word-card">
                                    <div className="col-12 position-relative">
                                        <h6>
                                            <div className="work-id">302654</div>
                                            <div className="project-name">Lower Manhattan Big Cleaning Phase 1</div>
                                        </h6>
                                        <small>Site Owner Account</small>
                                        <Dropdown overlay={menu} trigger={['click']}>
                                            <Button className="ant-dropdown-link ant-dropdown-link-custom"
                                                    onClick={e => e.preventDefault()}>
                                                <img src={Images.menu_dispatch_elipsis} alt={' '}
                                                     className="img-fluid"/>
                                            </Button>
                                        </Dropdown>
                                    </div>
                                    <div className="col-12 col-sm-8">
                                        <p>
                                            <img src={Images.location_gray_dispatch_14} alt=""
                                                 className="img-fluid"/>
                                            123 W 112TH ST New York</p>
                                    </div>
                                    <div className="col-12 col-sm-4 position-relative">
                                        <Button className="repeats-btn">Repeats
                                            <img src={Images.recurrence} alt={' '} className="img-fluid"/>
                                        </Button>
                                    </div>
                                </Link>
                                <Link to={''} className="row mx-0 dispatch-word-card">
                                    <div className="col-12 position-relative">
                                        <h6>
                                            <div className="work-id">302654</div>
                                            <div className="project-name">Lower Manhattan Big Cleaning Phase 1</div>
                                        </h6>
                                        <small>Site Owner Account</small>
                                        <Dropdown overlay={menu} trigger={['click']}>
                                            <Button className="ant-dropdown-link ant-dropdown-link-custom"
                                                    onClick={e => e.preventDefault()}>
                                                <img src={Images.menu_dispatch_elipsis} alt={' '}
                                                     className="img-fluid"/>
                                            </Button>
                                        </Dropdown>
                                    </div>
                                    <div className="col-12 col-sm-8">
                                        <p>
                                            <img src={Images.location_gray_dispatch_14} alt=""
                                                 className="img-fluid"/>
                                            123 W 112TH ST New York</p>
                                    </div>
                                    <div className="col-12 col-sm-4 position-relative">
                                        <Button className="repeats-btn">Repeats
                                            <img src={Images.recurrence} alt={' '} className="img-fluid"/>
                                        </Button>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="row">
                            <div className="col-12">
                                <div className="row mx-0 heading-dispatch-left">
                                    <div className="col-12">
                                        <h6 className="mb-0 text-green-tag">Local Union 101 <span
                                            className="color-gray-3">(5)</span></h6>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <Link to={''} style={{
                                    minHeight: '72px', borderBottom: '1px solid #e0e0e0'
                                }} className="row mx-0 dispatch-word-card">
                                    <div className="col-12 position-relative">
                                        <div className={'operator-initial'}>
                                            <span className="emp-tag-name text-uppercase">JS</span>
                                        </div>
                                        <div className="operator-details">
                                            <h6 className="mb-0">John Doe</h6>
                                            <p className="m-0">Operator</p>
                                        </div>
                                    </div>
                                </Link>
                                <Link to={''} style={{
                                    minHeight: '72px', borderBottom: '1px solid #e0e0e0'
                                }} className="row mx-0 dispatch-word-card">
                                    <div className="col-12 position-relative">
                                        <div className={'operator-initial'}>
                                            <span className="emp-tag-name text-uppercase">JS</span>
                                        </div>
                                        <div className="operator-details">
                                            <h6 className="mb-0">John Doe</h6>
                                            <p className="m-0">Operator</p>
                                        </div>
                                    </div>
                                </Link>
                                <Link to={''} style={{
                                    minHeight: '72px', borderBottom: '1px solid #e0e0e0'
                                }} className="row mx-0 dispatch-word-card">
                                    <div className="col-12 position-relative">
                                        <div className={'operator-initial'}>
                                            <span className="emp-tag-name text-uppercase">JS</span>
                                        </div>
                                        <div className="operator-details">
                                            <h6 className="mb-0">John Doe</h6>
                                            <p className="m-0">Operator</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                    */}
          <WorkOrderViewDetailsDrawer
            visible={this.state.visible}
            onClose={() => this.showWorkOrderDetails(false)}
          />
          <CommonWarningNew
            visible={this.state.woInformationRequiredPopup}
            onCancel={() => {
              this.setState({ woInformationRequiredPopup: false });
            }}
            onSubmit={this.onAddWorkOrderInformation}
            subTitle={
              <>
                <p>
                  You cannot dispatch this work order because the work order is
                  missing Billing Account Contact Information. Please select
                  “Edit Work Order” and add the following information:
                </p>
                <div className="bullet-left">
                  <ul>
                    {this.state.workOrderMissingInfoList.map((i) => (
                      <li>{i}</li>
                    ))}
                  </ul>
                </div>
              </>
            }
            okText={"Edit Work Order"}
            cancelText={"Cancel"}
            title={"This work order is missing information."}
          />
        </div>
      </>
    );
  }
}

export default DispatchProjectDetailsAll;
