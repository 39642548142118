import React, {useState, useEffect} from "react";
import AccountingHeader from "../AccountingHeader";
import InvoicingTableMain from "./InvoicingTableMain";
import {routes} from "../../../Controller/Routes";
import {debounceEvent} from "../../../Controller/utils";
import {
    FILTER_TYPE_BILLING_ACCOUNT,
    FILTER_TYPE_DATE_RANGE,
    FILTER_TYPE_DISPOSAL, FILTER_TYPE_INVOICE_STATUS,
    FILTER_TYPE_NUMBER_RANGE,
    FILTER_TYPE_PROJECT_SOURCE
} from "../../drawers/generic-filter/GenericFilterDrawerTypes";
import ManagementHeader from "../../management/ManagementHeader";
import {getInvoices} from "../../../Controller/api/invoiceServices";
import {handleError} from "../../../Controller/Global";
import {Image as Images} from "../../Images";
import {connect} from "react-redux";
import {setBreadcrumb} from "../../../Store/actions/breadcrumbAction";

const InvoicingMain = (props) => {

    const [search, setSearch] = useState()
    const [invoice, setInVice] = useState([])
    const [loading, setIsLoading] = useState(false)
    const [filters, setFilters] = useState({})
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 15,
        showSizeChanger: false,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
    },)

    useEffect(() => {
        let arr = [
            {
                title: "Invoice",
                // url: routes.dashboard.sales.proposal.self,
            },
        ];
        props.setBreadcrumb(arr);
        const filterValueFromStorage = sessionStorage.getItem(`invoice-filters`);
        let params = {
            ordering: "-id",
        };
        fetchAllInvoice({...params}, JSON.parse(filterValueFromStorage));
    }, [search])


    const fetchAllInvoice = (params = {}, localFilters=null) => {
        setIsLoading(true)
        const _filters = localFilters || filters;

        getInvoices({ search, ...params, ..._filters, 'minimal': 'True'})
            .then(res => {
            setInVice(res.data.results)
                setPagination(
                  {...pagination,
                        current: params.page || 1,
                        total: res.data.count
                })
            setIsLoading(false)

        }).catch((err) => {
            handleError(err)
            setIsLoading(false)
        })

    }

    const onSearch = (e) => {
        setSearch(e.target.value)
    }

    const handleFilterInvoice = (data) => {
    }

    const handleInvoiceFilterChange = (value) => {
        setFilters(value)
        setTimeout(() => {
            fetchAllInvoice(value);
        }, 100)

    }

    const handleTableChange = (pagination, filters, sorter) => {
        let symbol = "";
        if (sorter.order === "descend") symbol = "-";
        let params = {
            page: pagination.current,
        };
        if (sorter.columnKey) {
            params.ordering = `${symbol}${sorter.columnKey}`;
        } else {
            params.ordering = "-id";
        }

        fetchAllInvoice(params);
    }



    const getLocaleData = (invoice) => {
        return(
            <div className="col-12 no-data-card-row-new-table">
                <div className="row no-data-upload-screens no-data-second m-0 border-0">
                    <div className="col-12 text-center">
                        <img src={Images.teams_labor_no_data_icon} alt="" className="img-fluid"/>
                        <h6 className="mb-0 no-data-main-tg">{'No Invoice'}</h6>
                    </div>
                </div>
            </div>
        )
    }



    return (
      <div className="main-content-div">
        {/*<AccountingHeader buttonLink={routes.dashboard.accounting.invoicing.create} buttonName="+ Create Invoice"/>*/}
          <ManagementHeader
              buttonLink={routes.dashboard.accounting.invoicing.create}
              buttonName={"+ Create Invoice"}
              onSearch={debounceEvent(onSearch, 1000)}
              fetchData={(data) => handleFilterInvoice(data)}
              onChangeFilters={(filters) => handleInvoiceFilterChange(filters)}
              filters={[
                  { type: FILTER_TYPE_PROJECT_SOURCE, label: 'Associated Project', name:'project_id__in' },
                  { type: FILTER_TYPE_BILLING_ACCOUNT, label: 'Billing Account', name:'invoice_customer_contact__account__id__in' },
                  { type: FILTER_TYPE_INVOICE_STATUS, label: 'Status', name:'status__id__in' },
                  { type: FILTER_TYPE_DATE_RANGE, label: 'Last Activity Date', name:'modified' },
                  { type: FILTER_TYPE_DATE_RANGE, label: 'Creation Date', name:'created' },
                  { type: FILTER_TYPE_DATE_RANGE, label: 'Due Date', name:'due_date', with_time: false },
              ]}
              storageName={"invoice"}
          />
        <div className="row mx-0 opportunities-table-main-dashboard width-160-id">
          <InvoicingTableMain
              data={invoice}
              pagination={pagination}
              loading={loading}
              locale={!loading ? {emptyText: getLocaleData(true)}: {emptyText:" "}}
              fetchTableData={fetchAllInvoice}
              handleTableChange={handleTableChange}
          />
        </div>
      </div>
    );

}

// export default InvoicingMain;
export default connect(null, { setBreadcrumb })(InvoicingMain);
