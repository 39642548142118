import { GET_GLOBAL_SETTING } from "../actions/globalSettingAction";


export interface GlobalSettingState {
  globalSettingData: any;
}

const initialState: GlobalSettingState = {
  globalSettingData: null,
};

export const globalSettingReducer = (state = initialState, action: any): GlobalSettingState => {
  switch (action.type) {
    case GET_GLOBAL_SETTING:
      return action.payload;
    default:
      return state;
  }
};

export default globalSettingReducer;
