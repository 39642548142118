import { PayloadAction } from '@reduxjs/toolkit'
import { UNIT_ITEM } from "../actions/unitItemAction";

export interface UnitItemState {
    unitItem: {
        created: string,
        id: number,
        modified: string,
        name: string,
        symbol:string,
        unit_type: {
            id: number,
            created: string,
            modified: string,
            name: string
        },
        value: string
    }
}

const initialState: UnitItemState | null = {
    unitItem: {
        created: '',
        id: 0,
        modified: '',
        name: '',
        symbol:'',
        unit_type: {
            id: 0,
            created: '',
            modified: '',
            name: ''
        },
        value: ''
    },
};

export const unitItemReducer = (state = initialState, action: PayloadAction<UnitItemState>): UnitItemState => {
    switch (action.type) {
        case UNIT_ITEM:
            return action.payload;
        default:
            return state;
    }
};

export default unitItemReducer;
