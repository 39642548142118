import React, {useEffect, useState} from "react";
import {Button, Drawer, Form, Input} from "antd";
import {history} from "../../../Controller/history";
import {reverse} from "named-urls/dist/index.es";
import {routes} from "../../../Controller/Routes";
import {makeProposalTemplate} from "../../../Controller/api/proposalServices";
import {handleError} from "../../../Controller/Global";

export function CreateProposalTemplate({ visible, onClose, props }) {

    const [templateName, setTemplateName] = useState('')

    const clearValues = () => {

    }

    const handleCreateTemplate = () => {

        const data = {
            proposal_id: props.proposal.id,
            name: templateName,
            qualifiers: props.proposal.qualifiers,
            comments: props.proposal.comments
        }
        makeProposalTemplate(data)
            .then((res) => {
                if (res.status === 201) {
                    history.push({
                        pathname: reverse(routes.dashboard.sales.proposal.template, {
                            id: res.data.id
                        }),
                        editTab:"1"
                    })
                }

            })
            .catch((err) => {
                console.log(err)
                handleError(err);
            });
        onClose();
    }

    return (
        <Drawer
            title="Create Proposal Template"
            visible={visible}
            closable={true}
            onClose={onClose}
            onCancel={clearValues}
            className="main-all-form-modal main-drawer-div drawer-update"
            width={"400px"}
            placement={"right"}
            footer={
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <Button
                        className="apply-filter-update"
                        onClick={() => setTemplateName('')}
                        style={{marginRight: 8}}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="apply-filter-update"
                        onClick={() => handleCreateTemplate()}
                        type="primary"
                    >
                        Create
                    </Button>
                </div>
            }
        >
            <div className="row">
                <div className="col-12">
                    <div className="row info-gray-div align-items-center">
                        <h6 className="mb-0">
                            Please input a name for the template you are going to create.
                        </h6>
                    </div>
                </div>
                <div className="col-12">
                    <Form
                        hideRequiredMark={true}
                        className="main-inner-form"
                        layout="vertical"
                    >
                        <Form.Item
                            name="name"
                            label={"Proposal Template Name *"}
                            rules={[
                                {
                                    required: true,
                                    message: "this field is required",
                                },
                            ]}
                            className="position-relative"
                        >
                            <Input value={templateName}
                                   onChange={(e) => setTemplateName(e.target.value)}
                                   placeholder={"Template Name"}
                            />
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </Drawer>
    )
}

export default CreateProposalTemplate;