import React, { useEffect, useState } from "react";
import { Pie} from 'react-chartjs-2';
import styles from './SalesPieCardStyles.module.css'



const SalesPieCard = ({key, data, title }) => {
    return (
        <div className={styles.card}>
            <div className={`${styles.flexRow} ${styles.header}`}>
                <div className={styles.title}>Total Proposals</div>
                <h2>112</h2>
            </div>
            <div>
                <div className={styles.pie}>
                    <Pie
                        width="200px"
                        height={"200px"}
                        data={data}
                         options={{ maintainAspectRatio: false }}
                    />
                </div>
            </div>

        </div>
    )
}

export default SalesPieCard;