import React from "react";
import styles from "./OperationDetailCard.module.css";
import "../../../../CustomStyles.css";
import { Card, Divider } from "antd";
import {LoadingOutlined} from "@ant-design/icons";




const OperationDetailCard = ({title,  data , showTextColor, isLoading }) => {



    return (
        <Card title={title} className={`${styles.card} operation-card`}>
            <div>
                {isLoading ? (
                        <div className="text-center create-div m-5">
                            <div className="loading-spinner">
                                <LoadingOutlined style={{ fontSize: 24, color: '#9bd2bb' }} />
                            </div>
                        </div>
                    ) : (
                    data?.map((i, index) => {
                        const dataLength = data.length;
                        const color = showTextColor && index === dataLength - 1 ? styles.textColor : "";
                        const secondaryDivider = index === 3 ? styles.secondDivider : "";

                        return (
                            <div>
                                <div  className={styles.flexContainer}>
                                    <div className={styles.title}>{i.title}</div>
                                    <div className={`${styles.number} ${color}`}>{i.value}</div>
                                </div>
                                {index === dataLength - 1 ? ("") : (
                                    <Divider className={`${styles.divider} ${secondaryDivider}`}/>
                                )}
                            </div>
                        )
                    })
                )}

            </div>
        </Card>
    )
};

export default OperationDetailCard;