import React, { useState, useEffect } from 'react'
import { slateToHtml } from 'slate-serializers'
import { Element } from 'domhandler'
import HTMLReactParser from "html-react-parser";



const ELEMENT_NAME_TAG_MAP = {
    p: 'p',
    paragraph: 'p',
    headingOne: 'h1',
    headingTwo: 'h2',
    headingThree: 'h3',
    unorderedList: 'ul',
    orderedList: 'ol',
    'list-item': 'li',
    blockquote: 'blockquote',
    image: 'img',
}

const MARK_ELEMENT_TAG_MAP = {
    strikethrough: ['s'],
    bold: ['strong'],
    underline: ['u'],
    italic: ['i'],
    code: ['pre', 'code'],
}

export const slateToHtmlConfig = {
    markMap: MARK_ELEMENT_TAG_MAP,
    elementMap: ELEMENT_NAME_TAG_MAP,
    elementTransforms: {
        quote: ({children = []}) => {
            const p = [new Element('p', {}, children)]
            return new Element('blockquote', {}, p)
        },
        alignCenter: ({ node, children = [] }) => {
                const attrs = {}
                return new Element(
                    'div',
                    {
                        style: "display: flex; justify-content: center; list-style-position: inside;"
                    },
                    children,
                )
            },
        alignLeft: ({ node, children = [] }) => {
            const attrs = {}
            return new Element(
                'div',
                {
                    style: "list-style-position:inside;"
                },
                children,
            )
        },
        alignRight: ({ node, children = [] }) => {
            const attrs = {}
            return new Element(
                'div',
                {
                    style: "display: flex; justify-content: flex-end; list-style-position: inside;"
                },
                children,
            )
        },
        link: ({ node, children = [] }) => {
            const attrs = {}
            if (node.target === '_blank') {
                attrs.target = '_blank'
            }
            return new Element(
                'a',
                {
                    href: node.href,
                    ...attrs,
                },
                children,
            )
        },
        image: ({ node, children = [] }) => {
            const attrs = {}
           return new Element(
                'img',
                {
                    style:"max-height:500px;object-fit:fill; page-break-before: auto;",
                    src: node.url,
                    ...attrs,
                },
                children,
            )
        },
    },
    encodeEntities: true,
    alwaysEncodeCodeEntities: false,
    convertLineBreakToBr: false,

}

const isJsonString = (str) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

const RichTextarea = (props) => {

    const { initialValue } = props;
    const [formatToHtml, setFormatToHtml] = useState(null)


    useEffect(() => {
        if ( initialValue ) {
            // type of initialValue will come as
            // 1: string contains HTML tags
            // 2: string value that separate value by ^
            // 3: string of slate format


            if (/<\/?[a-z][\s\S]*>/i.test(initialValue)) {
                // handle case 1
                setFormatToHtml(initialValue)
            } else if ( isJsonString(initialValue)) {

                // handle case 3
                const value = JSON.parse(initialValue);
                setFormatToHtml(slateToHtml( value, slateToHtmlConfig ))

            } else {
                // handle case 2
                const listItem = initialValue.split('^')?.map(item => {
                    return {
                        type: 'list-item',
                        children:[{text: item }],
                    }
                })
                const convertToObjectArray = [
                    {
                        type:"unorderedList",
                        children: listItem
                    }
                ]
                setFormatToHtml(slateToHtml( convertToObjectArray, slateToHtmlConfig ))
            }

        }

    },[])
    return (
        <div className="col-12" style={{ wordBreak: 'break-all' }}>
            {initialValue && formatToHtml ? HTMLReactParser(formatToHtml) : "-"}
        </div>
    )
}




export default RichTextarea