import React, { useState } from "react";
import {connect} from "react-redux";
import {setBreadcrumb} from "../../../../Store/actions/breadcrumbAction";
import styles from "./SalesReportStyles.module.css"
import VerticalBarChart from "../components/VerticalBarChart/VerticalBarChart";
import SalesPieCard from "./components/SalePieCard/SalesPieCard";
import DataTable from "../components/DataTable/DataTable";
import ToolBar from "../components/ToolBar/ToolBar";
import ReportDonutChart from "../components/ReportDonutChart/ReportDonutChart";
import CustomDonutLegend from "../components/ReportDonutChart/CustomDonutLegend/CustomDonutLegend";
import TotalOpportunitiesCard from "./components/TotalOpportunitiesCard/TotalOpportunitiesCard";
import SalesProgressCard from "./components/SaleProgressBarCard/SaleProgressBarCard";



export const dummyDataForPieChart = {
    datasets: [
        {
            data: [42, 19,],
            backgroundColor: [
                'rgba(64, 169, 255, 1)',
                'rgba(0, 39, 102, 1)',
            ],
            borderWidth: 1,
        },
    ],
};

const dummyLabelsForGraph = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const dummyDataForGraph = {
    labels: dummyLabelsForGraph.map((i) => i.toUpperCase()),
    datasets: [
        {
            label: '2022',
            data: dummyLabelsForGraph.map(() => Math.floor(Math.random() * 600)),
            backgroundColor: 'rgba(172, 225, 255, 1)',
        },
        {
            label: '2023',
            data: dummyLabelsForGraph.map(() => Math.floor(Math.random() * 600)),
            backgroundColor: 'rgba(106, 175, 255, 1)',
        },
    ]

};



const SalesReportMain = ({setBreadcrumb, activeKey}) => {
    const [data, setDate] = useState()

    const donutLabel = ['NY & NJ Port Authority', 'NYC Housing Authority ', 'The City of New Haven',
        'C&S Property Management', 'Eastern Development Inc.', 'Others'];

    const donutBGForOpportunitySources = [
        'rgba(242, 242, 242, 1)',
        'rgba(51, 145, 255, 1)',
        'rgba(124, 203, 248, 1)',
        'rgba(191, 232, 255, 1)',
        'rgba(89, 114, 121, 1)',
        'rgba(177, 190, 191, 1)',
    ];
    const donutValue = [12, 19, 3, 5, 2, 3];

    const dummyDataForDonut = {
        // labels: donutLabel,
        datasets: [
            {
                data: donutValue,
                backgroundColor: donutBGForOpportunitySources,

                borderWidth: 0,
            },
        ],
    }

    const donutLegend = (donutBackgroundColorValue, donutValue, key) => {
        return (
            {
                key: key,
                label:
                    <CustomDonutLegend
                        donutBackgroundColorValue={donutBackgroundColorValue[key]}
                        donutValue={donutValue[key]}
                        donutLabel={donutLabel[key]}
                    />,
            }
        )
    }


    const columns = [
        {
            title: 'Proposal Name',
            dataIndex: 'name'
        },
        {
            title: 'Site Manager Account',
            dataIndex: 'site'
        },
        {
            title: 'Estimated Profit',
            dataIndex: 'estimated'
        },
    ]


    const dummyDataForTable = [1,2,3,4,5,6,7,8,9,10,11,12,13].map((i) => {
        return {
            key: i,
            name: 'Proposal',
            site: 'Site Manager Account',
            estimated: 0.00,
        }
    })

    const dummyDataForProgressBar = [
        {
            title: "Needs Analysis",
            value: 20,
        },
        {
            title: "Proposal Creation",
            value: 40,
        },
        {
            title: "Waiting on Approval",
            value: 20,
        },
        {
            title: "Proposal Presentation",
            value: 80,
        },
        {
            title: "Waiting on Bid Opening",
            value: 80,
        },
        {
            title: "Waiting on Formal Award",
            value: 80,
        },
        {
            title: "Negotiation",
            value: 80,
        },
        {
            title: "Buy Out Stage",
            value: 80,
        },
    ];

    const dummyDataForSmallProgressBar = [
        {
            title: "Created",
            value: 20,
        },
        {
            title: "Sent",
            value: 40,
        },
        {
            title: "Waiting on response",
            value: 80,
        },
        {
            title: "Closed",
            value: 20,
        },
        {
            title: "Moved to a project",
            value: 80,
        },
        {
            title: "Rejected",
            value: 80,
        },

    ];

    return (
        <div className={styles.main}>
            <ToolBar/>
            <div>
                <div className={styles.pieChartSection}>
                    <div className={styles.mainDetailCard}>
                        <TotalOpportunitiesCard progressBarData={dummyDataForProgressBar}/>
                    </div>
                    <div className={`${styles.mainDetailCard} ${styles.flexRow}`}>
                        <div className={styles.mainDetailCard}>
                            <SalesProgressCard progressBarData={dummyDataForSmallProgressBar} />
                        </div>
                        <div className={styles.mainDetailCard}>
                            <SalesPieCard key={3} title={"Total Proposals that created as Project"} data={dummyDataForPieChart}/>
                        </div>

                    </div>

                </div>
            </div>
            <div>
                <VerticalBarChart data={dummyDataForGraph} graphTitle={'Opportunity Created'}/>
            </div>
            <div className={styles.doubleCardRow}>
                <ReportDonutChart
                    title={"Opportunity Sources"}
                    data={dummyDataForDonut}
                    donutLegendDetail={
                        donutBGForOpportunitySources.map((i, index) =>
                            donutLegend(donutBGForOpportunitySources, donutValue, index)
                        )}
                    hasDataTable={false}
                    donutSubLabel={"July 1-15 2022"}
                    donutLabel={"$4M"}
                />
                <DataTable title={"Proposal Details"} columns={columns} data={dummyDataForTable}/>
            </div>
        </div>
    )
}

export default connect(null, {setBreadcrumb})(SalesReportMain);