import React, { useEffect, useState } from "react";
import {connect} from "react-redux";
import { Progress, Table } from "antd";
import {setBreadcrumb} from "../../../../Store/actions/breadcrumbAction";
import {routes} from "../../../../Controller/Routes";
import styles from "./ResourceUtilizationReportStyles.module.css"
import DataTable from "../components/DataTable/DataTable";
import ReportDonutChart from "../components/ReportDonutChart/ReportDonutChart";
import CustomDonutLegend from "../components/ReportDonutChart/CustomDonutLegend/CustomDonutLegend";
import { Image as Images } from "../../../Images";
import ToolBar from "../components/ToolBar/ToolBar";


const ResourceUtilizationReportMain = (props) => {
  const donutLabel = ['Working', 'Scheduled ', 'Not on Schedule', 'Unavailable']
  const donutBGForLaborChart = [
      'rgba(187, 107, 217, 1)',
      'rgba(219, 201, 226, 1)',
      'rgba(174, 155, 191, 1)',
      'rgba(223, 151, 250, 1)',
  ];
  const donutBGForFleetChart = [
      'rgba(56, 188, 148, 1)',
      'rgba(127, 212, 186, 1)',
      'rgba(177, 190, 191, 1)',
      'rgba(89, 114, 121, 1)',
  ];

  const donutValueForLaborChart = [12, 19, 3, 5];
  const donutValueForFleetChart = [14, 31, 8, 8];

    const dummyDonutLegendDetailData = [1,2,3,4,5,6,7,8,9,10].map((i) => {
        return {
            key: i,
            vehicle: "mason Dump 5000",
            vehicle_category: "Dump Truck"
        }
    })

    const dummyDataForTable = [1,2,3,4,5,6,7,8,9,10,11,12,13].map((i) => {
        return {
            key: i,
            assignee: 'Vehicle',
            type: 'Type',
            hours: 20,
            work_assigned: 20
        }
    })

    const columnsDonutLegendDetail = [
        {
            title: 'Vehicle',
            dataIndex: 'vehicle',
            key: 'vehicle',
        },
        {
            title: 'Vehicle Category',
            dataIndex: 'vehicle_category',
            key: 'vehicle_category',
        },

    ]



    const handleSetDonutChartValue = (donutBackgroundColorValue, data ) => {
      return (
          {
              datasets: [
                  {
                      data: data,
                      backgroundColor: donutBackgroundColorValue,
                      borderWidth: 0,
                  },
              ],
          }
      )
    }


    const getColumnsForTable = (strokeColor) => {
      return (
          [
              {
                  title: 'Assignee',
                  dataIndex: 'assignee',
                  key: 'assignee',
              },
              {
                  title: 'Type',
                  dataIndex: 'type',
                  key: 'type',
              },
              {
                  title: 'Hours',
                  dataIndex: 'hours',
                  key:  'hours',
              },
              {
                  title: 'Work Assigned',
                  dataIndex: 'work_assigned',
                  key: 'work_assigned',
                  render: (data) => (
                      <>
                          <Progress
                              type="line"
                              strokeColor={
                                  strokeColor
                              }
                              strokeLinecap="square" percent={data} />
                      </>
                  )
          },
          ]
      )
    }


    const donutLegend = (donutBackgroundColorValue, donutValue, key) => {
        return (
            {
                key: key,
                label: (
                    <CustomDonutLegend
                        donutBackgroundColorValue={donutBackgroundColorValue[key]}
                        donutValue={donutValue[key]}
                        donutLabel={donutLabel[key]}
                    />
                ),
                children: (
                    <Table
                        columns={columnsDonutLegendDetail}
                        dataSource={dummyDonutLegendDetailData}
                        pagination={false}
                    />
                ),

            }

        )
    }


    const sortOption = [
        {
            value: 'YearToDate',
            label: 'Year to Date',
        },
    ]

    const handleSortChange = () => {

    }


    return (
        <div className={styles.main}>
            <ToolBar/>
            <div className={styles.doubleCardRow}>
                <ReportDonutChart
                    title={"Labor"}
                    data={handleSetDonutChartValue(donutBGForLaborChart, donutValueForLaborChart)}
                    donutLegendDetail={donutBGForFleetChart.map((i, index) =>
                        donutLegend(donutBGForLaborChart, donutValueForLaborChart, index)
                    )}
                    hasDataTable={true}
                    donutLabel={"110"}
                    donutSubLabel={"Today 9:30 AM"}
                    icon={Images.labor_gray_icon}

                />
                <ReportDonutChart
                    title={"Fleet"}
                    data={handleSetDonutChartValue(donutBGForFleetChart, donutValueForFleetChart)}
                    donutLegendDetail={
                        donutBGForLaborChart.map((i, index) =>
                            donutLegend(donutBGForFleetChart, donutValueForFleetChart, index)
                    )}
                    hasDataTable={true}
                    donutLabel={"80"}
                    donutSubLabel={"Today 9:30 AM"}
                    icon={Images.truck_empty}
                />
            </div>
            <div className={styles.doubleCardRow}>
                <DataTable
                    title={"Employee Performance"}
                    columns={getColumnsForTable('rgba(223, 151, 250, 1)')}
                    data={dummyDataForTable}
                    isSortAble={true}
                    sortOptions={sortOption}
                    handleSortChange={handleSortChange}
                />
                <DataTable
                    title={"Vehicle Performance"}
                    columns={getColumnsForTable('rgba(56, 188, 148, 1)')}
                    data={dummyDataForTable}
                    isSortAble={true}
                    sortOptions={sortOption}
                    handleSortChange={handleSortChange}
                />
            </div>
        </div>

    )
}

export default connect(null, {setBreadcrumb})(ResourceUtilizationReportMain);