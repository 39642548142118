import { useRef } from "react";
import { Editor } from "slate";
import { useSlateStatic } from "slate-react";
import Icon from "../../Common/Icon";

export const insertImage = (editor, url) => {
  const text = { text: "" };
  const image = { type: "image", url, children: [text] };
  Editor.insertNode(editor, image);
};

export const InsertImageButton = ({ format }) => {
  const editor = useSlateStatic();
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      const url = reader.result;
      insertImage(editor, url);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };
  const ref = useRef(null);
  return (
    <div>
      <input
        type="file"
        accept="image/*"
        onChange={handleFileUpload}
        style={{ display: "none" }}
        id="image-upload-input"
        ref={ref}
      />
      {/* <label htmlFor="image-upload-input"> */}
      <div
        style={{
          cursor: "pointer",
          color:'black',
          opacity:'0.5'
        }}
        onClick={(event) => {
          event.preventDefault();
          ref.current.click();
        }}
      >
        <Icon icon={format} />
      </div>
      {/* </label> */}
    </div>
  );
};
