import { routes } from "../../Controller/Routes";
import { BREADCRUMB_DATA } from "../actions/breadcrumbAction";

export interface BreadcrumbItem {
  title: string;
  url: string;
}

export type BreadcrumbState = BreadcrumbItem[];

const initialState: BreadcrumbState = [
  {
    title: "Dashboard",
    url: routes.dashboard.self,
  },
];
export const breadcrumbReducer = (state = initialState, action: any): BreadcrumbItem[] => {
  switch (action.type) {
    case BREADCRUMB_DATA:
      return action.payload;
    default:
      return state;
  }
};

export default breadcrumbReducer;
