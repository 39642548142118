import React, { Component } from "react";
import { Tabs } from "antd";
import ProposalActivityView from "./summary-details/ProposalActivityView";
import ViewProposalCustomerAccount from "./components/ViewProposalCustomerAccount";
import {getProposalTemplate} from "../../../Controller/api/proposalServices";
import { routes } from "../../../Controller/Routes";
import { handleError } from "../../../Controller/Global";
import { setBreadcrumb } from "../../../Store/actions/breadcrumbAction";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ViewProposalPost from "./components/ViewProposalPost";
import ViewProposalDocuments from "./components/ViewProposalDocuments";
import { checkProposalFieldsRequired } from "../../../Controller/utils";
import ProposalTemplateSummary from "./ProposalTemplateSummary";

const { TabPane } = Tabs;

class ProposalViewTemplateMain extends Component {
    state = {
        proposal: null,
        sites: [],
        active: "1",
        proposalContacts: [],
        proposal_filled: true
    };

    componentDidMount() {
        this.fetchProposalTemplate();
    }

    fetchProposalTemplate = () => {
        getProposalTemplate(this.props.match.params.id)
            .then((res) => {
                let arr = [
                    {
                        title: "Proposals",
                        url: routes.dashboard.sales.proposal.self,
                    },
                    {
                        title: res.data.name,
                        url: "",
                    },
                ];
                this.props.setBreadcrumb(arr);
                this.setState({ proposal: res.data, proposal_filled: checkProposalFieldsRequired(res.data) })
                // , () => {
                //     // this.getServiceVariantProposal();
                // });
            })
            .catch((err) => {
                console.log(err)
                handleError(err);
            });
    };
    onTabChange = (key) => {
        this.setState({ active: key });
    };

    render() {
        const { proposal, sites, proposal_filled } = this.state;
        return (
            <React.Fragment>
                <div className="main-content-div">
                    <div className="row mx-0 carpet-cleaning-main-row position-relative">
                        <Tabs
                            className="carpet-cleaning-main-common-tab"
                            onChange={this.onTabChange}
                            activeKey={this.state.active}
                        >
                            <TabPane tab="Summary" key="1">
                                <ProposalTemplateSummary
                                    proposal_filled={proposal_filled}
                                    onTabChange={this.onTabChange}
                                    onChange={(key) => this.onChange(key)}
                                    sites={sites}
                                    proposal={proposal}
                                    fetchProposal={this.fetchProposalTemplate}
                                />
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const actionCreators = {
    setBreadcrumb,
};
export default connect(null, actionCreators)(withRouter(ProposalViewTemplateMain));
