import React, { useContext, useEffect, useRef, useState } from "react";
import { Form, Input, message, Table } from 'antd';
import { AlignType } from "rc-table/lib/interface";
import type { FormInstance } from 'antd/es/form';
import { updateDispatchDisposal } from "../../../../Controller/api/dispatchServices";
import  "./DisposalInformationDataTable.css";
import { handleError } from "../../../../Controller/Global";

const EditableContext = React.createContext<FormInstance<any> | null>(null);

export type DisposalInformationDataProps = {
    id?: number,
    name: string,
    created: number,
    ticket: string,
    loaded_weight: number,
    unloaded_weight: number,
    net_weight: number,
    net_uom?: number
}

export type DisposalInformationDataTableProps = {
    data?: DisposalInformationDataProps[],
    editable?: boolean,
    onUpdate?: (data: DisposalInformationDataProps) => void,
    onDelete?: (id: number | undefined) => void,
    isLoading: boolean
}

interface EditableRowProps {
    index: number;
}
  
const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

interface EditableCellProps {
    title?: React.ReactNode;
    editable?: boolean;
    children?: React.ReactNode;
    dataIndex?: any;
    record: DisposalInformationDataProps;
    handleSave: (record: DisposalInformationDataProps) => void;
}

const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
}: EditableCellProps) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef<any>(null);
    const form = useContext(EditableContext);

    useEffect(() => {
        if (editing) {
            inputRef.current.focus();
        }
    }, [editing]);

    const toggleEdit = (data: any) => {
        setEditing(!editing);
        form?.setFieldsValue({ [dataIndex]: data });
    };

    const save = async () => {
        try {
            if (inputRef.current) {
                toggleEdit(inputRef.current.state.value);
                // @ts-ignore
                record[dataIndex] = await form?.getFieldValue(dataIndex)
                if (record) handleSave(record);
            }
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };

    let childNode = children;

    if (editable) {
        childNode = editing ? (
        <Form.Item
            style={{ margin: 0 }}
            rules={[
            {
                required: true,
                message: `${title} is required.`,
            },
            ]}
        >
            <Input ref={inputRef} onPressEnter={save} onBlur={save} />
        </Form.Item>
        ) : (
        <div className="editable-cell-value-wrap" style={{ paddingRight: 24 }} onClick={toggleEdit}>
            {children}
        </div>
        );
    }

    return <td {...restProps}>{childNode}</td>;
};  

export const DisposalInformationDataTable = ( { data, editable, onUpdate, isLoading, onDelete } : DisposalInformationDataTableProps ) => {
    const alignCenter: AlignType = "center";
    const [disposalInformationData, setDisposalInformationData] = useState(data);

    const components = {
        body: {
          row: EditableRow,
          cell: EditableCell,
        },
    };

    const defaultColumns = [
        {
            title: 'Disposal Name & Dispatch',
            dataIndex: 'name',
            key: 'name',
            align: alignCenter,
            width: '400',
            editable: editable
        },
        {
            title: 'Disposal Time',
            dataIndex: 'created',
            key: 'created',
            align: alignCenter,
            width: '400',
            editable: false
        },
        {
            title: 'Ticket #',
            dataIndex: 'ticket',
            key: 'ticket',
            align: alignCenter,
            width: '400',
            editable: editable
        },
        {
            title: 'Loaded Weight',
            dataIndex: 'loaded_weight',
            key: 'loaded_weight',
            align: alignCenter,
            width: '400',
            editable: editable
        },
        {
            title: 'Unloaded Weight',
            dataIndex: 'unloaded_weight',
            key: 'unloaded_weight',
            align: alignCenter,
            width: '400',
            editable: editable
        },
        {
            title: 'Net Load',
            dataIndex: 'net_weight',
            key: 'net_weight',
            className: "tableTotalPrice",
            align: alignCenter,
            width: '400',
            editable: editable
        },
    ];

    const handleSave = (row: any) => {
        // @ts-ignore
        const newData = [...disposalInformationData];
        // @ts-ignore
        const index = Object.values(data).findIndex((position: any) => position.id === row.id)
        const item = newData[index];
        delete item['net_uom'];
        index && newData.splice(index, 1, {
        ...item,
        ...row,
        });
        setDisposalInformationData(newData);
        if (item) {
            updateDispatchDisposal(item.id, item).then((response) => {
                if (response.status === 200) message.success("Updated!");
            }).catch((err) => {
                handleError(err);
            });
        }
    };

    const columns = defaultColumns.map(col => {
        if (!col.editable) {
          return col;
        }
        return {
          ...col,
          onCell: (record: any) => ({
            record,
            editable: col.editable,
            dataIndex: col.dataIndex,
            title: col.title,
            handleSave,
          }),
        };
      });

    return (
        <div style={{ zIndex: 999 }} onClick={(e) => e.stopPropagation()}>
            <div className={"mb-4 miscellaneous-table"}>
                <Table
                    columns={columns}
                    components={components}
                    rowClassName={() => 'editable-row'}
                    // @ts-ignore
                    dataSource={[...disposalInformationData]}
                    pagination={false}
                    loading={isLoading}
                />
            </div>
        </div>
    )
};

export default DisposalInformationDataTable;
