import React, { useEffect, useState } from "react";
import {Table, Select, Space } from 'antd';
import styles from './DataTableStyles.module.css'
import "../../../CustomStyles.css"


const DataTable = ({data, title , columns, isSortAble, handleSortChange, sortOptions }) => {

    return (
        <div className={styles.main}>
            <div className={`${styles.tableHeader} data-table`}>
                <h4>{title}</h4>
                { isSortAble ? (
                    <div>
                        <Select
                            defaultValue="YearToDate"
                            style={{
                                width: 120,
                            }}
                            onChange={handleSortChange}
                            options={sortOptions}
                        />
                    </div>
                ) : null}
            </div>
            <div>
                <Table
                    columns={columns}
                    dataSource={data}
                    pagination={{
                        pageSize: 10,
                        showSizeChanger: false,
                    }}
                />
            </div>
        </div>
    )
}

export default DataTable;