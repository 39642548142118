import React, { useEffect, useState } from "react";
import {connect} from "react-redux";
import { Tabs } from "antd";
import SummaryReportMain from "./summary-report/SummaryReportMain";
import SalesReportMain from "./sales-report/SalesReportMain";
import OperationReportMain from "./operation-report/OperationReportMain";
import ResourceUtilizationReportMain from "./resource-utilization/ResourceUtilizationReportMain";
import "../CustomStyles.css"
import {setBreadcrumb} from "../../../Store/actions/breadcrumbAction";
import {routes} from "../../../Controller/Routes";

const { TabPane } = Tabs;
const Report = (props) => {
    const [activeKey, setActiveKey] = useState("3")


    const handleChange = (activeKey) => {
        let arr = [
            {
                title: "Report",
                url: routes.dashboard.analytics.self,
            },
        ];
        switch (activeKey) {
            case "1":
                arr.push(
                    {
                        title: "Summary",
                        url: routes.dashboard.analytics.reports.self,
                    },
                )
                props.setBreadcrumb(arr);
                return;
            case "2":
                arr.push(
                    {
                        title: "Resource & Utilization",
                        url: routes.dashboard.analytics.reports.self,
                    },
                )
                props.setBreadcrumb(arr);
                return;
            default:
                arr.push(
                    {
                        title: "Summary",
                        url: routes.dashboard.analytics.reports.self,
                    },
                )
                props.setBreadcrumb(arr);
                return;
        }
    }

    useEffect(() => {
        handleChange()
    },[])


    return (
        <>
            <div className="main-content-div">
                <Tabs
                    className="carpet-cleaning-main-common-tab analytics"
                    defaultActiveKey={activeKey}
                    onChange={handleChange}
                >
                    <TabPane tab="Summary" key="1" >
                        <OperationReportMain />
                    </TabPane>
                    <TabPane tab="Resource & Utilization" key="2" disabled>
                        <SalesReportMain />
                    </TabPane>
                    {/*<TabPane tab="Operation" key="3">*/}
                    {/*<SummaryReportMain/>*/}
                    {/*    <OperationReportMain />*/}
                    {/*</TabPane>*/}
                    {/*<TabPane tab="Operation-Resource & Utilization" key="4" disabled >*/}
                    {/*    <ResourceUtilizationReportMain />*/}
                    {/*</TabPane>*/}
                </Tabs>
            </div>
        </>
    )
}

// export default Report;
export default connect(null, {setBreadcrumb})(Report);