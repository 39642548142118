import { PROJECT_DETAIL_SUCCESS } from '../actions/projectAction';

export interface ProjectState {
  projectDetail: any;
}

const initialState: ProjectState = {
  projectDetail: null,
};

export const projectReducer = (state = initialState, action: any): ProjectState => {
  switch (action.type) {
    case PROJECT_DETAIL_SUCCESS:
      return action.payload
    default:
      return state;
  }
};

export default projectReducer;
