import React, { useEffect, useState } from "react";
import {Button, Dropdown, Menu, message, Modal, Select} from 'antd';
import AddMiscellaneousModal from "../AddMiscellaneousModal/AddMiscellaneousModal";
import MiscellaneousDataTable from "../MiscellaneousDataTable/MiscellaneousDataTable";
import "./MiscellaneousSectionStyles.css"
import { createInvoiceMiscellaneous, updateInvoiceMiscellaneous, deleteInvoiceMiscellaneous } from "../../../../Controller/api/invoiceServices";
import {handleError} from "../../../../Controller/Global";
import { MiscellaneousDataProps } from "../MiscellaneousDataTable/MiscellaneousDataTable";

export type MiscellaneousSectionProps = {
    invoiceId: number,
    data: MiscellaneousDataProps[],
    fetchInvoice: () => void,
    isFetchInvoiceLoading: boolean
};

export const MiscellaneousSection = ({ invoiceId, data, fetchInvoice, isFetchInvoiceLoading } : MiscellaneousSectionProps) => {

    const discount = data?.filter((i) => i.miscellaneous?.miscellaneous_type === "DISCOUNT") || [];
    const miscellaneous = data?.filter((i) => i.miscellaneous?.miscellaneous_type === "MISCELLANEOUS_SURCHARGE") || [];
    const [selectedMiscellaneous, setSelectedMiscellaneous] = useState({ label: "", value: "" });
    const [isAddModalVisible, setIsAddModalVisible] = useState(false);
    const [miscellaneousData , setMiscellaneousData] = useState<MiscellaneousDataProps[]>(miscellaneous);
    const [discountData, setDiscountData] = useState<MiscellaneousDataProps[]>(discount);
    const [isLoading, setIsLoading] = useState(false)

    const addMiscellaneousMenu = [
        {
            label: "Miscellaneous/Surcharge",
            value: "MISCELLANEOUS_SURCHARGE"
        },
        {
            label: "Discount",
            value: "DISCOUNT"
        },

    ];

    const handleSelectMiscellaneous = (value : { label: string, value: string }) => {
        setSelectedMiscellaneous(value);
        setIsAddModalVisible(true)
    }

    const handleAdd = (value : string) => {
        const newData = {
            name: value,
            qty: '0',
            cost_type: "AMOUNT",
            uom: null,
            price_per_unit: '0',
            total_price: 0,
            miscellaneous_type: selectedMiscellaneous.value,
        };
        onCreateMiscellaneous(newData).then(r => {});
    }

    const onCreateMiscellaneous = async (value: {
        name: string,
        qty: string,
        uom: number | null,
        cost_type: string,
        price_per_unit: string,
        total_price: number,
        miscellaneous_type: string,
    }) => {
        setIsLoading(true)
        try {
            const res = await createInvoiceMiscellaneous(
                {
                    miscellaneous: value,
                    invoice: invoiceId
                }
            )
            message.success("Miscellaneous create successfully");
            setIsLoading(false);
            fetchInvoice();
        } catch (err) {
            handleError(err);
            setIsLoading(false);
        }
    }

    const onUpdateMiscellaneous = async (id: number, value : MiscellaneousDataProps) => {
        setIsLoading(true);
        try {
            const res = await updateInvoiceMiscellaneous( id, value);
            setIsLoading(false);
            fetchInvoice();
        } catch (err) {
            handleError(err);
            setIsLoading(false);
        }
    };

    const onDeleteMiscellaneous = async (id: number | undefined) => {
        setIsLoading(true);
        if (id) {
            try {
                const res = await deleteInvoiceMiscellaneous(id);
                setIsLoading(false);
                fetchInvoice();
            } catch (err) {
                handleError(err);
                setIsLoading(false);
            }
        }
    };

    useEffect(() => {

        const discount = data?.filter((i) => i.miscellaneous?.miscellaneous_type === "DISCOUNT") || [];
        const miscellaneous = data?.filter((i) => i.miscellaneous?.miscellaneous_type === "MISCELLANEOUS_SURCHARGE") || [];

        setMiscellaneousData(miscellaneous);
        setDiscountData(discount);

    }, [data])

    return (
        <>
            <div className={"mb-4"}>
                <div className={"create-div mb-4"}>
                    <Dropdown
                        arrow
                        overlay={
                            <Menu>
                                {addMiscellaneousMenu.map((i, index) => {
                                    return(
                                        <Menu.Item
                                            onClick={() => handleSelectMiscellaneous(i)}
                                        >
                                            <Button className="bg-transparent border-0 shadow-none p-0 dropdown" key={index}>
                                                {i.label}
                                            </Button>
                                        </Menu.Item>
                                    )
                                })}
                            </Menu>
                        }
                    >
                        <Button>+ Add Miscellaneous</Button>
                    </Dropdown>
                </div>
                <div className={"mb-4"}>
                    <MiscellaneousDataTable
                        data={miscellaneousData}
                        tableType={'Miscellaneous/Surcharge'}
                        editable={true}
                        onUpdate={onUpdateMiscellaneous}
                        onDelete={onDeleteMiscellaneous}
                        isLoading={isLoading && isFetchInvoiceLoading}
                    />
                </div>
                <div className={"mb-4"}>
                    <MiscellaneousDataTable
                        data={discountData}
                        tableType={'Discount'}
                        editable={true}
                        onUpdate={onUpdateMiscellaneous}
                        onDelete={onDeleteMiscellaneous}
                        isLoading={isLoading && isFetchInvoiceLoading}
                    />
                </div>
            </div>
            <AddMiscellaneousModal
                isOpen={isAddModalVisible}
                onClose={() => setIsAddModalVisible(false)}
                handleAdd={(value) => handleAdd(value)}
                selectedMiscellaneous={selectedMiscellaneous}
                label={selectedMiscellaneous.label}
            />
        </>
    )
};

export default MiscellaneousSection;