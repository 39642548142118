import React, { Component } from "react";


class Bullet extends Component {
    ulRef = React.createRef();
    state = {
        firstLoad: false
    }

    componentDidMount() {
        if (this.props.value) {
            if (this.props.value.length === 0) {
                this.ulRef.current.innerHTML = "<li></li>";
            } else {
                let list = this.ulRef.current;
                let li = "";
                this.props.value.forEach((item) => {
                    li = li + "<li>" + item + "</li>";
                });
                list.innerHTML = li;
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let selectedElem = document.getElementById(this.props.id)
        if (!this.props.value && selectedElem?.childNodes?.length === 0) {
            this.ulRef.current.innerHTML = "<li></li>";
        }
        if(this.props.value ) {

            if (this.props.value.length === 0) {
                this.ulRef.current.innerHTML = "<li></li>";
            } else if (this.state.firstLoad === false ) {
                let list = this.ulRef.current;
                let li = "";
                this.props.value.forEach((item) => {
                    li = li + "<li>" + item + "</li>";
                });
                list.innerHTML = li;
                this.setState({ firstLoad: true})
            }
        }
    }

    handleListChange = (e) => {
        let selectedElem = document.getElementById(this.props.id);
        if(selectedElem?.childNodes?.length === 0) {
            selectedElem.innerHTML = "<li></li>";
        }
        // this.props.onChange(arr);
    };

    render() {
        return (
            <>
                <div className="editable-list">
                    <ul
                        id={this.props.id}
                        ref={this.ulRef}
                        onInput={this.handleListChange}
                        contentEditable="true"
                    >
                        <li />
                    </ul>
                </div>
            </>
        );
    }
}


export default Bullet;
