import { PROPOSAL_DETAIL_SUCCESS } from "../actions/proposalAction";

export interface ProposalState {
  proposalDetail: any;
}

const initialState: ProposalState = {
  proposalDetail: null,
};

export const proposalReducer = (state = initialState, action: any): ProposalState => {
  switch (action.type) {
    case PROPOSAL_DETAIL_SUCCESS:
      return  action.payload ;
    default:
      return state;
  }
};

export default proposalReducer;
