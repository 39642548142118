import { OPPORTUNITY_DETAIL_SUCCESS } from "../actions/opportunityAction";

export interface OpportunityState {
  opportunityDetail: any;
}

const initialState: OpportunityState = {
  opportunityDetail: null,
};


export const opportunityReducer = (state = initialState, action: any): OpportunityState => {
  switch (action.type) {
    case OPPORTUNITY_DETAIL_SUCCESS: {
      return  action.payload 
    }
    default: {
      return state;
    }
  }
};

export default opportunityReducer;
