import React from "react";
import {InputNumber} from "antd";

export function GenericNumberRangeFilterField({ label, onChange, valueGTE, valueLTE }) {
    const handleChange = (new_values, ) => {
        onChange(new_values);
    }

    return (
        <>
            <label className={'generic_select__label col-12 pl-0'}>{label}</label>

            <InputNumber onChange={value => handleChange([value, valueLTE])} className={'col-5'} value={valueGTE}/>
            <span className={'col-1'}>-</span>
            <InputNumber onChange={value => handleChange([valueGTE, value])} className={'col-5'} value={valueLTE}/>
        </>
    )
}
export default GenericNumberRangeFilterField
