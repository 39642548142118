import React , { useState, useEffect } from "react";
import {DatePicker, Select} from "antd";
import moment from "moment";
import './GenericFilterField.css';


export function GenericDateFilterFieldWithSelector(
    {
        label,
        onChange,
        valueGTE,
        valueLTE,
        isCustomDateRange,
        setIsCustomDateRange,
    }
    ) {
    const [ selectedDate, setSelectedDate] = useState();
   const [ errorMessage, setErrorMessage] = useState(true)



    const handleChange = (new_values, ) => {
        onChange(new_values);

        if (new_values[0] && new_values[1]) {
            setErrorMessage(false)
        }

    }


    const handleSelectChange = (value) => {
        setSelectedDate(value)

        if (value === "custom_range") {
            setIsCustomDateRange(true)
        } else {
            setIsCustomDateRange(false)

            const today = new Date();

            if (value === 'last_365_days') {
                const lastYearFromNow = new Date(today);
                lastYearFromNow.setDate(today.getDate() - 365);

                onChange([moment(lastYearFromNow), moment(today)])

            } else if (value === 'this_month') {
                const firstDayOfThisMonth = new Date(today.getFullYear(), today.getMonth(), 1);
                const lastDayOfThisMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

                onChange([moment(firstDayOfThisMonth), moment(lastDayOfThisMonth)])

            } else if (value === 'this_quarter') {
                const currentQuarter = Math.floor((today.getMonth()) / 3);
                const year = today.getFullYear();
                const thisQuarterStart = new Date(year, currentQuarter * 3, 1);
                const thisQuarterEnd = new Date(year, (currentQuarter + 1) * 3, 0);

                onChange([moment(thisQuarterStart), moment(thisQuarterEnd)])

            } else if (value === 'this_year') {
                const firstDayOfThisYear = new Date(today.getFullYear(), 0, 1);
                const lastDayOfThisYear = new Date(today.getFullYear(), 11, 31);

                onChange([moment(firstDayOfThisYear), moment(lastDayOfThisYear)])

            } else if  (value === 'last_month') {
                const firstDayOfPreviousMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
                const lastDayOfPreviousMonth =  new Date(today.getFullYear(), today.getMonth(), 0);


                onChange([moment(firstDayOfPreviousMonth), moment(lastDayOfPreviousMonth)])

            } else if (value === 'last_quarter') {
                const currentQuarter = Math.floor((today.getMonth() / 3));
                const previousQuarterStart = new Date(today.getFullYear(), currentQuarter * 3 - 3, 1);
                const previousQuarterEnd = new Date(previousQuarterStart.getFullYear(), previousQuarterStart.getMonth() + 3, 0);

                onChange([moment(previousQuarterStart), moment(previousQuarterEnd)])

            } else if (value === 'last_year') {
                const previousYear = new Date().getFullYear() - 1;
                const firstDayOfPreviousYear = new Date(previousYear, 0, 1);
                const lastDayOfPreviousYear = new Date(previousYear, 11, 31);

                onChange([moment(firstDayOfPreviousYear), moment(lastDayOfPreviousYear)])

            }
        }
    }

        const sortOptions = [
            {
                value: 'last_365_days',
                label: 'Last 365 days.',
            },
            {
                value: 'this_month',
                label: 'This Month',
            },
            {
                value: 'this_quarter',
                label: 'This Quarter',
            },
            {
                value: 'this_year',
                label: 'This Year',
            },
            {
                value: 'last_month',
                label: 'Last Month',
            },
            {
                value: 'last_quarter',
                label: 'Last Quarter'
            },
            {
                value: 'last_year',
                label: 'Last Year'
            },
            {
                value: 'all_dates',
                label: 'All Dates',
            },
            {
                value: 'custom_range',
                label: 'Custom Range',
            },
        ];


   useEffect(() => {
       if (!valueGTE && !valueLTE) {
           setSelectedDate(undefined)
       }

   },[valueGTE ,valueLTE])

    return (
        <>
            <label className={'generic_select__label'}>{label}</label>
               <Select
                className={'generic_select mb-3'}
                style={{ width: '100%' }}
                onChange={handleSelectChange}
                options={sortOptions}
                placeholder="Please select"
                value={selectedDate}
                />

            { isCustomDateRange ? (
                <>
                    {errorMessage ? <div className={"col-12 text-danger"}>Please select start date and end date.</div> : ""}
                    <DatePicker onChange={date => handleChange([date, valueLTE])} className={'col-5'} value={valueGTE} placeholder={"Start Date"} />
                    <span className={'col-1'}>-</span>
                    <DatePicker onChange={date => handleChange([valueGTE, date])} className={'col-5'} value={valueLTE} placeholder={"End Date"}/>

                </>
            ): ("")}
        </>
    )
}
export default GenericDateFilterFieldWithSelector;