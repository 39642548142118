import LogRocket from 'logrocket';

import {
  logout,
  setRefreshToken,
  setUserRole,
  setUserToken,
} from "../../Controller/localStorageHandler";
import { routes } from "../../Controller/Routes";
import { history } from "../../Controller/history";
import { login } from "../../Controller/api/authServices";

export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const CHANGE_DEFAULT_WAREHOUSE = "CHANGE_DEFAULT_WAREHOUSE";

export function userLoginAction(data) {
  return (dispatch) => {
    return login(data).then((response) => {
      setUserToken(response.data.access);
      setRefreshToken(response.data.refresh);
      setUserRole(response.data.user?.role)
      dispatch({
        type: LOGIN_SUCCESS,
        payload: response.data.user,
      });
      LogRocket.identify(String(response.data.user.id), {
        name: `${response.data.user.first_name} ${response.data.user.last_name}`,
        first_name: response.data.user.first_name,
        last_name: response.data.user.last_name,
        email: response.data.user.email,
        environment: process.env.REACT_APP_ENVIRONMENT,
      });
      history.push(routes.dashboard.self);
    });
  };
}

export const userLogOutAction = () => {
  logout();
  history.push(routes.login);
  window.location.reload();
  return { type: LOGOUT_SUCCESS };
};
