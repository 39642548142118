import React, { Component } from "react";
import { Button, Collapse, Form, Input } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import { Image as Images } from "../../../../Images";
import GeneralInfo from "./GeneralInfo";
import PaymentInfo from "./PaymentInfo";
import AddressInfo from "../AddressInfo";
import ContactsInfo from "../ContactsInfo";
import DocumentsInfo from "../DocumentsInfo";
import OpportunitiesInfo from "../OpportunitiesInfo";
import AssociatedAccounts from "../AssociatedAccounts";
import CustomerPostTab from "../CustomerPostTab";
import { history } from "../../../../../Controller/history";
import { reverse } from "named-urls/dist/index.es";
import { routes } from "../../../../../Controller/Routes";
import { withRouter } from "react-router-dom";
import ActivityInfo from "../../../common/ActivityInfo";
import CustomerTeamInfoView from "./CustomerTeamInfoView";
import ProposalCustomerInfo from "../ProposalCustomerInfo";
import ProjectsInfo from "../../../../contacts/summaryInfo/ProjectsInfo";
import WorkOrdersInfo from "../../../../contacts/summaryInfo/WorkOrdersInfo";

const { Panel } = Collapse;

function callback(key) {
  // console.log(key);
}

class SummaryInfo extends Component {
  state = {
    visibleTaskCreate: false,
    visibleCreateNote: false,
    visibleViewContact: false,
    visibleCreateContact: false,
    visibleViewAccount: false,
    visibleAddAccount: false,
  };

  showCreateTask = (visible) => {
    this.setState({
      visibleTaskCreate: visible,
    });
  };

  showCreateNote = (visible) => {
    this.setState({
      visibleCreateNote: visible,
    });
  };

  showViewContact = (visible) => {
    this.setState({
      visibleViewContact: visible,
    });
  };

  showCreateContact = (visible) => {
    this.setState({
      visibleCreateContact: visible,
    });
  };

  showViewAccount = (visible) => {
    this.setState({
      visibleViewAccount: visible,
    });
  };

  showAddAccount = (visible) => {
    this.setState({
      visibleAddAccount: visible,
    });
  };
  
  render() {
    const { customer } = this.props;
    console.log(this.props, 'sumaryyyyyy')
    return (
      <React.Fragment>
        <div className="col-12">
          <div className="row summary-info-inner-row">
            <div className="col-12">
              <Collapse
                // accordion
                defaultActiveKey={["1"]}
                onChange={callback}
                expandIcon={({ isActive }) => (
                  <CaretRightOutlined rotate={isActive ? 90 : 0} />
                )}
              >
                <Panel
                  header={
                    <div className="opportunity_info-collapse d-flex align-items-center justify-content-between">
                      <span>
                        General Information <sup>*</sup>
                      </span>
                      <Button
                        onClick={() =>
                          history.push({
                            pathname: reverse(
                              routes.dashboard.customer_account.edit,
                              { id: this.props.match.params.id }
                            ),
                            editTab: "1"

                          })
                        }
                        className="edit-btn-summary"
                      >
                        <img
                          src={Images.pencil_green}
                          alt=""
                          className="img-fluid"
                        />
                        Edit
                      </Button>
                    </div>
                  }
                  key="1"
                >
                  <GeneralInfo />
                </Panel>

                <Panel
                  header={
                    <div className="opportunity_info-collapse d-flex align-items-center">
                      <span>Posts </span>
                    </div>
                  }
                  key="10"
                >
                  <CustomerPostTab {...this.props} hideTitle />
                </Panel>

                <Panel
                  header={
                    <div className="opportunity_info-collapse d-flex align-items-center">
                      <span>Activity </span>
                    </div>
                  }
                  key="11"
                >
                  <ActivityInfo {...this.props} hideTitle hideSearch />
                </Panel>

                <Panel
                  header={
                    <div className="opportunity_info-collapse d-flex align-items-center justify-content-between">
                      <span>
                        Payment Information 
                      </span>
                      <div className="d-flex align-items-center">
                      {/* {checkAccountRequired(customer, "PAYMENT") && (
                        <p className="mb-0 info-signifire mr-3">
                          Please complete required information to avoid issues
                        </p>)} */}
                      <Button
                        onClick={() =>
                          history.push({
                            pathname: reverse(
                              routes.dashboard.customer_account.edit,
                              { id: this.props.match.params.id }
                            ),
                            editTab: "2",
                          })
                        }
                        className="edit-btn-summary"
                      >
                        <img
                          src={Images.pencil_green}
                          alt=""
                          className="img-fluid"
                        />
                        Edit
                      </Button>
                      </div>
                    </div>
                  }
                  key="2"
                >
                  <PaymentInfo />
                </Panel>

                <Panel
                  header={
                    <div className="opportunity_info-collapse d-flex align-items-center justify-content-between">
                      <span>
                        Address Information 
                      </span>
                      <div className="d-flex align-items-center">
                      {/* {checkAccountRequired(customer,"ADDRESS") && (
                        <p className="mb-0 info-signifire mr-3">
                          Please complete required information to avoid issues
                        </p>)} */}
                      <Button
                        onClick={() =>
                          history.push({
                            pathname: reverse(
                              routes.dashboard.customer_account.edit,
                              { id: this.props.match.params.id }
                            ),
                            editTab: "3",
                          })
                        }
                        className="edit-btn-summary"
                      >
                        <img
                          src={Images.pencil_green}
                          alt=""
                          className="img-fluid"
                        />
                        Edit
                      </Button>
                      </div>
                    </div>
                  }
                  key="3"
                >
                  <AddressInfo hideTitle={true} />
                </Panel>

                <Panel
                  header={
                    <div className="opportunity_info-collapse d-flex align-items-center justify-content-between">
                      <span>Contacts </span>
                      <Button
                        onClick={() =>
                          history.push({
                            pathname: reverse(
                              routes.dashboard.customer_account.edit,
                              { id: this.props.match.params.id }
                            ),
                            editTab: "4",
                          })
                        }
                        className="edit-btn-summary"
                      >
                        <img
                          src={Images.pencil_green}
                          alt=""
                          className="img-fluid"
                        />
                        Edit
                      </Button>
                    </div>
                  }
                  key="4"
                >
                  <ContactsInfo {...this.props} hideTitle={true} hideSearch viewAll={false} />
                </Panel>

                <Panel
                  header={
                    <div className="opportunity_info-collapse d-flex align-items-center justify-content-between">
                      <span>Team </span>
                      <Button
                        onClick={() =>
                          history.push({
                            pathname: reverse(
                              routes.dashboard.customer_account.edit,
                              { id: this.props.match.params.id }
                            ),
                            editTab: "6",
                          })
                        }
                        className="edit-btn-summary"
                      >
                        <img
                          src={Images.pencil_green}
                          alt=""
                          className="img-fluid"
                        />
                        Edit
                      </Button>
                    </div>
                  }
                  key="12"
                >
                  <CustomerTeamInfoView
                    {...this.props}
                    hideTitle={true}
                    hideSearch
                  />
                </Panel>

                <Panel
                  header={
                    <div className="opportunity_info-collapse d-flex align-items-center">
                      <span>Documents </span>
                    </div>
                  }
                  key="5"
                >
                  <DocumentsInfo {...this.props} hideTitle={true} />
                </Panel>
                <Panel
                  header={
                    <div className="opportunity_info-collapse d-flex align-items-center">
                      <span>Associated Accounts</span>
                    </div>
                  }
                  key="6"
                >
                  <AssociatedAccounts {...this.props} hideTitle />
                </Panel>

                <Panel
                  header={
                    <div className="opportunity_info-collapse d-flex align-items-center">
                      <span>Opportunities</span>
                    </div>
                  }
                  key="7"
                >
                  <OpportunitiesInfo {...this.props} hideTitle />
                </Panel>

                <Panel
                  header={
                    <div className="opportunity_info-collapse d-flex align-items-center">
                      <span>Proposals</span>
                    </div>
                  }
                  key="13"
                >
                  <ProposalCustomerInfo {...this.props} hideTitle viewAll={false} />
                </Panel>

                <Panel
                  header={
                    <div className="opportunity_info-collapse d-flex align-items-center">
                      <span>Projects</span>
                    </div>
                  }
                  key="8"
                >
                  <ProjectsInfo {...this.props} />

                </Panel>

                <Panel
                  header={
                    <div className="opportunity_info-collapse d-flex align-items-center">
                      <span>Work Orders</span>
                    </div>
                  }
                  key="9"
                >
                  <WorkOrdersInfo  {...this.props} />
                </Panel>
              </Collapse>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(SummaryInfo);
