import React from "react";

import styles from './CustomSelectOptionStyles.module.css'
import moment from "moment";



export type CustomSelectOptionProps = {
    name: string,
    icon: React.ReactElement,
    id: string,
    serviceDate: string
}

export const CustomSelectOption = ({ name, icon , id, serviceDate } : CustomSelectOptionProps) => {
    const formattedDate = moment(new Date(serviceDate)).utc().format("MM/DD/YYYY"); // Format as mm/dd/yyyy

    return (
        <div className={`${styles.container} p-4`}>
            <div className={styles.flexContainer}>
                <div className={`mr-4`}>
                    {icon}
                </div>
                <div>
                    <h6 className={styles.title}>{name}</h6>
                    <div className={`${styles.flexContainer} ${styles.subtext}`}>
                        <div className={'mx-2'}>Work Order ID : {id}</div>
                        <div>Service Date : {serviceDate && serviceDate !== "-" ? formattedDate : "-"}</div>
                    </div>
                </div>
            </div>
            <div className={`${styles.typeText}`}>Work Order</div>
        </div>
    )

}

export  default CustomSelectOption;

