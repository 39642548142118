import { SIDEBAR_KEY } from "../actions/sidebarAction";

export interface SidebarState {
  key: string | undefined;
}

const initialState: SidebarState = {
  key: undefined,
};

export const sidebarReducer = (state = initialState, action: any): SidebarState => {
  switch (action.type) {
    case SIDEBAR_KEY:
      return {
        ...state,
        key: action.payload,
      };
    default:
      return state;
  }
};

export default sidebarReducer;
