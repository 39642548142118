import React, { useEffect, useState } from "react";
import {connect} from "react-redux";
import {Breadcrumb, Tabs} from "antd";
import {setBreadcrumb} from "../../../../Store/actions/breadcrumbAction";
import {routes} from "../../../../Controller/Routes";

const SummaryReportMain = (props) => {


    return (
        <>
            <h1>Summary report test</h1>
        </>
    )
}

export default connect(null, {setBreadcrumb})(SummaryReportMain);