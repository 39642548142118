import React, {  useState } from "react";
import { DatePicker, Button} from "antd";
import styles from "./ToolBarStyles.module.css";
import {Image as Images} from "../../../../Images";
import GenericFilterDrawer from "../../../../drawers/generic-filter/GenericFilterDrawer";
import FilterBadge from "../../../../filter-badge/FilterBadge";
import {checkFilterValue} from "../../../../../Controller/utils";
// import {  DownloadOutlined } from '@ant-design/icons';



const { RangePicker } = DatePicker;

const ToolBar = ({ onChange, filters, onChangeFilters, storageName }) => {


    const [visibleFilter, setVisibleFilter] = useState(false);
    const [filterApplied, setFilterApplied] = useState(false);
    const [filterObj, setFilterObj] = useState(null);
    const [isClearFilter,setIsCleanFilter] = useState(false)

    const sortByDateOption = [
        {
            value: 'MonthToDate',
            label: 'Month to Date',
        },
        {
            value: 'YearToDate',
            label: 'Year to Date',
        },
        {
            value: 'SpecificDateRange',
            label: 'Specific date range',
        },
    ];


    const filterClose = (visible) => {
        setVisibleFilter(visible)
    };

    const handleFilterChange = (value, valueWithLabel) => {
        handleSetFilterObj(valueWithLabel)
        if (onChangeFilters){
            onChangeFilters(value)
        }
    }

    const handleSetFilterObj = (filter) => {
        setFilterObj(filter)
        const checkValue = checkFilterValue(filter)
        if (checkValue && checkValue.length > 0) {
            setFilterApplied(true)
            setIsCleanFilter(false)
        } else {
            setFilterApplied(false)
        }
    }


    return (
        <>
            <div className={`${styles.main} data-table`}>
                <div>
                    <Button
                        onClick={() => filterClose(true)}
                        className={styles.filterBtn}
                        icon={<img alt={" "} src={Images.filter_icon} className={`${styles.buttonIcon} mr-2`}/>}
                    >
                         Filter
                    </Button>
                </div>
                {/*<div>*/}
                {/*    /!*TODO: temporary comment it out *!/*/}
                {/*    <Button icon={<DownloadOutlined/>}  />*/}
                {/*</div>*/}
                {
                    filters &&
                    <GenericFilterDrawer
                        visible={visibleFilter}
                        onClose={() => filterClose(false)}
                        filters={filters}
                        changeFilters={(filters, labels) => handleFilterChange(filters, labels)}
                        isClearFilter={isClearFilter}
                        storageName={storageName}
                    />
                }

            </div>
            <div className={` ${styles.filterBadgeContainer} row mx-0`}>
                {filterApplied &&
                    <FilterBadge
                        filterObj={filterObj}
                        handleFilterChange={handleFilterChange}
                        filters={filters}
                        setIsCleanFilter={setIsCleanFilter}
                    />
                }
            </div>
        </>
    )
}

export default ToolBar;