import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {Button, Dropdown, InputNumber, Menu, Select, Table} from 'antd';
import  "./MiscellaneousDataTableStyles.css";
import {CaretDownOutlined} from "@ant-design/icons";
import { AlignType } from "rc-table/lib/interface"
import {Image as Images} from "../../../Images";
import CommonWarningModal from "../../../modals/CommonWarningModal";
import {RootState} from "../../../../Store/store";


export type UnitItemInterface = {
    label: string,
    value: number,
    disabled: boolean
}

export type MiscellaneousDataProps = {
    created: string,
    id: number,
    invoice: number,
    miscellaneous: {
        name: string,
        cost_type: string,
        qty: string,
        uom: number,
        price_per_unit: string,
        total_price: number,
        miscellaneous_type: string,
        id: number,
    },
    modified: string,
    total_price?: number,
}

export type MiscellaneousDataTableProps = {
    data: MiscellaneousDataProps[],
    tableType: string,
    editable: boolean,
    onUpdate?: (id: number, value: MiscellaneousDataProps ) => void,
    onDelete?: (id: number | undefined) => void,
    isLoading: boolean
}

export const MiscellaneousDataTable = ( { data, tableType, editable, onUpdate, isLoading, onDelete } : MiscellaneousDataTableProps ) => {
    const alignCenter: AlignType = "center";
    const [removeWarningVisible, setRemoveWarningVisible] = useState(false);
    const [removableId, setRemovableId] = useState<number | undefined>();
    const [uomItems, setUomItems] = useState<any[] | undefined>();
    // @ts-ignore
    const unitItems = useSelector((state: RootState) => state.unit_item_data) || [];

    const selectOption = [
        {
            label: "Amount",
            value: "AMOUNT"
        },
        {
            label: "Percentage",
            value: "PERCENTAGE"
        }
    ];

    const itemsForSelect = () => {
        let uomItemsSorted = [...unitItems].sort((a: any, b: any) => {
            if(a.unit_type.id < b.unit_type.id) return -1;
            if(a.unit_type.id > b.unit_type.id) return 1;
            return 0;
        });

        let selectUOM: UnitItemInterface[] = [];
        for (let unit_item of uomItemsSorted) {
            let itemExistsType = selectUOM.find((uom: any) => uom.value === (unit_item.unit_type.id * 1000));
            let itemExists = selectUOM.find((uom: any) => uom.value === unit_item.id);
            if (!itemExistsType) {
                selectUOM.push({
                    label: unit_item.unit_type.name,
                    value: unit_item.unit_type.id * 1000,
                    disabled: true
                } , {
                    label: unit_item.name + " (" + unit_item.symbol + ")",
                    value: unit_item.id,
                    disabled: false
                });
            } else {
                if (!itemExists) {
                    selectUOM.push({
                        label: unit_item.name + " (" + unit_item.symbol + ")",
                        value: unit_item.id,
                        disabled: false
                    });
                }
            }
        }
        setUomItems(selectUOM);
    }

    useEffect(() => {
        itemsForSelect();
    }, []);

    const columns = [
        {
            title: tableType,
            dataIndex: 'name',
            key: 'name',
            align: alignCenter,
            width: '400',
            render: (_ : any, record : MiscellaneousDataProps) => {
                const id = record.id;

                return {
                    props: {
                        style: {
                            background: record?.miscellaneous?.miscellaneous_type === "DISCOUNT" ? "rgba(227, 153, 153, 0.15)" : "rgba(218, 242, 235, 1)",
                            width: "830px"
                        },
                    },
                    children:
                    <div className={"flexContainer"}>
                        <div></div>
                        <div className={"nameCol"}>{record?.miscellaneous?.name}</div>
                        {!editable ? (
                            <div></div>
                        ) : (
                            <Dropdown
                                overlayClassName="add-remove-dropdown-main"
                                overlay={
                                    <Menu>
                                        <Menu.Item key="0">
                                            <Button
                                                onClick={() => {
                                                    setRemovableId(id);
                                                    setRemoveWarningVisible(true);

                                                }}
                                                className="border-0 bg-transparent text-center p-0 w-100"
                                            >
                                                Remove
                                            </Button>
                                        </Menu.Item>
                                    </Menu>
                                }
                                trigger={["click"]}
                                disabled={!editable}
                            >
                                <div
                                    className={"removeBtn"}
                                    onClick={(e: any) => e.preventDefault()}
                                >
                                    <img
                                        alt="remove-icon"
                                        src={Images.black_dots_elipsis}
                                        className="img-fluid"
                                    />
                                </div>
                            </Dropdown>
                        )}
                    </div>
                };
            }
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'uom',
            align: alignCenter,
            width: '400',
            render: ( _ : any, record : MiscellaneousDataProps) => {
                const id = record.id;
                const miscellaneous = record.miscellaneous;

                return {
                    props: {
                        style: { background: !editable ? "#F2F2F2" : "#FFFFFF" },
                    },
                    children: (
                        <Select
                            className={"tableSelectOption"}
                            suffixIcon={<CaretDownOutlined />}
                            onChange={(value) => {
                                const updateData = {
                                    created: record.created,
                                    id: record.id,
                                    invoice: record.invoice,
                                    miscellaneous: {
                                        ...miscellaneous,
                                        cost_type: value.toString()
                                    },
                                    modified: record.modified,
                                };

                                onUpdate && onUpdate(id, updateData)
                            }}
                            placeholder="select"
                            loading={isLoading}
                            value={record?.miscellaneous?.cost_type}
                            options={selectOption}
                            disabled={!editable}
                        />
                    )
                };
            }
        },
        {
            title: 'Qty',
            dataIndex: 'qty',
            key: 'qty',
            align: alignCenter,
            width: 160,
            render: (_ : any, record : MiscellaneousDataProps ) => {

                const id = record.id;
                const miscellaneous = record.miscellaneous;
                const isPercentage = record.miscellaneous.cost_type === "PERCENTAGE";

                return {
                    props: {
                        style: { background: isPercentage ? "#F2F2F2" : !editable ? "#F2F2F2" : "#FFFFFF" },
                    },
                    children: (
                        <div className={"disableInput"}>
                            <InputNumber
                                placeholder=""
                                // onChange={}
                                value={isPercentage ? "-" : record?.miscellaneous?.qty}
                                disabled={!editable || isPercentage}
                                onBlur={(e) => {
                                    const updateData = {
                                        created: record.created,
                                        id: record.id,
                                        invoice: record.invoice,
                                        miscellaneous: {
                                            ...miscellaneous,
                                            qty: e.target.value,
                                        },
                                        modified: record.modified,
                                    };
                                    onUpdate && onUpdate(id, updateData)
                                }}
                            />
                        </div>
                    ),
                };
            }
        },
        {
            title: 'UOM',
            dataIndex: 'uom',
            key: 'uom',
            align: alignCenter,
            width: '160',
            render: ( _ : any, record : MiscellaneousDataProps) => {
                const id = record.id;
                const miscellaneous = record.miscellaneous;
                const isPercentage = record.miscellaneous.cost_type === "PERCENTAGE";

                return {
                    props: {
                        style: { background: isPercentage ? "#F2F2F2" : !editable ? "#F2F2F2" : "#FFFFFF" },
                    },
                    children: (
                        <Select
                            className={"tableSelectOption"}
                            suffixIcon={<CaretDownOutlined />}
                            onChange={(value) => {
                                const updateData = {
                                    created: record.created,
                                    id: record.id,
                                    invoice: record.invoice,
                                    miscellaneous: {
                                        ...miscellaneous,
                                        uom: value
                                    },
                                    modified: record.modified,
                                };

                                onUpdate && onUpdate(id, updateData)
                            }}
                            placeholder="UOM"
                            loading={isLoading}
                            value={record?.miscellaneous?.uom}
                            options={uomItems}
                            disabled={ isPercentage ? isPercentage : !editable }
                        />
                    )
                };
            }
        },
        {
            title: 'Price Per Unit',
            dataIndex: 'price_per_unit',
            key: 'price_per_unit',
            align: alignCenter,
            width: 160,
            render: (_ : any, record : MiscellaneousDataProps) => {
                const id = record.id;
                const miscellaneous = record.miscellaneous;
                const isPercentage =  miscellaneous?.cost_type === "PERCENTAGE" ? '%' : ''
                const isAmount = miscellaneous?.cost_type === "AMOUNT" ? '$' : ''
                const formatValue = miscellaneous?.cost_type === "PERCENTAGE" ? (parseFloat(record?.miscellaneous?.price_per_unit || '0') * 100)
                    :  parseFloat(record?.miscellaneous?.price_per_unit || '0');

                const onUpdateValue = (updateValue: string) => {
                    const updateData = {
                        created: record.created,
                        id: record.id,
                        invoice: record.invoice,
                        miscellaneous: {
                            ...miscellaneous,
                            price_per_unit: updateValue,
                        },
                        modified: record.modified,
                    };
                    onUpdate && onUpdate(id, updateData)
                }

                return {
                    props: {
                        style: { background: !editable ? "#F2F2F2" : "#FFFFFF" },
                    },
                    children: (
                        <div className={"disableInput"}>
                            {miscellaneous?.cost_type === "PERCENTAGE" ? (
                                <div className={"disableInput"}>
                                    <InputNumber
                                        disabled={!editable}
                                        value={formatValue}
                                        formatter={(value) => `${value} ${isPercentage}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        onBlur={(e) => {
                                            const decimalValue = e.target.value !== ''
                                                ? parseFloat(e.target.value.replace(/[$%]/g, '')) / 100 : 0;
                                            const updateValue =  decimalValue.toString();
                                            onUpdateValue(updateValue);
                                        }}
                                    />
                                </div>
                            ) : (
                                <InputNumber
                                    disabled={!editable}
                                    value={formatValue}
                                    formatter={(value) => `${isAmount} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    onBlur={(e) => {
                                        const rawValue = e.target.value !== '' ? e.target.value : '0';
                                        const updateValue =  rawValue.replace(/[$%]/g, '').toString();
                                        onUpdateValue(updateValue);
                                    }}
                                />
                            )}
                        </div>
                    )
                }
            }
        },
        {
            title: 'Total Price',
            dataIndex: 'total_price',
            key: 'total_price',
            className: "tableTotalPrice",
            align: alignCenter,
            width: 200,
            render: ( _ : any, record : MiscellaneousDataProps ) => {
                const style = record?.miscellaneous?.miscellaneous_type === "DISCOUNT" ? "warning" : "";
                return {
                    props: {
                        style: { background:  "#F2F2F2" },
                    },
                    children: (
                            <div className={`${style} disableInput`}>
                                $ {record?.total_price}
                            </div>
                    ),
                };
            }
        },
    ];



    return (
        <div>
            { data ? (
                <div className={"mb-4 miscellaneous-table"}>
                    <Table
                        columns={columns}
                        dataSource={data}
                        pagination={false}
                        loading={isLoading}
                    />
                </div>
                ) : (
                    <div className={"noDataContainer"}>
                        <h6>No Miscellaneous</h6>
                    </div>
                )
            }
            <CommonWarningModal
                common
                newCommonModal={true}
                visible={removeWarningVisible}
                onClose={() => {
                    setRemoveWarningVisible(false);
                    setRemovableId(undefined);
                }}
                serviceVariantWarning
                commonFunc={() => {
                    onDelete && onDelete(removableId)
                    setRemoveWarningVisible(false);
                    setRemovableId(undefined);
                }}
                heading={
                    "Are you sure you want to remove this?"
                }
                subHeadingUOM={" "}
            />
        </div>
    )
};

export default MiscellaneousDataTable;
