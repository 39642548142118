import { AppDispatch } from "../store";
import {getUnitNames} from "../../Controller/api/invoiceServices";
export const UNIT_ITEM = "UNIT_ITEM"

export function unitItemAction() {
    return async (dispatch: AppDispatch) => {
        const response = await getUnitNames();
        dispatch({
            type: UNIT_ITEM,
            payload: response?.data,
        });
    };
}
