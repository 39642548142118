import React, { Component } from "react";
import { Button, Modal } from "antd";
import { Image as Images } from "../../Images";

const CommonWarningNew = ({ subTitle, title, visible, onCancel, onSubmit, okText, cancelText}) => {

    return (
      <React.Fragment>
        <Modal
          visible={visible}
          onOk={onSubmit}
          onCancel={onCancel}
          centered
          className={"confirmation-popup-modal warning-modal"}
          okText={okText ? okText : "Yes, I want to continue"}
          cancelText={cancelText ? cancelText : "No, go back"}
          // footer={
          //   <div className="row mx-0">
          //     <Button type={"danger"}>Exit</Button>
          //     <Button>Continue</Button>
          //
          //   </div>
          // }
        >
          <div className={"row mx-0 confirm-modal-row"}>
            <div className="col-12 text-center">
              <img src={Images.warning_icon} alt="" className="img-fluid" />
              <h5>{title}</h5>
              <p className="mb-0">
                  {subTitle}
              </p>
            </div>
          </div>
        </Modal>
      </React.Fragment>
    );
}

export default CommonWarningNew;
