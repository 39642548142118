import { CONTACT_DETAIL_SUCCESS } from "../actions/contactAction";

export interface ContactState {
  contactDetail: any;
}

const initialState: ContactState = {
  contactDetail: null,
};

export const contactReducer = (state = initialState, action: any): ContactState => {
  switch (action.type) {
    case CONTACT_DETAIL_SUCCESS:
      return  action.payload ;
    default:
      return state;
  }
};

export default contactReducer;
