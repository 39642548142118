export const costSettingOptions = [
  {
    name: "Standard",
    value: "STANDARD",
  },
  {
    name: "Lump Sum",
    value: "LUMP_SUM",
  },
  {
    name: "Lump Sum with UOM and QTY",
    value: "LUMP_SUM_WITH_UOM_AND_QTY",
  },
  {
    name: "Price Rate Only",
    value: "PRICE_RATE_ONLY",
  },
  {
    name: "Total Price Per Unit",
    value: "TOTAL_PRICE_PER_UNIT",
  },
];

export const paymentOptions = [
  {
    name: "Due on Receipt",
    value: "DUE_ON_RECEIPT",
  },
  {
    name: "30 days",
    value: "30_DAYS",
  },
  {
    name: "45 days",
    value: "45_DAYS",
  },
  {
    name: "60 days",
    value: "60_DAYS",
  },
  {
    name: "90 days",
    value: "90_DAYS",
  },
  {
    name: "Pay when paid",
    value: "PAY_WHEN_PAID",
  },
];
