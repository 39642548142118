import React, { Component } from 'react';
import moment from 'moment';
import {Image as Images} from "../../../Images";

class WoServiceInformation extends Component {
    render() {
        const { workOrder } = this.props
        const contact = workOrder && workOrder.site_contact || null;

        return (
            <React.Fragment>
                <div className="row summary-collapse-inner-row-main common-form-card-row pb-0 border-top-1">
                    <div className="row mb-2">
                        <div className="col-12">
                            <h6 className={"text-uppercase"}> Site Contact</h6>
                        </div>
                    </div>
                </div>

                {contact && (
                <div className="d-flex align-items-center p-2 mb-2 ml-4" style={{
                    borderRadius: "6px",
                    border: "1px solid #E0E0E0",
                    maxWidth: '348px'
                }}>
                    <div className="user-icons-div mr-2">
                        <img
                          src={Images.contact_file_icon_black}
                          alt=""
                          className="img-fluid"
                        />
                    </div>
                    <div style={{ width: 'calc(87% - 38px)' }} className="vc-select-option-data float-left">
                        <div className="row">
                            <div className="col-12">
                                <h6 className="d-flex mb-0 align-items-center">
                                    {contact.full_name}
                                    <small
                                        style={{
                                          borderLeft: "1px solid #e0e0e0",
                                          marginLeft: "5px",
                                          paddingLeft: "5px",
                                          fontWeight: "500",
                                          color: "#bdbdbd",
                                        }}
                                    >
                                        {contact.role}
                                    </small>
                                </h6>

                            </div>
                            <div className="row">
                              <div className="col-12">
                                <h6 className="col-12 vc-select-display-name">
                                    <div className="flex-column">
                                        <div className="display-data">
                                            {contact.emails && contact.emails.length > 0 && contact.emails[0].email}
                                        </div>
                                        <div className="display-data">
                                            {contact.phone_numbers && contact.phone_numbers.length > 0 && contact.phone_numbers[0].phone_number}
                                        </div>
                                    </div>
                                </h6>
                              </div>
                            </div>
                        </div>
                    </div>
                </div>
                )}
                <div className="row summary-collapse-inner-row-main ">
                    <div className="col-12">
                        <div className="row summary-view-row-vehicle border-bottom-0">
                            <div className="col-12 col-sm-6 col-md-3 col-lg-3 pl-0">
                                <h6 className="text-uppercase">SERVICE DATE</h6>
                                <h5 className="mb-0">{workOrder?.service_date ? moment(workOrder?.service_date).format("MMM DD,YYYY") :
                                    "-"}</h5>
                            </div>
                            <div className="col-12 col-sm-6 col-md-3 col-lg-3">
                                <h6 className="text-uppercase">Requested Site Arrival Time</h6>
                                <h5 className="mb-0">{workOrder?.start_time ? moment(workOrder?.start_time, "HH:mm").format('HH:mm A') : '-'}</h5>
                            </div>
                            <div className="col-12 col-sm-6 col-md-3 col-lg-3">
                                <h6 className="text-uppercase">Estimated End Time</h6>
                                <h5 className="mb-0">{workOrder?.end_time ? moment(workOrder?.end_time, "HH:mm").format('HH:mm A') : '-'}</h5>
                            </div>
                            <div className="col-12 col-sm-6 col-md-3 col-lg-3">
                                <h6 className="text-uppercase">Billing Account PO#/Job#</h6>
                                <h5 className="mb-0">{workOrder?.billing_account_po || '-'}</h5>
                            </div>
                        </div>
                        <div className="row summary-view-row-vehicle border-bottom-0">
                            <div className="">
                                <h6 className="text-uppercase">Requested Shop Arrival Time</h6>
                                <h5 className="mb-0">{workOrder?.shop_arrival_time ? moment(workOrder?.shop_arrival_time, "HH:mm").format('HH:mm A') : '-'}</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default WoServiceInformation;