import React, {useState, useEffect, createContext} from "react";
import {connect} from "react-redux";
import {setBreadcrumb} from "../../../../Store/actions/breadcrumbAction";
import styles from "./OperationReportStyles.module.css"
// import GraphData from "./components/GraphData/GraphData";
import "../../CustomStyles.css"
import FullWidthDataTable from "./components/FullWidthDataTable/FullWidthDataTable";
import ToolBar from "../components/ToolBar/ToolBar";
import OperationLineChart from "./components/OperationLineChart/OperationLineChart";
import OperationDetailCard from "./components/OperationDetailCard/OperationDetailCard";
import {
    FILTER_TYPE_BILLING_ACCOUNT,
    FILTER_TYPE_DATE_RANGE_WITH_SELECTOR,
    FILTER_TYPE_SALES_PEOPLE,
    FILTER_TYPE_SITE_MANAGER,
    FILTER_TYPE_WAREHOUSE
} from "../../../drawers/generic-filter/GenericFilterDrawerTypes";
import {
    getAllProjectAnalytics,
    getProjectAnalyticsDetail,
    getAllProjectAnalyticsSummary
} from "../../../../Controller/api/projectServices";
import {handleError} from "../../../../Controller/Global";
import { numberWithCommas } from "../../../../Controller/utils";


export const OperationsReportContext = createContext()

const OperationReportMain = (props) => {

    const [isLoading, setIsLoading] = useState(false);
    const [isChartLoading, setIsChartLoading] = useState(false);
    const [ selectedDataType, setSelectedDataType] = useState( {
        value: 'profit',
        label: 'Profit',
    });
    const [selectedGroupByData, setSelectedGroupByData] = useState(  {
        value: 'month',
        label: 'Month',
    });

    const [chartData, setChartData] = useState([]);

    const [summaryData, setSummaryData] = useState([]);
    const [projectDetailData, setProjectDetailData] = useState([]);
    const [filters, setFilters] = useState({});
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 15,
        showSizeChanger: false,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
    },)

    const handleGetAllProjectAnalyticsSummary = async (params) => {
        setIsLoading(true);
        try {
            const res = await getAllProjectAnalyticsSummary(params);

            setSummaryData(res.data)
            setIsLoading(false);

        } catch (err) {
            handleError(err);
            setIsLoading(false);
        }

    }

    const handleGetAllProjectAnalytics = async (params) => {
        setIsLoading(true);
        try {
            const res = await getAllProjectAnalytics(params);

            setChartData(res.data)
            setIsLoading(false);

            handleDataForChart(res.data);

        } catch (err) {
            handleError(err);
            setIsLoading(false);
        }

    }

    const handleGetAllProjectsDetail = async (params = {}) => {
        setIsLoading(true);
        try {

            const res = await getProjectAnalyticsDetail(params);

            setProjectDetailData(res.data.results)
            setIsLoading(false);
            setPagination({
                ...pagination,
                current: params.page || 1,
                total: res.data.count,
            })
        } catch (err) {
            handleError(err);
            setIsLoading(false);
        }
    }



    const handleDataForChart = (data) => {
        const graphData = data.map((i) => {

            return {
                month: i.month || "",
                year: i.year || "",
                week: i.week || "",
                fiscal_quarter: i.fiscal_quarter || "",
                projected_profit: i.projected_profit,
                projected_revenue: i.projected_revenue,
                projected_expense: i.projected_expense,
            }
        })

        setChartData([...graphData])
    }


    const handleGroupByDataChange = (value) => {
        setSelectedGroupByData(value);
        const groupBy = {group_by: value.value}

        const params = {
            ...groupBy,
            ...filters
        }

        setTimeout(() => {
            handleGetAllProjectAnalytics(params);
        }, 200)

    }

    const handleDataTableChange = (pagination) => {
        const filterValueFromStorage = JSON.parse(sessionStorage.getItem(`analytic-operation-report-filters`));

        let params = {
            page: pagination.current,
            ...filterValueFromStorage
        };

        sessionStorage.setItem("page", pagination.current );

        handleGetAllProjectsDetail(params)
    };

    const handleFilterChange = (value) => {

        setFilters(value);
        const groupBy = {group_by: selectedGroupByData.value}

        // const params = getPaginationFromStorage("analytic-operation-report", "name")
        const paramsForGetAllProjectsDetail = {
            ...value,
            ...pagination

        }

        const paramsForGetAllProjectAnalytics = {
            ...value,
            ...groupBy,
        }



        setTimeout(() => {
            handleGetAllProjectAnalytics(paramsForGetAllProjectAnalytics)
            handleGetAllProjectsDetail(paramsForGetAllProjectsDetail)
            handleGetAllProjectAnalyticsSummary({...value})
        }, 100)
    };



    useEffect(() => {
        const param = {group_by: selectedGroupByData.value}

        handleGetAllProjectAnalytics(param)
        handleGetAllProjectsDetail({})
        handleGetAllProjectAnalyticsSummary({})

    },[])



    return (
        <div className={`${styles.main}`}>
            <ToolBar
                filters={[
                    { type: FILTER_TYPE_WAREHOUSE, label: 'Warehouse', name:'project__work_order__workorder_warehouse__warehouse_id__in' },
                    { type: FILTER_TYPE_BILLING_ACCOUNT, label: 'Billing Account', name:'project__project_customer_contact__account__in' },
                    { type: FILTER_TYPE_SITE_MANAGER, label: 'Site Manager Account', name:'project__project_owner_contact__account__in' },
                    { type: FILTER_TYPE_SALES_PEOPLE, label: 'Sales Person', name:'project__sales_person__id__in' },
                    { type: FILTER_TYPE_DATE_RANGE_WITH_SELECTOR, label: 'Date Range', name:'project__created' },
                ]}
                onChangeFilters={handleFilterChange}
                storageName={"analytic-operation-report"}
            />
                <div className={`${styles.cardSection}`}>
                    <OperationDetailCard
                        title={"Sales Estimate"}
                        data={[
                            {
                                title: "Estimate Revenue",
                                value: summaryData.total_estimated_revenue ? `$${numberWithCommas(summaryData.total_estimated_revenue)}` : "-"
                            },
                            {
                                title: "Estimate Expense",
                                value: summaryData.total_estimated_expense ? `$${numberWithCommas(summaryData.total_estimated_expense)}` : "-"
                            },
                            {
                                title: "Estimate Profit",
                                value: summaryData.total_estimated_profit ? `$${numberWithCommas(summaryData.total_estimated_profit)}` : "-"
                            },
                            {
                                title: "Estimate Profit Margin",
                                value: summaryData.total_estimated_profit_margin ? `${numberWithCommas(summaryData.total_estimated_profit_margin)}%` : "-"
                            }
                        ]}
                        isLoading={isLoading}
                    />
                    <OperationDetailCard
                        title={"Operations Projection"}
                        data={[
                            {
                                title: "Projected Revenue",
                                value: summaryData.total_projected_revenue ? `$${numberWithCommas(summaryData.total_projected_revenue)}` : "-"
                            },
                            {
                                title: "Projected  Expense",
                                value: summaryData.total_projected_expense ? `$${numberWithCommas(summaryData.total_projected_expense)}` : "-"
                            },
                            {
                                title: "Projected  Profit",
                                value: summaryData.total_projected_profit ? `$${numberWithCommas(summaryData.total_projected_profit)}` : "-"
                            },
                            {
                                title: "Projected  Profit Margin",
                                value: summaryData.total_projected_profit_margin ? `${numberWithCommas(summaryData.total_projected_profit_margin)}%` : "-" }
                        ]}
                        isLoading={isLoading}
                    />
                    <OperationDetailCard
                        title={"Accounting"}
                         data={[
                                {
                                    title: "Actual Revenue",
                                    value: summaryData.total_actual_revenue ? `$${numberWithCommas(summaryData.total_actual_revenue)}` : "-" },
                                {
                                    title: "Actual Expense",
                                    value: summaryData.total_actual_expense ? `$${numberWithCommas(summaryData.total_actual_expense)}` : "-" },
                                {
                                    title: "Actual Profit",
                                    value:  summaryData.total_actual_profit ? `$${numberWithCommas(summaryData.total_actual_profit)}` : "-"
                                },
                                {
                                    title: "Actual Profit Margin",
                                    value: summaryData.total_profit_margin ? `${numberWithCommas(summaryData.total_profit_margin)}%` : "-"
                                },
                                {
                                    title: "Account Receivables",
                                    value: summaryData.total_accounts_receivable ? `$${numberWithCommas(summaryData.total_accounts_receivable)}` : "-"
                                },
                                {
                                    title: "Paid",
                                    value: summaryData.total_paid ? `$${numberWithCommas(summaryData.total_paid)}` : "-"
                                },
                                {
                                    title: "Over Due",
                                    value: summaryData.total_over_due ? `$${numberWithCommas(summaryData.total_over_due)}` : "-"
                                }
                         ]}
                        isLoading={isLoading}
                        showTextColor={true}
                    />
                </div>

                <OperationsReportContext.Provider value={chartData}>
                    <OperationLineChart
                        data={chartData}
                        selectedDataType={selectedDataType}
                        setSelectedDataType={setSelectedDataType}
                        selectedGroupByData={selectedGroupByData}
                        handleGroupByDataChange={handleGroupByDataChange}
                        isLoading={isLoading}
                    />
                </OperationsReportContext.Provider>
                <FullWidthDataTable
                    graphTitle={"Project Details"}
                    isLoading={isLoading}
                    data={projectDetailData.map((i) => {
                        return {
                            key: i.id,
                            id: i.project.id,
                            name: i.project.name,
                            site: i.project.project_owner_contact[0]?.account?.name || "-",
                            estimate_revenue: i.estimated_revenue ? `$${numberWithCommas(i.estimated_revenue)}` : "-",
                            estimate_expense: i.estimated_expense ? `$${numberWithCommas(i.estimated_expense)}` : "-",
                            estimate_profit: i.estimated_profit ? `$${numberWithCommas(i.estimated_profit)}` : "-",
                            operations_revenue: i.projected_revenue ? `$${numberWithCommas(i.projected_revenue)}` : "-",
                            operations_expense: i.projected_expense ? `$${numberWithCommas(i.projected_expense)}` : "-",
                            operations_profit:  i.projected_profit ? `$${numberWithCommas(i.projected_profit)}` : "-",
                            accounting_revenue: i.actual_revenue ? `$${numberWithCommas(i.actual_revenue)}` : "-",
                            accounting_expense: i.actual_expense ? `$${numberWithCommas(i.actual_expense)}` : "-",
                            accounting_profit: i.actual_profit_sum ? `$${numberWithCommas(i.actual_profit_sum)}` : "-",
                        }
                        })}
                    pagination={pagination}
                    handleDataTableChange={handleDataTableChange}
                />
        </div>
    )
}

export default connect(null, {setBreadcrumb})(OperationReportMain);