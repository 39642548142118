import React, { useEffect, useState } from "react";
import { Divider } from "antd";
import styles from "./CustomDonutLegendStyles.module.css"





const CustomDonutLegend = ({
    donutBackgroundColorValue,
    donutValue,
    donutLabel,
    }) => {
    return (
    <div className={styles.legendHeader}>
        <div className={styles.legendHeaderSection}>
            <div className={`${styles.legendBar}`} style={{background: `${donutBackgroundColorValue}`}}></div>
            <div className={styles.legendTitle}>{donutLabel}</div>
        </div>
        <div className={`${styles.legendHeaderNumberSection} mr-5`}>
            <div className={styles.legendTitle}>{donutValue}</div>
            <Divider type="vertical" style={{height: 20, top: 0, bottom: 0}}/>
            <div className={styles.legendTitle}>{donutValue}%</div>
        </div>
    </div>
    )
}

export default CustomDonutLegend;