import { Get, Post } from "../headerIntercepter";
import { getAPIUrl } from "../Global";

export function login(data) {
  const url = getAPIUrl("auth.login");
  return Post(url, data, false);
}

export function getUser(type, params) {
  const url = getAPIUrl("auth.user", { type });
  return Get(url, params);
}
export function getGoogleAuthenticateURL(params) {
  const url = getAPIUrl("auth.gmail_authenticate");
  return Get(url, params);
}

export function getUserData() {
  const url = getAPIUrl("auth.profile");
  return Get(url);
}

export function logoutGmail(data) {
  const url = getAPIUrl("auth.gmail_logout");
  return Post(url, data);
}
export function logoutMicrosoft(data) {
  const url = getAPIUrl("auth.microsoft_logout");
  return Post(url, data);
}

export function storageWarehouse(data) {
  const url = getAPIUrl("auth.storage_warehouse");
  return Post(url, data);
}
