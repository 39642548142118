import React, { useEffect, useState } from "react";
import {Table, Space, Tag} from 'antd';
import styles from "./FullWidthDataTableStyles.module.css";
import "../../../../CustomStyles.css";
import {LoadingOutlined} from "@ant-design/icons";

const { Column, ColumnGroup } = Table;







const FullWidthDataTable = ({data, graphTitle,pagination, handleDataTableChange, isLoading}) => {




    return (
        <div className={`${styles.main} fullWidthDataTable`}>
            <div className={styles.graphHeader}>
                <h5>{graphTitle}</h5>
            </div>
                <div className={styles.scrollTable}>
                    <Table
                        dataSource={data}
                        pagination={pagination}
                        onChange={handleDataTableChange}
                        loading={isLoading ? {indicator:<LoadingOutlined style={{ fontSize: 24, color: '#9bd2bb' }} />} : false }

                    >
                        <Column title="ID" dataIndex="id" key="id" align={"center"}/>
                        <Column title="Project Name" dataIndex="name" key="name" align={"center"} />
                        <Column title="Site Manager Account" dataIndex="site" key="site" align={"center"} />
                        <ColumnGroup title="Sales Estimate">
                            <Column title="Revenue" dataIndex="estimate_revenue" key="estimate_revenue" align={"center"} />
                            <Column title="Expense" dataIndex="estimate_expense" key="estimate_expense" align={"center"} />
                            <Column title="Profit" dataIndex="estimate_profit" key="estimate_profit" align={"center"} />
                        </ColumnGroup>
                        <ColumnGroup title="Operations Projection">
                            <Column title="Revenue" dataIndex="operations_revenue" key="operations_revenue" align={"center"} />
                            <Column title="Expense" dataIndex="operations_expense" key="operations_expense" align={"center"} />
                            <Column title="Profit" dataIndex="operations_profit" key="operations_profit" align={"center"} />
                        </ColumnGroup>
                        <ColumnGroup title="Accounting">
                            <Column title="Revenue" dataIndex="accounting_revenue" key="accounting_revenue" align={"center"} />
                            <Column title="Expense" dataIndex="accounting_expense" key="accounting_expense" align={"center"} />
                            <Column title="Profit" dataIndex="accounting_profit" key="accounting_profit" align={"center"} />
                        </ColumnGroup>
                    </Table>
                </div>
        </div>
    )
}

export default FullWidthDataTable;