import { CUSTOMER_ACCOUNT_DETAIL_SUCCESS } from "../actions/customerAccountAction";

export interface CustomerAccountState {
  customerAccountDetail: any;
}

const initialState: CustomerAccountState = {
  customerAccountDetail: null,
};

export const customerAccountReducer = (state = initialState, action: any): CustomerAccountState => {
  switch (action.type) {
    case CUSTOMER_ACCOUNT_DETAIL_SUCCESS: {
      return action.payload
    }
    default: {
      return state;
    }
  }
};

export default customerAccountReducer;

export interface GlobalSearchState {
  globalSearchData: any;
}

const initialGlobalSearchState: GlobalSearchState = {
  globalSearchData: null,
};
const globalSearchReducer = (state = initialGlobalSearchState, action: any): GlobalSearchState => {
  switch (action.type) {
    case "GLOBAL_SEARCH":
      return action.payload ;
    default:
      return state;
  }
};

export { globalSearchReducer };
