import { getDefaultMiddleware } from '@reduxjs/toolkit';
import rootReducer from './reducers/mainReducer';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { useDispatch } from 'react-redux';
import { configureStore } from "@reduxjs/toolkit";

// Define the persistConfig for redux-persist
const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['user'],
};

// Wrap the rootReducer with persistReducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure the Redux store with Redux Toolkit
const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: false, // Disable warning for non-serializable values in the state
  }),
});

export default store;

export type RootState = typeof store.getState;
export type AppDispatch = typeof store.dispatch ;

export const useAppDispatch = () => useDispatch<AppDispatch>();

export const pStore = persistStore(store);

export { store };




