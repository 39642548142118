import React, { useEffect, useState } from "react";
import styles from './TotalOpportunitiesCardStyles.module.css';
import {Divider, Progress} from "antd";



const TotalOpportunitiesCard = ({ data, title, progressBarData }) => {
    return (
        <div className={styles.card}>
            <div className={`${styles.flexRow} analytics col-12`}>
                <div className={'analytics col-2'}>
                    <div className={styles.title}>Total Opportunities</div>
                    <div className={styles.pieNumber}>
                        <h2>250</h2>
                    </div>
                </div>
                <div className={`${styles.flexRow} col-10`}>
                    <Divider type="vertical" className={styles.divider}/>
                    <div className={`${styles.contextContainer} col-6`}>
                        { progressBarData.map((i) => {
                            return (
                                <div className={`${styles.progressContainer}`}>
                                    <div className={`${styles.flexRow} col-9`}>
                                        <p>{i.title}</p>
                                        <p>{i.value}</p>
                                    </div>
                                    <div className={`col-3`}>
                                        <Progress
                                          type="line"
                                          strokeColor={
                                                'rgba(105, 192, 255, 1)'
                                            }
                                          strokeLinecap="square"
                                          percent={i.value}
                                          showInfo={false}
                                        />
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className={`${styles.contextContainer} col-6`}>
                        { progressBarData.map((i) => {
                            return (
                                <div className={`${styles.progressContainer}`}>
                                    <div className={`${styles.flexRow} col-9`}>
                                        <p>{i.title}</p>
                                        <p>{i.value}</p>
                                    </div>
                                    <div className={`col-3`}>
                                        <Progress
                                            type="line"
                                            strokeColor={
                                                'rgba(105, 192, 255, 1)'
                                            }
                                            strokeLinecap="square"
                                            percent={i.value}
                                            showInfo={false}
                                        />
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TotalOpportunitiesCard;