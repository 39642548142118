import React, { useEffect, useState } from "react";
import Chart from 'chart.js/auto'
import { Doughnut } from 'react-chartjs-2';
import {Breadcrumb, Tabs, Collapse, Table, Select } from "antd";
import styles from "./ReportDonutChartStyles.module.css"
import { CaretRightOutlined } from '@ant-design/icons';
import "../../../CustomStyles.css"


const { Panel } = Collapse;


const ReportDonutChart = ({data, title, icon, donutLabel, donutSubLabel, donutLegendDetail, hasDataTable }) => {

    const [activeKey, setActiveKey] = useState([])


    const textCenter = {
        id: 'text-center',
        beforeDatasetsDraw(chart, args, options) {
            const { ctx, data } = chart;

            const xCoor = chart.getDatasetMeta(0).data[0].x;
            const yCoor = chart.getDatasetMeta(0).data[0].y

            const donutIcon = icon ? icon  : ''

            ctx.save();

            const image = new Image();
            image.src = donutIcon;
            const imageSize = 30;
            ctx.drawImage(image, chart.width / 2 - imageSize / 2, chart.height / 2 - 52, imageSize, imageSize);

            ctx.restore();


            ctx.font = 'bolder 24px Inter, sans-serif ';
            ctx.fillStyle = 'rgba(79, 79, 79, 1)';
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            ctx.fillText(donutLabel,
                xCoor, yCoor - 10);

            ctx.font = '16px Inter, sans-serif '
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            ctx.fillText(donutSubLabel,
                xCoor, yCoor + 28)

        }
    }

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        // plugins: {
        //     legend: {
        //         position: 'bottom',
        //         align: 'center',
        //     },
        // },
    };



    const handleCollapseChange = (value) => {
        setActiveKey(value)
    };



    return (
        <div className={styles.main}>
            <div className={styles.title}>
                <h4>{title}</h4>
            </div>
            <div className={styles.chartContainer }>
                <Doughnut height="450px" options={options} data={data} plugins={[textCenter]} />
            </div>
            <div className={(hasDataTable ? `${styles.collapseTable}` : `${styles.collapseTable} donut-chart`)}>
                { hasDataTable ? (
                    <Collapse
                        showArrow={false}
                        bordered={false}
                        onChange={handleCollapseChange}
                        defaultActiveKey={[]}
                        activeKey={activeKey}
                        expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                        style={{
                            background: 'rgba(255, 255, 255, 1)',
                        }}
                        className={"resource-util"}
                    >
                        {donutLegendDetail.map((i) => {
                            return(
                                <Panel
                                    header={i.label}
                                    key={i.key}
                                    className={styles.hasDataTablePanel}
                                >
                                    <div>{i.children}</div>
                                </Panel>
                            )
                        }) }
                    </Collapse>
                ) : (
                     donutLegendDetail.map((i) => {
                            return(
                                <Panel
                                    header={i.label}
                                    key={i.key}
                                    className={styles.panel}
                                >
                                    <div>{i.children}</div>
                                </Panel>
                            )
                        })
                  )
                }
            </div>
        </div>
    )
}

export default ReportDonutChart;